import React, { useState } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import ResignationSuccess from "./ResignationSuccess";

function DeadCoinHandOverFormMain({ coin }) {
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [coinType, setCoinType] = useState("Type-1");
  const [isDone, setIsDone] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      {!isDone && (
        <>
          <div className="flex flex-col mt-10 self-stretch pb-6 max-md:px-2 max-md:mt-6 max-md:max-w-full">
            <div className="text-lg font-bold tracking-tight leading-5 text-blue-900 max-md:max-w-full">
              Edit Coin
            </div>
            <div className="flex flex-col justify-center mt-6 bg-white rounded-3xl max-md:max-w-full">
              <div className="flex flex-col p-6 rounded-2xl bg-[#FFF9DF] max-md:px-5 max-md:max-w-full">
                <div className="text-sm leading-5 text-neutral-600 max-md:max-w-full">
                  Please provide all the coin profile information to be zipped
                  into one file with your serial number and coins name.
                  <span className="block">{`(whole info from the profile)
 Must be a complete file as you will be held responsible for the files accuracy and precision.
`}</span>
                  <span className="mt-2 block">
                    Send the google drive link of other files.
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-6 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[16%] max-md:ml-0 max-md:w-full ">
                  <div className="flex flex-col self-stretch max-md:mt-4 relative">
                    <div className="text-base tracking-tight leading-4 text-neutral-600">
                      Coin Logo
                    </div>
                    <div className="flex flex-col justify-center px-2.5 py-4 mt-2 text-sm leading-4 text-center rounded-2xl border border-solid border-neutral-300 placeholder:text-stone-300">
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4072f13a03724e0f0aaebabad129fe9d73bee02fda7b510be25b9e62e0e88fd8?"
                        className="self-center w-11 aspect-[0.95]"
                      />
                      <div>Coin Logo</div>
                    </div>
                    <input
                      type="file"
                      value=""
                      className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
                    />
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[84%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center text-base max-md:mt-4 max-md:max-w-full">
                    <div className="flex gap-4 max-md:flex-wrap">
                      <div className="flex flex-col flex-1 justify-center">
                        <div className="tracking-tight leading-[110%] text-neutral-600">
                          Coin Name
                        </div>
                        <input
                          placeholder=" Coin Name"
                          className="justify-center px-4 py-3 mt-2 rounded-xl 
                    border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                        />
                      </div>
                      <div className="flex flex-col flex-1 justify-center">
                        <div className="tracking-tight leading-[110%] text-neutral-600">
                          Current Supply
                        </div>
                        <input
                          placeholder=" Current Supply"
                          className="justify-center items-start px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5"
                        />
                      </div>
                    </div>
                    <div className="flex gap-4 mt-4 max-md:flex-wrap">
                      <div className="flex flex-col flex-1 justify-center">
                        <div className="tracking-tight leading-[110%] text-neutral-600">
                          Coin Ticker
                        </div>
                        <input
                          placeholder=" Coin Ticker"
                          className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300"
                        />
                      </div>
                      <div className="flex flex-col flex-1 justify-center">
                        <div className="tracking-tight leading-[110%] text-neutral-600">
                          Maximum Supply
                        </div>
                        <input
                          placeholder=" Maximum Supply"
                          className="justify-center items-start
                     px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%]
                      placeholder:text-stone-300 max-md:pr-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-5 justify-between mt-6 max-md:flex-wrap max-md:max-w-full">
              <div className="flex flex-col self-start text-base">
                <div className="tracking-tight leading-[110%] text-neutral-600">
                  Coin Type
                </div>
                <div className="flex flex-col justify-center px-2 mt-4 leading-[121%] placeholder:text-stone-300">
                  <div
                    className="flex gap-2 py-px cursor-pointer"
                    onClick={() => setCoinType("Type-1")}
                  >
                    <div
                      className={`${
                        coinType === "Type-1" ? "bg-teal-400 " : " bg-zinc-300"
                      } shrink-0 self-start w-4 h-4 bg-teal-400 rounded-full`}
                    />
                    <div>Type 1</div>
                  </div>
                  <div
                    className="flex gap-2 py-px mt-4 cursor-pointer "
                    onClick={() => setCoinType("Type-2")}
                  >
                    <div
                      className={`${
                        coinType === "Type-2" ? "bg-teal-400 " : " bg-zinc-300"
                      } shrink-0 self-start w-4 h-4 bg-teal-400 rounded-full`}
                    />
                    <div>Type 2</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col text-base max-md:max-w-full w-full max-w-[80%]">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Coin Description
                </div>
                <input
                  placeholder={` Describe in detail about your coin (should be expandable)`}
                  className=" px-4 pt-4 pb-14 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
                />
              </div>
            </div>
            <div className="flex gap-4 justify-center mt-6 text-base max-md:flex-wrap">
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Coin Tracker Page
                </div>
                <input
                  placeholder="Coin Tracker Page"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                />
              </div>
              <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Forked Technology
                </div>
                <input
                  placeholder="Forked Technology"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                ></input>
              </div>
            </div>
            <div className="flex gap-4 justify-center mt-6 text-base max-md:flex-wrap">
              <div className="flex flex-col justify-center max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Mining Algorithm / Mechanism
                </div>
                <input
                  placeholder="Mining Algorithm / Mechanism"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                ></input>
              </div>
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Base Chain
                </div>
                <input
                  placeholder="Base Chain"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                ></input>
              </div>
            </div>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Coin Descriptions
            </div>
            <input
              placeholder={`Tell all about the coin information (should be expandable)`}
              className="justify-center items-start px-4 pt-4 pb-7 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:pr-5 max-md:max-w-full"
            ></input>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Total Market Capitalization
            </div>
            <input
              placeholder="Total Market Capitalization"
              className="justify-center px-4 py-3 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="mt-6 max-md:pr-5 max-md:max-w-full">
              <div className="flex gap-3 max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[21%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center self-stretch pt-1.5 text-base tracking-tight leading-4 text-neutral-600 max-md:mt-8">
                    <div>How long has the digital asset been in existence?</div>
                    <input className="px-2  shrink-0 mt-6 h-10 rounded-xl border border-solid border-neutral-300" />
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center self-stretch text-base tracking-tight leading-6 text-black text-opacity-80 max-md:mt-8">
                    <div>Has the asset ever changed hands?</div>
                    <input className="px-2  shrink-0 mt-4 h-10 rounded-xl border border-solid border-neutral-300" />
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center self-stretch text-base tracking-tight leading-6 text-neutral-600 max-md:mt-8">
                    <div>
                      Development Team Size - this is the site, not the trader.
                    </div>
                    <input className="px-2  shrink-0 mt-4 h-10 rounded-xl border border-solid border-neutral-300" />
                  </div>
                </div>
                <div className="flex flex-col ml-5 w-[32%] max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow justify-center text-base tracking-tight leading-6 text-neutral-600 max-md:mt-8">
                    <div className="text-[15px]">
                      Platform (e.g. EOS, NEO, Ethereum, proprietary
                      blockchain).
                    </div>
                    <input className="px-2  shrink-0 mt-4 h-10 rounded-xl border border-solid border-neutral-300" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-center mt-6 text-base leading-5 placeholder:text-stone-300 max-md:flex-wrap">
              <input
                placeholder="Chain Fork(optional)"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
              <input
                placeholder="Team/Backers/Investors"
                className="flex-1 justify-center p-4 whitespace-nowrap rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Media Coverage / Awards (Optional)
            </div>
            <input
              placeholder="Media Coverage/Awards(optional)"
              className="justify-center px-4 py-3 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Coin Website Link
            </div>
            <input
              placeholder="Website Link"
              className="justify-center px-4 py-3 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="flex gap-4 mt-6 max-md:flex-wrap">
              <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                <div className="text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
                  Source Code
                </div>
                <input
                  placeholder="e.g. Gitub, Gitter"
                  className="justify-center px-4 py-2 mt-2 text-lg tracking-tight leading-7 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 text-opacity-80 max-md:max-w-full"
                ></input>
              </div>
              <div className="flex flex-col flex-1 justify-center text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
                <div className="max-md:max-w-full">White Paper Link</div>
                <div className="shrink-0 mt-2 h-10 rounded-xl border border-solid border-neutral-300 max-md:max-w-full" />
              </div>
            </div>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              {`All valid blockchain explores (URLs)`}
            </div>
            <input
              placeholder="URLs"
              className="justify-center px-4 pt-4 pb-12 mt-2 text-base leading-5 whitespace-nowrap rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Bitcointalk ANN Page
            </div>
            <input
              placeholder="Website Link"
              className="justify-center px-4 py-3 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Message Board 1 (Optional)
            </div>
            <input
              placeholder="e.g. Blog / Medium"
              className="justify-center px-4 pt-4 pb-12 mt-2 text-base leading-5 rounded-xl border border-solid border-neutral-300 placeholder:text-stone-300 max-md:max-w-full"
            ></input>
            <div className="flex gap-5 justify-center mt-6 text-base max-md:flex-wrap">
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Mobile App
                </div>
                <input
                  placeholder="Website Link"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                ></input>
              </div>
              <div className="flex flex-col flex-1 max-md:max-w-full">
                <div className="tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
                  Total Supply of Exchange
                </div>
                <input
                  placeholder="Website Link"
                  className="justify-center px-4 py-3 mt-2 rounded-xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
                ></input>
              </div>
            </div>
            <div className="mt-6 text-base tracking-tight leading-4 text-neutral-600 max-md:max-w-full">
              Social Media Links
            </div>
            <div className="flex gap-4 mt-8 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/218c5aa884054468f470bc17078edfbc0bf4137cab1c2759bc00d808e587045d?"
                className="shrink-0 my-auto w-8 aspect-[1.33]"
              />
              <input
                placeholder="Discord"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b9159362b172d08a761a818e5f6d4345038623a01019244c21348be08033587?"
                className="shrink-0 my-auto w-8 aspect-square"
              />
              <input
                placeholder="Telegram"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a3894aa2ae0ffebab862be6d4793e0bcc386998b0616edce5ecc65280e2a3e8?"
                className="shrink-0 my-auto w-8 aspect-[1.14] fill-zinc-300"
              />
              <input
                placeholder="X"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b33c94fd3bf980e16502a36af4edf69ac70090a19cde526181d74c0792656d5e?"
                className="shrink-0 my-auto w-8 aspect-square"
              />
              <input
                placeholder="Facebook"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0ef0b87a6e82f59c4f9a420d3ced727af26963d691f330fa6c6121f6624daf38?"
                className="shrink-0 my-auto w-8 aspect-square fill-zinc-300"
              />
              <input
                placeholder="Linkedin"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 whitespace-nowrap placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f42401acdcb59fe8f6b562b740b6c64007bed9e03537ad931f0708c3365a4ca0?"
                className="shrink-0 my-auto w-8 aspect-square"
              />
              <input
                placeholder="Instagram"
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 mt-6 text-base leading-5 placeholder:text-stone-300 max-md:flex-wrap">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/71b51482efd4126eaf9a563ce0069ffae75cab04f10747d521df6529aad1ad65?"
                className="shrink-0 my-auto w-8 aspect-square"
              />
              <input
                placeholder={`Video Channel (e.g. YouTube)(optional)`}
                className="flex-1 justify-center p-4 rounded-xl border border-solid border-neutral-300 max-md:max-w-full"
              ></input>
            </div>
            <div className="flex gap-4 self-end mt-12 text-base font-semibold text-white max-md:mt-10">
              <button
                className="justify-center px-6 py-4 whitespace-nowrap bg-sky-600 rounded-xl"
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
              >
                Cancel
              </button>
              <button
                className="justify-center px-14 py-4 bg-blue-900 rounded-xl max-md:px-5"
                onClick={() => {
                  setIsDone(true);
                }}
              >
                Send
              </button>
            </div>
          </div>
        </>
      )}
      {isDone && <ResignationSuccess coin={coin} />}
    </>
  );
}

export default DeadCoinHandOverFormMain;
