import React from "react";
import Profile from "./ProfileMain";

function PreviewProfile() {
  return (
    <div className="max-md:px-3">
      <Profile />
    </div>
  );
}

export default PreviewProfile;
