import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

function AccountSettingsMain() {
  const navigate = useNavigate();
  const accountUpdated = localStorage.getItem('account_updated') || null;
  
  const user = JSON.parse(localStorage.getItem('user')) || {};
  const [visible, setVisible] = useState(!!accountUpdated);

  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
        localStorage.removeItem('account_updated');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [visible]);

  const handleClose = () => {
    setVisible(false);
    localStorage.removeItem('account_updated');
  };

  return (
    <div className="flex flex-col mt-6 max-md:mx-3 max-md:mt-8 max-w-full m-auto bg-white border border-gray-200 shadow-sm rounded-lg">
      <div className="py-6">
        <h2 className="text-xl font-bold text-blue-900 px-6">Security Settings</h2>
        {visible && (
          <div className="flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm bg-green-100 text-green-600">
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <span className="font-semibold mx-2 text-green-600">Account Updated</span>
            <div>
              <FaCheckCircle size={20} />
            </div>
          </div>
        )}
      </div>
      <hr className="border-t border-gray-200"/>
      
      {/* Password Section */}
      <div className="flex justify-between items-center py-4 px-6 border-b border-neutral-200 max-md:flex-col max-md:items-start">
        <div className="text-neutral-600">
          <span className="block font-medium">Password</span>
          <span className="text-neutral-400">•••••••••••</span>
        </div>
        <button
          onClick={() => navigate("/dashboard/account-settings/change-password")}
          className="text-blue-900 font-medium py-2 px-4 bg-[#e0e7ff] rounded-full hover:bg-blue-200 max-md:w-full max-md:mt-2"
        >
          Change Password
        </button>
      </div>

      {/* Email Section */}
      <div className="flex justify-between items-center py-4 px-6 border-b border-neutral-200 max-md:flex-col max-md:items-start">
        <div className="text-neutral-600">
          <span className="block font-medium">Email Address</span>
          <span className="text-neutral-400">
            {user.email}
            </span>
        </div>
        <button
          onClick={() => navigate("/dashboard/account-settings/change-email")}
          className="text-blue-900 font-medium py-2 px-4 bg-[#e0e7ff] rounded-full hover:bg-blue-200 max-md:w-full max-md:mt-2"
        >
          Change Email
        </button>
      </div>

      {/* Phone Number Section */}
      <div className="flex justify-between items-center py-4 px-6 border-b border-neutral-200 max-md:flex-col max-md:items-start">
        <div className="text-neutral-600">
          <span className="block font-medium">Phone Number</span>
          <span className="text-neutral-400">{user.phone_number}</span>
        </div>
        <button
          onClick={() => navigate("/dashboard/account-settings/change-number")}
          className="text-blue-900 font-medium py-2 px-4 bg-[#e0e7ff] rounded-full hover:bg-blue-200 max-md:w-full max-md:mt-2"
        >
          Change Phone
        </button>
      </div>
    </div>
  );
}

export default AccountSettingsMain;
