import React, { useState } from "react";
import { FaRegEnvelope } from "react-icons/fa";
import BacktoLogin from "../components/BackToLogin";
import TwoStepVerification from "./TwoStepVerification";
import { forgetPasswordSendOtpEmail } from "../../../api/apiCall.js";

function ForgotPassMain() {
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const [email, setEmail] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      return false;
    }
    setError('');
    return true; 
  };

  const handleSendOtpEmail = async () => {
    if (!validateEmail()) return;

    try {
      setLoading(true);
      let data = {
        payloads: {
          email: email,
        },
      };
      const response = await forgetPasswordSendOtpEmail(data);
      if (response.status === 200) {
        setShowVerifyCode(true);
      }
      else {
        // Display error message
        console.error("Failed to verify OTP: ", response.message);
        setError('Failed to send OTP, invalid email!');
      }
    } catch (error) {
      console.log("Error sending OTP: ", error);
      setError('Failed to send OTP, invalid email!');
    } finally {
      setLoading(false);
    }
  };

  const handleResendOtpEmail = async () => {
    if (!validateEmail()) return;

    try {
      if (validateEmail()) {
        return;
      }

      let data = {
        payloads: {
          email: email,
        },
      };
      const response = await forgetPasswordSendOtpEmail(data);
      if (response.status === 200) {
        startCountdown();
        setShowVerifyCode(true);
      } else {
        setError('Failed to resend OTP, invalid email!');
      }
    } catch (error) {
      console.log("Error resending OTP: ", error);
      setError('Failed to resend OTP, invalid email!');
    }
  };

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendOtpEmail();
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-[#F9FAFB]">
      {!showVerifyCode && (
        <>
          <div className="flex flex-col items-center px-20 py-20 text-base bg-[#F9FAFB] flex-grow max-md:px-5">
            <div className="flex flex-col p-6 mt-6 max-w-full font-semibold bg-white border border-gray-200 rounded-2xl shadow-sm w-[400px] max-md:w-full max-md:px-5">
              <div className="flex items-center justify-between w-full mb-4">
                <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                  <BacktoLogin />
                </div>
                <img src="/logoFooter.png" alt="Devreg Logo" className="h-8" />
              </div>
              <hr className="w-full border-t border-gray-200" />
              <div className="mt-8 mr-auto text-xl text-center text-neutral-800 max-sm:mr-auto max-md:text-xl">
                Forgot Password
              </div>
              <div className="mt-2 text-zinc-700 text-opacity-80 max-sm:mx-auto max-sm:text-sm max-md:text-md text-sm">
                Enter your email and we’ll send you instructions to reset your
                password
              </div>
              <div className="relative mt-6">
                <FaRegEnvelope className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                <input
                  value={email}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  className={`w-full pl-10 pr-4 py-2.5 rounded-lg border border-solid placeholder-text-stone-300 ${error ? 'border-red-600' : 'border-neutral-300'}`}
                />
              </div>
              {error && (
                <div className="text-red-600 text-sm mt-1">
                  {error}
                </div>
              )}
              <button
                className={`cursor-pointer justify-center items-center px-9 py-3 mt-4 mb-4 text-base text-white rounded-3xl max-md:px-5 text-center ${loading ? "bg-gray-400" : "bg-blue-900"
                  }`}
                onClick={handleSendOtpEmail}
                disabled={loading}
              >
                {loading ? (
                  <svg className="animate-spin h-6 w-6 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" ></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" ></path>
                  </svg>
                ) : (
                  "Send to Email"
                )}
              </button>
            </div>
          </div>
        </>
      )}
      {showVerifyCode && (
        <>
          <TwoStepVerification email={email} />
        </>
      )}
    </div>
  );
}

export default ForgotPassMain;
