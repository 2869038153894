import React from 'react';

function ForumTopic({ title, description, tags, posts, topics, latestPost }) {
  return (
    <article className="flex flex-wrap gap-1 px-4 w-full bg-white border-b border-solid max-md:max-w-full max-sm:py-4">
      <div className="flex flex-col flex-1 shrink py-2 pr-4 my-auto basis-8 min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col justify-center w-full text-sm max-md:max-w-full">
          <h3 className="font-semibold text-blue-900">{title}</h3>
          <p className="mt-1 leading-5 text-sky-600 max-md:max-w-full">
            {description} <span className="text-sky-600">see more</span>
          </p>
        </div>
        <div className="flex flex-wrap gap-1 items-center py-2 mt-1 w-full text-xs text-gray-50 max-md:max-w-full">
          {tags.map((tag, index) => (
            <span key={index} className="gap-2 self-stretch px-2 py-1 my-auto whitespace-nowrap bg-blue-700 rounded">
              {tag}
            </span>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center px-6 text-xs text-gray-500 bg-gray-100 border-r border-b border-l border-solid w-[119px] max-md:px-5 max-sm:w-full max-md:flex-row max-md:items-center max-md:justify-evenly max-md:py-1.5 max-md:rounded-md">
        <div>
          <span className="font-medium text-gray-700">{posts}</span> Posts
        </div>
        <div className="mt-2 max-md:mt-0">
          <span className="font-medium text-gray-700">{topics}</span> Topics
        </div>
      </div>
      <div className="flex flex-col justify-center px-2 w-60 text-xs text-sky-600 max-md:mt-2 max-md:px-0">
        <div>
          Latest post by <span className="font-medium text-sky-600">{latestPost.author}</span>
        </div>
        <div className="mt-1">
          in <span className="font-medium text-sky-600">{latestPost.topic}</span>
        </div>
        <div className="mt-1 text-gray-600">{latestPost.date}</div>
      </div>
    </article>
  );
}

export default ForumTopic;