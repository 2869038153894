import { useLocation, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";

const useRedirectToCoinProfilePage = () => {
  const location = useLocation();
  const [coin, setCoin] = useState(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (location.state && location.state.coin) {
      setCoin(location.state.coin);
    } else {
      setRedirect(true);
    }
  }, [location.state]);

  if (redirect) {
    return { coin: null, redirect: <Navigate to="/dashboard/coin-profile" /> };
  }

  return { coin, redirect: null };
};

export default useRedirectToCoinProfilePage;
