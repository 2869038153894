import React, { useState } from "react";
import useRedirectToCoinProfilePage from "../hooks/useRedirectToCoinProfilePage";
import { useNavigate } from "react-router-dom";
import DeadCoinHandOverFormMain from "./DeadCoinHandOverFormMain";

function ResignAsHeadDevMain() {
  const { coin, redirect } = useRedirectToCoinProfilePage();
  const [goTo2ndPage, setGoTo2ndPage] = useState(false);
  const [goTo3rdPage, setGoTo3rdPage] = useState(false);
  const [goTo4thPage, setGoTo4thPage] = useState(false);
  const navigate = useNavigate();
  if (redirect) {
    return redirect;
  }

  return (
    <div>
      {coin ? (
        <div className="w-full ">
          <div className="self-stretch tracking-tight leading-[110%] max-md:max-w-full">
            <span className="font-bold">{coin.ticker} Coin</span> &gt; Resign as
            Head Developer
          </div>

          {!goTo4thPage && (
            <div className=" flex items-center justify-center text-black80 gap-3 max-sm:mt-4  mt-20">
              <img
                src={coin.icon}
                alt={`${coin.name} icon`}
                className="max-w-[40px] max-sm:max-w-[35px]"
              />
              <div className="">
                <div className="font-bold text-[16px] max-sm:text-[15px]">
                  {coin.ticker}
                </div>
                <div className=" items-start text-sm16 leading-4 max-sm:text-sm17">
                  {coin.name}
                </div>
              </div>
            </div>
          )}
          {!goTo2ndPage && !goTo2ndPage && !goTo3rdPage && !goTo4thPage && (
            <>
              <div className="flex max-w-[45%] max-md:max-w-[90%] flex-col justify-start mx-auto">
                <div className="font-semibold text-black90 mt-4">
                  Resigning as a head developer
                </div>
                <p className="text-stone-500 text-sm14">
                  Content here about resigning as head dev, the effect of the
                  resignation, the process of handing over the dead coin, etc.
                </p>
                <div className=" text-black90 text-sm14 mt-4 font-semibold">
                  Reason for resignation
                </div>
                <textarea
                  name="text-area-resign"
                  id="resign-msg"
                  className="border-2 mt-1 rounded-xl min-h-[150px] p-3"
                ></textarea>

                <div className="flex space-x-3 justify-center text-white mt-4">
                  <button
                    onClick={() => {
                      navigate("/dashboard/coin-profile");
                      window.scrollTo(0, 0);
                    }}
                    className="text-sm14 bg-devRegBlue px-5 py-1.5 rounded-2xl"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => setGoTo2ndPage(true)}
                    className="text-sm14 bg-devRegDarkBlue px-5 py-1.5 rounded-2xl"
                  >
                    Continue with resignation
                  </button>
                </div>
              </div>
            </>
          )}

          {goTo2ndPage && !goTo3rdPage && (
            <div className="px-3">
              <div className="container-page-2 mt-10  border-2 p-5 rounded-2xl">
                <div className="font-semibold text-black90">
                  The Developer and a Dead Coin:{" "}
                </div>
                <div className="text-sm14 text-black70 mt-2">
                  <p>
                    Coinreg, operating as an affiliate to Devreg, is developing
                    a dedicated Trustee Database. This platform is tailored
                    exclusively for registered developers looking to archive
                    their dormant cryptocurrency's relevant data, historical
                    records, authentication details, and additional information.
                  </p>
                  <p className="mt-2">
                    This specific configuration is designed exclusively for the
                    benefit of the registered developers, who are required to
                    get in touch with the administrative team at devreg.org to
                    facilitate the uploading all comprehensive cryptocurrency
                    data. This information should encompass all components
                    integral to the coin'’'s operation, emphasising usernames
                    and passwords for social media accounts, websites,
                    exchanges, and GitHub. These details should be compiled into
                    a zip file for secure cloud-based storage, ensuring the
                    preservation and accessibility of this critical data.
                  </p>
                  <p className="mt-5">
                    Read more at{" "}
                    <span className="underline">
                      https://coinreg.info/dead-coins/
                    </span>
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 items-center justify-center mt-4">
                <input type="checkbox" className="w-[15px] h-[15px]" />
                <div>I have read and agree this information</div>
              </div>

              <div className="flex space-x-3 justify-center text-white mt-4">
                <button
                  onClick={() => setGoTo2ndPage(false)}
                  className="text-sm14 bg-devRegBlue px-5 py-1.5 rounded-2xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setGoTo3rdPage(true)}
                  className="text-sm14 bg-devRegDarkBlue px-5 py-1.5 rounded-2xl"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {goTo3rdPage && !goTo4thPage && (
            <div className="px-3">
              <div className="container-page-2 mt-10  border-2 p-5 rounded-2xl">
                <div className="font-semibold text-black90">
                  Terms & Condition{" "}
                </div>
                <div className="text-sm14 text-black70 mt-2">
                  <p>
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis venenatis.
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis venenatis.
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis venenatis.
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis venenatis.
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis venenatis.
                    Integer vitae nisl sit amet odio elementum ultrices in eget
                    sem. Nunc consectetur pellentesque metus mattis
                    venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis. Integer vitae nisl sit amet odio elementum
                    ultrices in eget sem. Nunc consectetur pellentesque metus
                    mattis venenatis.
                  </p>
                </div>
              </div>

              <div className="flex space-x-4 items-center justify-center mt-4">
                <input type="checkbox" className="w-[15px] h-[15px]" />
                <div>I have read and agree this information</div>
              </div>

              <div className="flex space-x-3 justify-center text-white mt-4">
                <button
                  onClick={() => setGoTo3rdPage(false)}
                  className="text-sm14 bg-devRegBlue px-5 py-1.5 rounded-2xl"
                >
                  Cancel
                </button>
                <button
                  onClick={() => setGoTo4thPage(true)}
                  className="text-sm14 bg-devRegDarkBlue px-5 py-1.5 rounded-2xl"
                >
                  Continue with resignation
                </button>
              </div>
            </div>
          )}

          {goTo4thPage && <DeadCoinHandOverFormMain coin={coin} />}
        </div>
      ) : (
        <>ERROR NO DATA FOUND</>
      )}
    </div>
  );
}

export default ResignAsHeadDevMain;
