import React from "react";
import { FaRegCheckCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function ResignationSuccess({ coin }) {
  const navigate = useNavigate();
  return (
    <div>
      {coin && (
        <div className="flex flex-col justify-center items-center space-y-5">
          <div className=" flex items-center justify-center text-black80 gap-3 max-sm:mt-4  mt-20">
            <img
              src={coin.icon}
              alt={`${coin.name} icon`}
              className="max-w-[40px] max-sm:max-w-[35px]"
            />
            <div className="">
              <div className="font-bold text-[16px] max-sm:text-[15px]">
                {coin.ticker}
              </div>
              <div className=" items-start text-sm16 leading-4 max-sm:text-sm17">
                {coin.name}
              </div>
            </div>
          </div>
          <div className="max-w-[60%] max-md:max-w-[90%] flex flex-col justify-center items-center">
            <div className="text-devRegDarkBlue text-sm20 font-bold flex items-center">
              <span className="text-[#00A018] text-[25px] mr-3">
                <FaRegCheckCircle />
              </span>{" "}
              Resignation has been received
            </div>
            <p className="text-black70 mt-5">
              Your resignation as the head developer has been received. The
              responsibility for the coin has been transferred to the admin. Our
              admin will contact you shortly for further details. Thank you for
              your contributions.
            </p>
            <button
              onClick={() => navigate("/dashboard/coin-profile")}
              className="bg-devRegDarkBlue text-white px-8 mt-8 py-2 rounded-full"
            >
              Close this page
            </button>
          </div>
        </div>
      )}
      {!coin && <></>}
    </div>
  );
}

export default ResignationSuccess;
