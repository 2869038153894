const initialState = {
  value: "",
  gotoLoginPage: false,
  isLoggedIn: false,
  isLoading: false,
  markedNav: { tab: 1, message: "test" },
};

const valueReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VALUE":
      return { ...state, value: action.payload };
    case "SET_GOTO_LOGIN_PAGE":
      return { ...state, gotoLoginPage: action.payload };
    case "IS_LOGGED_IN":
      return { ...state, isLoggedIn: action.payload };
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "MARK_NAV_DASH":
      return { ...state, markedNav: action.payload };
    default:
      return state;
  }
};

export default valueReducer;
