import React, { useState } from "react";
import useRedirectToCoinProfilePage from "../hooks/useRedirectToCoinProfilePage";
import { useNavigate } from "react-router-dom";
function AssignNewDev() {
  const [isSelected, setIsSelected] = useState("");
  const navigate = useNavigate();

  // redirect to coin profile if url is not the correct one
  const { coin, redirect } = useRedirectToCoinProfilePage();
  if (redirect) {
    return redirect;
  }

  return (
    <div>
      {coin ? (
        <div className="w-full pl-5 max-md:pl-0">
          <div className="self-stretch tracking-tight leading-[110%] max-md:max-w-full">
            <span className="font-bold">{coin.ticker} Coin</span> &gt; Assign
            New Head Developer
          </div>
          <div className="mt-8 text-base tracking-tight leading-6 text-black text-opacity-80">
            {` Select New Head Developer for ${coin.ticker}`}
          </div>

          {/* looped member data here */}
          <div className="mt-7">
            {coin.member.map((data, index) => (
              <div
                key={index}
                onClick={() => {
                  if (index === 0) {
                    return;
                  }
                  setIsSelected(index);
                }}
                className={`flex space-x-3 justify-start cursor-pointer items-center ${
                  index === 0 ? "opacity-50" : "opacity-100"
                }`}
              >
                <button
                  disabled={index === 0 ? true : false}
                  className={`shrink-0 w-5 h-5 ${
                    isSelected === index ? "bg-teal-400" : "bg-neutral-400"
                  } rounded-full`}
                ></button>
                <img src={data.img} alt={`profile-img-${data.fullName}`} />
                <div>{`${data.fullName} ${index === 0 ? "(You)" : ""}`}</div>
              </div>
            ))}
          </div>

          <div className="flex gap-4 self-end mt-12 text-base font-semibold text-white max-md:mt-10">
            <button
              className="justify-center px-8 py-4 whitespace-nowrap bg-sky-600 rounded-3xl"
              onClick={() => {
                navigate("/dashboard/coin-profile");
                window.scrollTo(0, 0);
              }}
            >
              Cancel
            </button>
            <button
              className="justify-center px-10 py-4 bg-blue-900 rounded-3xl max-md:px-5"
              onClick={() => {
                navigate("/dashboard/coin-profile");
                window.scrollTo(0, 0);
              }}
            >
              Assign
            </button>
          </div>
        </div>
      ) : (
        <>ERROR NO DATA FOUND</>
      )}
    </div>
  );
}

export default AssignNewDev;
