import React from 'react';
import BoardItem from './BoardItem';

const BoardSection = ({ title, boards }) => {
  return (
    <section className="flex overflow-hidden flex-col mt-6 w-full rounded-lg border border border-solid max-md:max-w-full">
      <h2 className="gap-2.5 self-stretch px-4 py-2 w-full text-sm font-semibold text-white bg-blue-900 max-md:max-w-full">
        {title}
      </h2>
      <div className="flex flex-col w-full text-xs max-md:max-w-full">
        {boards.map((board, index) => (
          <BoardItem key={index} {...board} />
        ))}
      </div>
    </section>
  );
};

export default BoardSection;