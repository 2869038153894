import { basicAxios } from '../../config/common';


// Helper function to create FormData from an object
const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
};

// Helper function to handle Axios requests
const handleAxiosRequest = async (axiosInstance, method, url, data = null) => {
  try {
    const params = data ? toFormData(data) : null;
    const response = await axiosInstance[method](url, params);
    return JSON.parse(response.data);
  } catch (error) {
    throw new Error(error.response?.data?.message || error.message);
  }
};

// Update clicks
const updateReferralClicks = async (ref_code) => handleAxiosRequest(basicAxios, 'post', `api/referral-update/${ref_code}/clicks`);

// Update Referral Shares 
const updateReferralShares = async (ref_code) => handleAxiosRequest(basicAxios, 'post', `api/referral-update/${ref_code}/shared`);

const homeAPI = {
  updateReferralClicks,
  updateReferralShares,
};

export default homeAPI;
