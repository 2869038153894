import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInNavigations from "./routes/NotLoggedInNavigations";
import LoggedIn from "./routes/LoggedIn";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "./actions";
import axios from "axios";

function App() {
  const isLoggedIn = useSelector((state) => state.value.isLoggedIn);
  const isLoading = useSelector((state) => state.value.isLoading);
  const auth = useSelector((state) => state.value.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkLocalStorage = async () => {
      const isLoggedInLocal = JSON.parse(localStorage.getItem("isLoggedIn"));
      console.log(isLoggedInLocal);
      if (isLoggedInLocal) {
        await dispatch(setIsLoggedIn(true));
      } else {
        await dispatch(setIsLoggedIn(false));
      }
    };

    checkLocalStorage();

    // Prevent scrolling when loading
    if (isLoading) {
      document.body.classList.add('overflow-hidden');
      return () => document.body.classList.remove('overflow-hidden');
    }
  }, [dispatch]);

  // useEffect(() => {
  //   // Function to get the user's current location
  //   const getUserLocation = async () => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         async (position) => {
  //           const { latitude, longitude } = position.coords;

  //           try {
  //             // HERE Maps API Call
  //             const api_key = "RwaPnQdSam_JjFU1EUCeC7F_32KXEpfy1dpAvi5xpmc" 
  //             const response = await axios.get(
  //               `https://revgeocode.search.hereapi.com/v1/revgeocode?at=${latitude},${longitude}&apiKey=${api_key}`
  //             );

  //             if (response.status === 200) {
  //               const locationData = response.data.items[0].address;
  //               localStorage.setItem('userAddress', JSON.stringify(locationData));

  //             } else {
  //               console.error("Failed to fetch location data");
  //             }
  //           } catch (error) {
  //             console.error("Error during API call:", error);
  //           }
  //         },
  //         (error) => {
  //           console.error("Error getting location:", error);
  //         }
  //       );
  //     } else {
  //       console.error("Geolocation is not supported by this browser.");
  //     }
  //   };

  //   getUserLocation();
  // }, []);

  useEffect(() => {
    const LOCATIONIQ_API_KEY = "pk.4cd67ac67c0cfee819d0f453ae533eae";

    // Get user's coordinates
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        getLocationData(latitude, longitude);
      },
      (err) => {
        console.error("Failed to retrieve location.", err);
      }
    );

    const getLocationData = async (lat, lon) => {
      try {
        const response = await axios.get(
          `https://us1.locationiq.com/v1/reverse.php?key=${LOCATIONIQ_API_KEY}&lat=${lat}&lon=${lon}&format=json`
        );
  
        console.log(response.data);
        localStorage.setItem("userAddress", JSON.stringify(response.data.address));
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    };
  }, []);

  return (
    <>
      {isLoading && (
        <>
          {/* LOADING WITH LOCK SVG */}
          <div className="fixed inset-0 flex flex-col items-center justify-center z-9999999 bg-white/75">
            <div className="relative flex justify-center items-center">
              <div className="absolute animate-spin rounded-full h-24 w-24 max-md:w-14 max-md:h-14 max-sm:w-12 max-sm:h-12 border-t-4 max-sm:border-t-2 border-b-4 max-sm:border-b-2 border-devRegBlue"></div>
              <img src="/lock.svg" className="rounded-full h-14 w-14 max-md:w-10 max-md:h-10 max-sm:w-7 max-sm:h-7" alt="Loading" />
            </div>
            <p className="mt-9 max-sm:mt-4 text-lg max-sm:text-sm font-semibold text-devRegBlue animate-pulse">Loading...</p>
          </div>
        </>
      )}

      <Router>{isLoggedIn ? <LoggedIn /> : <NotLoggedInNavigations />}</Router>
    </>
  );
}

export default App;
