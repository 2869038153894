import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneNumber({ onPhoneNumberChange }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);
  console.log(valid);
  const handleChange = (value) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    onPhoneNumberChange(value);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <div className="mt-3">
      <PhoneInput
        country={"au"}
        value={phoneNumber}
        onChange={handleChange}
        inputStyle={{
          width: "100%",
          height: "45px",
          padding: "0.75rem",
          fontSize: "1rem",
          lineHeight: "1.5",
          color: "black",
          backgroundColor: "#fff",
          border: "1px solid #D4D4D4",
          borderRadius: ".5rem",
          paddingLeft: "50px",
          transition:
            "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        }}
        buttonStyle={{
          backgroundColor: "#fff",
          border: "1px solid #BCBCBC",
          borderRadius: " 0.6rem",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
        }}
      />
    </div>
  );
}

export default PhoneNumber;
