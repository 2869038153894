import React from "react";
import SidePanel from "../../component/SidePanel";
import TopBanner from "../../component/TopBanner";
import AccordionRequirements from "../../component/AccordionRequirements";

function Requirements() {
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e38e9cce73c9b7818258291d85da668868be0a750779e2ee3f171f615471c7af?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={'/requirement-banner.png'} />
      <div className="self-center mt-16 w-full max-w-[1360px] max-md:mt-5 max-md:max-w-full m-auto pb-16">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 max-md:mt-10 max-md:max-w-full max-md:px-7">
              <div className="text-3xl font-bold tracking-tight leading-10 text-blue-900 max-md:max-w-full">
                How it works?
              </div>
              <div className="flex flex-col mt-8 rounded-3xl text-neutral-600 max-md:max-w-full">
                <div className="flex gap-8 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/5828f5ab3b1082ad8d6b021cba8b704925290dfb0fcbf41b798063d615877d10?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-[0.95] w-[60px]"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-8 max-md:max-w-full">
                      Registration
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                      To begin the registration process, ensure all the information you provide is accurate. You’ll need to upload two forms of government-issued photo ID, such as a passport, driver’s license, or national ID card, as well as a recent utility bill or bank statement showing your current address. If you have questions about acceptable documents, please feel free to contact an administrator for assistance
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 mt-8 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a782be78c2d5c99d2dd951462580aa922f47809b8a50e7dda8befee6de975ec?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-[0.82] w-[60px]"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-8 max-md:max-w-full">
                      Facial Capture for Identification
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                    Using your mobile phone, you’ll be asked to capture your face with advanced facial recognition software. This technology supports over 150 countries' ID documents, ensuring that identity verification is convenient and accessible worldwide. It’s designed to help everyone—whether you’re a professional, freelancer, or individual—get verified easily.
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 mt-8 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2033de73b78187528a7b10f72bc5b9febc4adb3f7cdaad77dde802e9c6efab8?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-square w-[60px]"
                  />
                  <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-8 max-md:max-w-full">
                      Zoom Interview Scheduling
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                    Schedule a 15-minute Zoom interview at a time that suits you using our online calendar. In this brief session, you’ll meet with your designated country manager, who will confirm your identity as part of the verification process.
                    </div>
                  </div>
                </div>
                <div className="flex gap-8 mt-8 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d1eba038d1f7eda350d626806e9731f5afe0576acd5b37ce199a173479d2e553?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-square w-[60px]"
                  />
                  <div className="flex flex-col flex-1 justify-center max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Account Approval
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                    Once your account is approved, you’ll gain access to all the exclusive features Full ID Check offers to meet your specific needs, whether it’s building trust with potential clients, proving your identity, or increasing your visibility.
                    </div>
                    <div className="self-end mt-6 text-base tracking-tight leading-7 max-md:max-w-full">
                    Rest assured, all documents and ID information you provide during registration are securely stored offline and removed from our server. The only information available online is what you choose to share in your personal profile for self-promotion or professional purposes.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-24 max-md:mt-10 max-md:max-w-full">
                <div className="text-3xl font-bold tracking-tight leading-10 text-blue-900 max-md:max-w-full">
                  Want to know more?
                </div>
                <div className="mt-2 text-lg tracking-tight leading-5 text-neutral-500 max-md:max-w-full">
                  Check our FAQ Below
                </div>
                <AccordionRequirements />
              </div>
            </div>
          </div>
          <SidePanel image={imgUrl} />
        </div>
      </div>
    </div>
  );
}

export default Requirements;
