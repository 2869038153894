import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FaqAccordion from "../../component/FaqAccordion";
import {
  listTab,
  generalInformation,
  registrationAndMembership,
  profileManagement,
  verificationProcess,
  privacyAndSecurity,
  supportAndCommunity,
  featuresAndReferralProgram,
  reportAndDispute,
} from "../../data/AccordionFaqs";
import SearchFaqs from "./SearchFaqs";

function Viewseemore() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("general-information");
  const [titleAccordion, setTitleAccordion] = useState(
    "What is fullidcheck.com?"
  );
  const [activeTab, setActiveTab] = useState("");
  const [overview, setOverView] = useState("general-information");
  const location = useLocation();
  const [accordionData, setAccordionData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const tabParam = urlParams.get("tab");
  const tabQueryParam = urlParams.get("query");

  useEffect(() => {

    if (location.pathname === "/faq/category/view") {
      setTitleAccordion("What is fullidcheck.com?");
    }

    if (tabParam === "general-information") {
      setOverView("general-information");
      setAccordionData(generalInformation);
      setTitleAccordion("General Information");
    }
    if (tabParam === "registration-and-membership") {
      setOverView("registration-and-membership");
      setAccordionData(registrationAndMembership);
      setTitleAccordion("Registration and Membership");
    }
    if (tabParam === "profile-management") {
      setOverView("profile-management");
      setAccordionData(profileManagement);
      setTitleAccordion("Profile Management");
    }
    if (tabParam === "verification-process") {
      setOverView("verification-process");
      setAccordionData(verificationProcess);
      setTitleAccordion("Verification Process");
    }
    if (tabParam === "privacy-and-security") {
      setOverView("privacy-and-security");
      setAccordionData(privacyAndSecurity);
      setTitleAccordion("Privacy and Security");
    }
    if (tabParam === "support-and-community") {
      setOverView("support-and-community");
      setAccordionData(supportAndCommunity);
      setTitleAccordion("Support and Community");
    }
    if (tabParam === "features-and-referral-program") {
      setOverView("features-and-referral-program");
      setAccordionData(featuresAndReferralProgram);
      setTitleAccordion("Features and Referral Program");
    }
    if (tabParam === "report-and-dispute") {
      setOverView("report-and-dispute");
      setAccordionData(reportAndDispute);
      setTitleAccordion("Report and Dispute");
    }
  }, [location]);

  return (
    <div>
      <SearchFaqs search={tabQueryParam} />

      <div className="flex  max-[900px]:flex-col  justify-between max-w-[1366px] m-auto px-5 py-10">
        <div className="container-3  min-[900px]:hidden mb-20 ">
          <div className="min-w-[220px] bg-white ">
            <div className="grid gap-6 grid-cols-1 sm:grid-cols-4 lg:grid-cols-3">
              {listTab.map((item, index) => (
                <div
                  key={index}
                  className={`flex items-center justify-start cursor-pointer border-b-2 pb-2 ${item.isActive === isActive ? "bg-white font-extrabold" : ""
                    }  ${isActive === item.isActive
                      ? "border-[#0082BA]"
                      : "border-[#ebebeb]"
                    }`}
                  onClick={() => {
                    navigate(item.link);
                    setIsActive(item.isActive);
                  }}
                >
                  <div className="pl-4 text-sm text-neutral-700">
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="container-1 px-5 max-w-[800px]">
          <div className="font-semibold text-[#111827] text-[24px]">
            {titleAccordion}
          </div>

          {titleAccordion === "What is fullidcheck.com?" && (
            <div>
              <div className="mt-3 text-[16px]">
                Fullidcheck.com is a platform designed to verify the identities
                of professionals and individuals across various roles and job
                types. This platform enables anyone to confirm the identity of a
                person they are interacting with, whether for professional,
                personal, or transactional purposes. Our service is especially
                useful for those seeking to verify the identity of a
                tradesperson, a professional, or even someone who has met
                online. By linking profiles on fullidcheck to register.dev,
                users can send a verification request that prompts the recipient
                to confirm their identity, report spam, or indicate a stolen ID.
                This ensures that the person you are dealing with is registered
                and trusted, providing peace of mind and preventing various
                types of scams and fraudulent activities, including:
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Identity Verification Scams
                </div>

                <div className="mt-1">
                  ● Identity Theft: Preventing unauthorised use of personal
                  information to impersonate someone else.
                </div>
                <div className="mt-1">
                  ● Fake IDs: Detecting and rejecting forged or counterfeit
                  identification documents.
                </div>
                <div className="mt-1">
                  ● Phishing Scams: Protecting against attempts to obtain
                  sensitive information through deceptive emails or websites.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Employment and Freelancer Scams
                </div>

                <div className="mt-1">
                  ● Fake Job Offers: Ensuring job listings are legitimate and
                  employers are verified.
                </div>
                <div className="mt-1">
                  ● Non-existent Freelancers: Verifying the identity of remote
                  workers to ensure they are real and qualified.
                </div>
                <div className="mt-1">
                  ● Fraudulent Resumes: Checking the authenticity of
                  qualifications and work history.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Employment and Freelancer Scams
                </div>

                <div className="mt-1">
                  ● Ponzi Schemes: Identifying and preventing fraudulent
                  investment operations.
                </div>
                <div className="mt-1">
                  ● Advance Fee Fraud: Ensuring legitimacy before fees for
                  services or opportunities are paid upfront.
                </div>
                <div className="mt-1">
                  ● Fake Investment Opportunities: Verifying the authenticity of
                  investment offers and platforms.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Online Dating and Romance Scams
                </div>

                <div className="mt-1">
                  ● Catfishing: Preventing individuals from creating fake
                  profiles to deceive others for romantic or financial gain.
                </div>
                <div className="mt-1">
                  ● Romance Scams: Verifying identities to ensure genuine
                  interactions on dating sites. E-commerce and Online
                  Marketplace Scams
                </div>
                <div className="mt-1">
                  ● Fake Sellers: Ensuring sellers on online marketplaces are
                  legitimate.
                </div>
                <div className="mt-1">
                  ● Non-delivery of Goods: Verifying transactions to prevent
                  buyers from being scammed by non- existent products.
                </div>
                <div className="mt-1">
                  ● Counterfeit Products: Ensuring the authenticity of products
                  sold online. Rental and Real Estate Scams
                </div>
                <div className="mt-1">
                  ● Fake Rental Listings: Verifying landlords and property
                  listings to prevent fraudulent rental offers.
                </div>
                <div className="mt-1">
                  ● Real Estate Fraud: Ensuring real estate transactions involve
                  legitimate properties and owners.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Financial Services Scams
                </div>

                <div className="mt-1">
                  ● Loan Scams: Verifying lenders to prevent fake loan offers.
                </div>
                <div className="mt-1">
                  ● Online Lending Scams: Ensuring online lending platforms are
                  legitimate.
                </div>
                <div className="mt-1">
                  ● Credit Card Fraud: Preventing unauthorised use of credit
                  card information.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Healthcare and Insurance Scams
                </div>

                <div className="mt-1">
                  ● Medical Identity Theft: Protecting personal health
                  information from being fraudulently used.
                </div>
                <div className="mt-1">
                  ● Fake Insurance Policies: Verifying insurance providers and
                  policies to prevent fraud.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Education and Certification Scams
                </div>

                <div className="mt-1">
                  ● Diploma Mills: Ensuring educational institutions and their
                  credentials are legitimate.
                </div>
                <div className="mt-1">
                  ● Fake Certifications: Verifying professional certifications
                  and licenses.
                </div>
                <div className="mt-1">
                  ● Fake Charities: Ensuring charity organisations and
                  fundraising campaigns are legitimate.
                </div>
                <div className="mt-1">
                  ● Crowdfunding Fraud: Verifying the authenticity of
                  crowdfunding projects and their creators.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Travel and Accommodation Scams
                </div>

                <div className="mt-1">
                  ● Fake Travel Agencies: Verifying travel agencies and their
                  offers.
                </div>
                <div className="mt-1">
                  ● Accommodation Scams: Ensuring listings on accommodation
                  platforms are genuine.
                </div>
              </div>

              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Subscription and Membership Scams
                </div>

                <div className="mt-1">
                  ● Fake Subscription Services: Verifying the legitimacy of
                  subscription-based services.
                </div>
                <div className="mt-1">
                  ● Membership Scams: Ensuring membership organizations and
                  their offers are genuine.
                </div>
              </div>
              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Vehicle Rental and Sharing Scams
                </div>

                <div className="mt-1">
                  ● Fake Car Rentals: Verifying vehicle rental companies and
                  their offers.
                </div>
                <div className="mt-1">
                  ● Car Sharing Fraud: Ensuring car-sharing services and
                  participants are legitimate.
                </div>
              </div>
              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Second-hand and Used Goods Scams
                </div>

                <div className="mt-1">
                  ● Fake Listings: Verifying sellers and items listed on
                  second-hand marketplaces.
                </div>
                <div className="mt-1">
                  ● Non-delivery of Second-hand Goods: Ensuring transactions for
                  used goods are legitimate.
                </div>
              </div>
              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Professional Services Scams
                </div>

                <div className="mt-1">
                  ● Fake Professionals: Verifying the identity and credentials
                  of professionals offering services.
                </div>
                <div className="mt-1">
                  ● Unlicensed Tradespersons: Ensuring tradespersons have the
                  necessary licenses and qualifications.
                </div>
              </div>
              <div>
                <div className="font-bold text-[#111827] text-[16px] mt-7">
                  Cybersecurity Scams
                </div>

                <div className="mt-1">
                  ● Hacking and Phishing: Protecting users from cyber threats
                  and ensuring secure interactions.
                </div>
                <div className="mt-1">
                  ● Malware and Ransomware: Preventing the spread of malicious
                  software through verified platforms.
                </div>
              </div>
            </div>
          )}

          <div className="w-full">
            {titleAccordion !== "What is fullidcheck.com?" && (
              // {/* NEW VERSION */}
              // {/* <FaqAccordion items={accordionData} query={tabQueryParam} /> */}

              // {/* OLD VERSION */}
              <FaqAccordion items={accordionData} query={tabQueryParam} activeIndex={activeIndex}
                setActiveIndex={setActiveIndex} />
            )}
          </div>
        </div>

        <div className="container-2  max-[900px]:hidden">
          <div className="min-w-[220px] bg-white ">
            {listTab.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-start cursor-pointer ${item.isActive === isActive ? "bg-white font-extrabold" : ""
                  }`}
                onClick={() => {
                  navigate(item.link);
                  setIsActive(item.isActive);
                }}
              >
                <div
                  className={`h-12 w-[4px] ${isActive === item.isActive ? "bg-[#0082BA]" : "bg-[#ebebeb]"
                    }`}
                ></div>
                <div className="pl-4 text-sm text-neutral-700">
                  {item.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Viewseemore;
