import React from "react";
import { useNavigate } from "react-router-dom";
import useRedirectToCoinProfilePage from "../hooks/useRedirectToCoinProfilePage";
import { RxCrossCircled } from "react-icons/rx";
import { GoCheckCircle } from "react-icons/go";
function ResignAsCoinDev() {
  const navigate = useNavigate();
  const { coin, redirect } = useRedirectToCoinProfilePage();
  if (redirect) {
    return redirect;
  }
  return (
    <div>
      {coin && (
        <>
          <div className="self-stretch tracking-tight leading-[110%] max-md:max-w-full">
            <span className="font-bold">{coin.ticker} Coin</span> &gt; Resign as
            Coin Developer
          </div>

          <div className="flex flex-col justify-center items-center mt-20">
            <div className="text-black90">
              Are you sure you want to resign as a coin developer?
            </div>
            <textarea
              name="text-area-resign"
              id="resign-msg"
              className="border-2 mt-3 rounded-xl w-full max-w-[40%] max-md:max-w-[90%] min-h-[150px] p-3"
            ></textarea>

            <div className="text-white font-semibold text-sm17 space-x-5 flex mt-4">
              <button
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
                className="flex items-center px-4 py-2 rounded-full bg-[#4A4A4A]"
              >
                <span className="text-[20px] mr-2">
                  <GoCheckCircle />
                </span>
                {` `} Yes, I want to Resign
              </button>
              <button
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
                className="flex items-center px-4 py-2 rounded-full bg-[#00A018]"
              >
                <span className="text-[20px] mr-2">
                  <RxCrossCircled />
                </span>
                {` `} No
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ResignAsCoinDev;
