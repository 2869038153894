import React from "react";
import { useNavigate } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";
function ReadyRecognized() {

    const navigate = useNavigate();

    return (
        <section className="flex overflow-hidden flex-col justify-center items-center self-center w-full bg-blue-900 rounded-[24px] max-md:rounded-none max-md:w-full">
            <div className="flex flex-col justify-center w-[1440px] px-32 py-12 max-md:px-5 max-[1200px]:w-full">
                <div className="flex flex-col justify-center w-full text-center text-gray-50 max-md:max-w-full">
                    <div className="flex flex-col w-full max-md:max-w-full">
                        <div className="gap-2 self-center pb-2 text-sm font-semibold border-b border-teal-400">PROVE YOU ARE WHO YOU SAY YOU ARE</div>
                        <h2 className="mt-2 text-4xl max-md:text-[24px] font-bold max-md:max-w-full">Ready to become a recognised, trusted, and verified individual across the globe?</h2>
                    </div>
                    <p className="mt-4 text-lg max-md:max-w-full">Join a community of distinguished individuals who have earned global trust and recognition through verification. Elevate your credibility and unlock new opportunities today!</p>
                </div>
                <button onClick={() => { navigate("/register"); window.scrollTo(0, 0); }} className="flex gap-1 justify-center items-center self-center py-3 pr-5 pl-6 mt-10 max-w-full text-lg font-medium text-blue-900 bg-indigo-100 rounded-[99px] w-[180px] max-md:w-full">
                    <span className="self-stretch my-auto">Get Verified</span>
                    <GoArrowRight size={20} className="object-contain shrink-0 self-stretch my-auto aspect-square" />
                </button>
            </div>
        </section>
    );
}

export default ReadyRecognized;
