import * as React from "react";
import { useNavigate } from "react-router-dom";

function NotFoundPage() {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const navigate = useNavigate();
    const redirectToHomepage = () => {
        if(isLoggedIn){
            navigate("/dashboard/forum");
            return;
        }
        else{
            navigate("/");
            return;
        }
    };

    const redirectToContact = () => {
        navigate("/contact");
    };

    return (
        <div className="flex overflow-hidden flex-col items-center justify-center bg-gray-50 border h-screen max-md:h-full">
            <div className="flex gap-10 items-center max-md:w-full max-md:flex-col max-md:gap-0 max-md:justify-center max-md:py-10">
                <div className="flex flex-col items-start self-stretch my-auto min-w-[240px] max-md:w-full max-md:items-center">
                    <div className="flex flex-col self-stretch max-md:w-full max-md:items-center">
                        <div className="text-6xl font-bold text-blue-900 max-md:text-4xl max-md:text-[24px]">
                            So Sorry!
                        </div>
                        <div className="mt-2 text-3xl font-medium text-neutral-700 max-md:w-[80%] max-md:text-center max-md:text-[24px]">
                            The page you are looking for cannot be found
                        </div>
                    </div>
                    <div className="flex flex-col mt-12 text-neutral-600 max-md:mt-10 max-md:text-center max-md:items-center">
                        <div className="text-xl font-semibold max-md:text-[16px]">Possible Reasons</div>
                        <div className="flex flex-col mt-6 text-base max-md:text-[14px]">
                            <div>The address may have been typed incorrectly.</div>
                            <div className="mt-4">It may be a broken or outdated link</div>
                        </div>
                    </div>
                    <div className="flex gap-4 items-start mt-12 text-base font-medium max-md:mt-10 max-md:w-full max-md:flex-col max-md:px-5">
                        <div onClick={redirectToContact} className="cursor-pointer gap-2 self-stretch px-4 py-2.5 text-blue-900 whitespace-nowrap border border-blue-900 border-solid rounded-[99px] text-center">
                            Help
                        </div>
                        <div onClick={redirectToHomepage} className="gap-2 self-stretch px-4 py-2.5 text-white bg-blue-900 rounded-[99px] cursor-pointer text-center">
                            Go to Homepage
                        </div>
                    </div>
                </div>
                <img
                    loading="lazy"
                    src="/404.png"
                    className="object-contain self-stretch my-auto aspect-square min-w-[240px] w-[440px] max-md:w-full "
                />
            </div>
        </div>
    );
}

export default NotFoundPage;