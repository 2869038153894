import React, { useState } from "react";
import useRedirectToCoinProfilePage from "../hooks/useRedirectToCoinProfilePage";
import { useNavigate } from "react-router-dom";
import { RxCrossCircled } from "react-icons/rx";
import { GoCheckCircle } from "react-icons/go";

function RemoveCoinDevMain() {
  const [isSelected, setIsSelected] = useState("");
  const [next, setNext] = useState(false);
  const navigate = useNavigate();
  const [RemoveSelectedDev, setRemoveSelectedDev] = useState({
    name: "",
    img: "",
    index: null,
  });
  // redirect to coin profile if url is not the correct one
  const { coin, redirect } = useRedirectToCoinProfilePage();
  if (redirect) {
    return redirect;
  }

  return (
    <div>
      {coin ? (
        <div className="w-full ">
          <div className="self-stretch tracking-tight leading-[110%] max-md:max-w-full">
            <span className="font-bold">{coin.ticker} Coin</span> &gt; Remove a
            Coin Developer
          </div>
          {!next && (
            <>
              <div className="mt-8 text-base tracking-tight leading-6 text-black text-opacity-80">
                {` Select A Coin Developer of ${coin.ticker}`}
              </div>

              <div className="mt-7 flex max-md:flex-col max-md:items-center w-full space-x-10 pl-10 max-md:pl-0  items-start">
                <div>
                  {coin.member.map((data, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (index === 0) {
                          return;
                        }
                        setRemoveSelectedDev({
                          name: data.fullName,
                          img: data.img,
                          index: index,
                        });
                        setIsSelected(index);
                      }}
                      className={`flex space-x-3 justify-start cursor-pointer items-center ${
                        index === 0 ? "opacity-50" : "opacity-100"
                      }`}
                    >
                      <button
                        disabled={index === 0 ? true : false}
                        className={`shrink-0 w-5 h-5 ${
                          isSelected === index
                            ? "bg-teal-400"
                            : "bg-neutral-400"
                        } rounded-full`}
                      ></button>
                      <img
                        src={data.img}
                        alt={`profile-img-${data.fullName}`}
                      />
                      <div>{`${data.fullName} ${
                        index === 0 ? "(You)" : ""
                      }`}</div>
                    </div>
                  ))}
                </div>
                <div className="w-full max-md:mt-10 pl-10 max-md:pl-0">
                  <div className="font-semibold">Reason For Removal</div>
                  <textarea className="w-full max-md:max-w-[70%] max-w-[50%] px-4 pt-4 pb-14 mt-2 rounded-2xl border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"></textarea>

                  <div className="flex  gap-4 self-end mt-12 text-base font-semibold text-white max-md:mt-10">
                    <button
                      className="justify-center px-8 py-4 whitespace-nowrap bg-sky-600 rounded-3xl"
                      onClick={() => {
                        navigate("/dashboard/coin-profile");
                        window.scrollTo(0, 0);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="justify-center px-10 py-4 bg-blue-900 rounded-3xl max-md:px-5"
                      onClick={() => {
                        if (RemoveSelectedDev.index) {
                          setNext(true);
                        } else {
                          return;
                        }
                      }}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <>ERROR NO DATA FOUND</>
      )}

      {next && (
        <div className="flex flex-col justify-center items-center mt-20">
          <div className="bg-[#FAFAFA] p-10 rounded-xl">
            <div className="flex space-x-4 justify-center items-center text-black70 mb-1">
              <img
                src={RemoveSelectedDev.img}
                alt="devtoremove"
                className="max-w-[60px]"
              />
              <div className="font-bold  text-black90">
                {RemoveSelectedDev.name}
              </div>
            </div>
            <div>{`Remove ${RemoveSelectedDev.name} as a Coin Developer?`}</div>
            <div className="text-white font-semibold text-sm17 space-x-5 flex mt-4">
              <button
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
                className="flex items-center px-4 py-2 rounded-full bg-[#4A4A4A]"
              >
                <span className="text-[20px] mr-2">
                  <GoCheckCircle />
                </span>
                {` `} Yes, remove developer
              </button>
              <button
                onClick={() => {
                  setNext(false);
                }}
                className="flex items-center px-4 py-2 rounded-full bg-[#00A018]"
              >
                <span className="text-[20px] mr-2">
                  <RxCrossCircled />
                </span>
                {` `} No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RemoveCoinDevMain;
