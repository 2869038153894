import React, { useState, useRef, useEffect } from "react";
import Loading from "../../../../component/Loading.js"
import {
  getFeatures,
  addFeature,
  updateFeature,
  deleteFeature,
} from "../../../../api/apiCall.js";
import { FaCheckCircle } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";

function AdvertisingFeatures() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  
  const [actionResponse, setActionResponse] = useState({status: null, message: null});
  const [isDeleting, setIsDeleting] = useState({id: null, deleted: null});
  const [updatingId, setUpdatingId] = useState(null);
  const [action, setAction] = useState('Add');
  const [isLoading, setIsLoading] = useState({adding: false, fetching: false});
  const [errors, setErrors] = useState({
    title: null,
    content: null,
    link: null,
    banner: null,
    keywords: null,
  })
  const [features, setFeatures] = useState({});
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [websiteImage, setWebsiteImage] = useState(null);
  const [websiteImagePreview, setWebsiteImagePreview] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const inputRefs = useRef([]);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (actionResponse) {
      const timer = setTimeout(() => {
        setVisible(false);
        setActionResponse(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [actionResponse]);

  const validateInputs = () => {
    let isValid = true;
    if(!title){
      setErrors((prev) => ({...prev, title: "Title is required" }));
      isValid = false;
    }
    if(!content){
      setErrors((prev) => ({...prev, content: "Content is required" }));
      isValid = false;
    }
    if(!websiteLink){
      setErrors((prev) => ({...prev, link: "Website Link is required" }));
      isValid = false;
    }
    if(!websiteImagePreview){
      setErrors((prev) => ({...prev, banner: "Website Image is required" }));
      isValid = false;
    }
    if(!keywords.some(keyword => keyword)){
      setErrors((prev) => ({...prev, keywords: "At least one keyword is required" }));
      isValid = false;
    }

    return isValid;
  }

  const handleClose = () => {
    setVisible(false);
    setActionResponse(false);
  };

  const handleAddKeyword = () => {
    setKeywords((prevKeywords) => [...prevKeywords, '']);
  };

  const handleChange = (e, index) => {
    const newKeywords = [...keywords];
    newKeywords[index] = e.target.value;
    setKeywords(newKeywords);
  };

  const handleDeleteKeyword = (index) => {
    const newKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(newKeywords);
    inputRefs.current.splice(index, 1);
  };

  useEffect(() => {
    const initialFetch = async () => {
      setIsLoading((prev) => ({ ...prev, fetching: true }));
      await handleGetFeatures();
      setIsLoading((prev) => ({ ...prev, fetching: false }));
    }

    initialFetch();
  }, [])

  useEffect(() => {
    if (keywords.length > 0) {
      inputRefs.current[keywords.length - 1].focus();
    }
  }, [keywords]);

  const handleGetFeatures = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getFeatures(data);
      if(response.status === 200){
        setFeatures(response.data);
      }
    }
    catch(error){
      console.log("Error fetching feature: ", error);
    }
  }
  
  const handleAddFeature = async () => {
    setErrors({
      title: null,
      content: null,
      link: null,
      banner: null,
      keywords: null,
    })
    const checkValidation = validateInputs();
    if(!checkValidation){
      return;
    }

    setIsLoading((prev) => ({ ...prev, adding: true }));
    try{
      let data = {
        id: user.id,
        token: token,
        payloads: {
          title: title,
          content: content,
          link: websiteLink,
          banner_file: websiteImage,
          keywords: keywords.join(','),
        }
      }

      const response = await addFeature(data);
      if(response.status === 201){
        await handleGetFeatures();
        setTitle('');
        setContent('');
        setWebsiteLink('');
        setWebsiteImage('');
        setWebsiteImagePreview('');
        setKeywords([]);
        inputRefs.current = [];
        setActionResponse({status: true, message: 'Feature Added'});
      }
    }
    catch(error){
      console.log("Error adding feature: ", error);
    }
    finally{
      setIsLoading((prev) => ({...prev, adding: false }));
    }
  }

  const handleWebsiteImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setWebsiteImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setWebsiteImage(file);
    }
  };

  const handleUpdateFeatures = (data) => {
    window.scrollTo(0, 0)
    setActionResponse({status: null, message: null});
    setAction('Update');
    setUpdatingId(data.id);
    setTitle(data.title);
    setContent(data.content);
    setWebsiteLink(data.link);
    setWebsiteImagePreview(data.banner);
    setKeywords(data.keywords.split(','));
  }

  const handleCancelUpdateFeatures = () => {
    setAction('Add');
    setUpdatingId('');
    setTitle('');
    setContent('');
    setWebsiteLink('');
    setWebsiteImagePreview('');
    setKeywords([]);
  }

  const handleSaveUpdateFeature = async () => {
    setErrors({
      title: null,
      content: null,
      link: null,
      banner: null,
      keywords: null,
    })
    const checkValidation = validateInputs();
    if(!checkValidation){
      return;
    }

    setIsLoading((prev) => ({ ...prev, adding: true }));
    try{
      let data = {
        id: user.id,
        token: token,
        featureId: updatingId,
        payloads: {
          title: title,
          content: content,
          link: websiteLink,
          banner_file: websiteImage,
          keywords: keywords.join(','),
        }
      }

      const response = await updateFeature(data);
      if(response.status === 200){
        await handleGetFeatures();
        setAction('Add');
        setUpdatingId('');
        setTitle('');
        setContent('');
        setWebsiteLink('');
        setWebsiteImage('');
        setWebsiteImagePreview('');
        setKeywords([]);
        inputRefs.current = [];
        setActionResponse({status: true, message: 'Feature Updated'});
      }
    }
    catch(error){
      console.log("Error adding feature: ", error);
    }
    finally{
      setIsLoading((prev) => ({...prev, adding: false }));
    }
  }

  const handleDeleteFeature = async (featureId) => {
    setIsDeleting({id: featureId, deleted: null });
    try{
      let data = {
        id: user.id,
        token: token,
        featureId: featureId,
      }

      const response = await deleteFeature(data);
      if(response.status === 200){
        setActionResponse({status: null, message: null});
        setIsDeleting({id: featureId, deleted: true });
        await handleGetFeatures();
      }
    }
    catch(error){
      console.log("Error deleting feature: ", error);
    }
  }

  
  return (
    <>
      <div className="flex flex-col pt-6 max-md:pt-0 grow text-base pb-8 max-md:mt-8 max-md:max-w-full max-md:px-3">
        {actionResponse.status && (
          <div className={`flex items-center self-center mb-6 max-md:mt-3 justify-between bg-white border border-gray-300 rounded-lg py-2 px-3 shadow-sm max-w-sm bg-green-100 text-green-600`}>
            <button onClick={handleClose} className="text-gray-500 hover:text-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            <span className={`font-semibold mx-2 text-green-600`}>{actionResponse.message}</span>
            <div>
              <FaCheckCircle size={20} />
            </div>
          </div>
        )}
        <div className="w-full flex justify-between">
          <div className="text-base font-bold tracking-tight leading-4 max-md:max-w-full">
            {action} Feature
          </div>
        </div>
        <div className="mt-2 mb-2 text-base tracking-tight leading-4 text-neutral-400 max-md:max-w-full">
          Promote your website, product, or any other things you want to feature on your professional profile. You can add of to 3 features.
        </div>
        <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          Title 
        </div>
        <input
          placeholder="Feature Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="justify-center px-4 py-3 mt-2 rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
        />
        {errors.title && (<small className="text-red-500">*{errors.title}</small>)}

        <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          Content
        </div>
        <input
          placeholder="Content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="justify-center px-4 py-3 mt-2 whitespace-nowrap rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
        />
        {errors.content && (<small className="text-red-500">*{errors.content}</small>)}

        <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
          URL/Website Link
        </div>
        <input
          placeholder="https://mywebsite.com"
          value={websiteLink}
          onChange={(e) => setWebsiteLink(e.target.value)}
          className="justify-center px-4 py-3 mt-2 whitespace-nowrap rounded-lg border border-solid border-neutral-300 leading-[121%] placeholder:text-stone-300 max-md:max-w-full"
        />
        {errors.link && (<small className="text-red-500">*{errors.link}</small>)}

        <div className="container flex justify-center bg-gray-200 max-w-full max-h-[380px] m-auto border-neutral-300 border-2 rounded-lg mt-7 relative  hover:opacity-80 overflow-hidden">
          {websiteImagePreview ? (
            <img
              alt=""
              loading="lazy"
              src={websiteImagePreview}
              className="w-full aspect-[1.89]"
            />
          ):(
            <div className="py-4 mt-7 text-neutral-400">
              <div className="text-center flex flex-col justify-center items-center text-lg">
                <div className="text-base font-semibold leading-5 text-black text-opacity-80">
                  Upload Image
                </div>
                <div className="text-sm15">Add banner</div>
                <div className="text-xs leading-4">
                  {`  (Profile Cover / Ad Space (815px x 380px)`}
                </div>
                <div className="mt-2  text-sm15 font-bold tracking-tight text-devRegBlue">
                  Upload
                </div>
              </div>
            </div>
          )}
          
          <input
            type="file"
            value=""
            accept="image/*"
            onChange={handleWebsiteImageUpload}
            className="opacity-0 absolute top-0 left-0 w-full h-full cursor-pointer"
          />
        </div>
        {errors.banner && (<small className="text-red-500">*{errors.banner}</small>)}

        <div className="mt-4 tracking-tight leading-[110%] text-neutral-600 max-md:max-w-full">
        Keywords (max of 3)
        </div>
        <div className="flex flex-col justify-center items-start p-4 mt-2 text-sm leading-4 rounded-2xl border border-solid border-neutral-300 text-neutral-600 max-md:pr-5 max-md:max-w-full">
          <div className="flex flex-wrap gap-2">
            {keywords.map((keyword, index) => (
              <div key={index} className="relative">
                <input
                  type="text"
                  className="px-3 py-2 rounded-lg border border-solid border-neutral-300 bg-gray-200"
                  value={keyword}
                  onChange={(e) => handleChange(e, index)}
                  size={keyword.length || 1}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
                <button
                  className="absolute top-[5.5px] right-1 px-2 py-1 text-white bg-red-500 rounded-full"
                  onClick={() => handleDeleteKeyword(index)}
                >
                  x
                </button>
              </div>
            ))}
            <button
              className="justify-center px-3 py-2 rounded-full bg-neutral-100 bg-gray-400 text-black"
              onClick={handleAddKeyword}
            >
              +
            </button>
          </div>
        </div>
        {errors.keywords && (<small className="text-red-500">*{errors.keywords}</small>)}

        <div className="w-full justify-end flex py-2 mt-8 mb-2 max-w-full text-base font-semibold text-white w-[309px] max-md:w-full max-md:px-0">
          <button
            className="justify-center whitespace-nowrap px-6 py-3 bg-devRegDarkBlue rounded-full max-md:px-5 w-[200px] h-14 max-md:w-full"
            onClick={() => { action==='Add' ? handleAddFeature():handleSaveUpdateFeature()}}
            disabled={isLoading.adding}
          >
            {isLoading.adding ? (
              <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              `${action} Feature`
            )}
          </button>
        </div>
      </div>

      <hr className="my-7" />

      <div className="flex flex-col mt-8 max-md:max-w-full pb-[250px]">
        <div className="text-3xl font-semibold leading-10 text-neutral-800 max-md:max-w-full">
          My Features
        </div>

        {!isLoading.fetching ? (
          features.length > 0 ? (
            features.map((feature, index) => (
              <>
                {/* get Data and loop it here */}
                <div
                  key={index}
                  className="mt-7 w-full max-md:flex-wrap max-md:pr-5 max-md:max-w-full"
                >
  
                  {/* actions in this section  */}
                  <div className="self-end w-full flex justify-end gap-2 mt-1 text-lg font-medium text-blue-900">
                    <div>
                      <button 
                        onClick={() => { updatingId === feature.id ? handleCancelUpdateFeatures():handleUpdateFeatures(feature)}}
                      >{ updatingId === feature.id ? 'Cancel':'Edit' }</button>
                    </div>
                    <div>|</div>
                    <div>
                      {isDeleting.id === feature.id && isDeleting.deleted === null && (
                        <div className="w-[25px] h-[25px] border-2 border-t-transparent border-[#1c3775] rounded-full animate-spin"></div>
                      )}
                      {isDeleting.id === feature.id && isDeleting.deleted === true && (
                        <svg width="25" height="25" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9484 20.8869C9.46389 22.124 10.6735 23.911 11.4639 24.6498C12.8556 26.0072 15.9278 29.0106 17.0824 30.1653C18.2371 31.3199 19.6598 30.6464 20.2268 30.1653L33.1134 17.2787C33.5945 16.7976 34.268 15.4746 33.1134 14.0313C31.9587 12.588 30.4329 13.4299 29.8144 14.0313L18.5257 25.3199L15.0721 21.8663C14.0927 20.8869 12.8041 19.3405 10.9484 20.8869Z" fill="#00A018"/>
                          <circle cx="22" cy="22" r="20" stroke="#00A018" stroke-width="4"/>
                        </svg>                                
                      )}
                      {isDeleting.id !== feature.id && (
                        <button className="text-red-500" onClick={() => handleDeleteFeature(feature.id)}>Delete</button>
                      )}
                    </div>
                  </div>
  
                  
                  <div>
                    <img
                      alt="img"
                      loading="lazy"
                      srcSet={feature.banner}
                      className="mt-2 mb-5 w-full aspect-[2.38] max-md:max-w-full"
                    />
                    <p className="font-bold text-[23px]">{feature.title}</p>
                    <div className="text-sm font-normal">{feature.content}</div>
                  </div>
                </div>
  
                {/* DESCRIPTION OF WORK */}
                <div className="mt-4 text-base leading-6 text-neutral-600 max-md:max-w-full">
                  {feature.description}
                </div>
  
              </>
            ))
          ) : (
            <div className="text-center text-neutral-600 w-full">
              No Features added yet.
            </div>
          )
        ):(
          <>
           <div className="w-full flex justify-center min-h-[200px]">
            <Loading />
           </div>
          </>
        )}

        

        {/* end  */}
      </div>
    </>
  );
}

export default AdvertisingFeatures;
