import React from "react";
import { useNavigate } from "react-router-dom";

function TopBannerHomepage({ bannerImg, center }) {
  const navigate = useNavigate();
  return (
    <section className="w-full h-[230px] max-md:h-[350px] mt-2 max-md:mt-0" style={{
      width: "100%",
      backgroundImage: `url(${bannerImg})`,
      // height: "230px",
      margin: "auto",
      zIndex: "-1",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    }}>
      <div
        className={`${center ? "min-[768px]:ml-[40%]" : "min-[768px]:ml-auto"
          } max-w-[1320px] items-center justify-center m-auto h-full max-xl:flex max-xl:flex-col max-xl:justify-center max-xl:items-center`}
      >
        <div className="max-sm:text-sm20 max-lg:text-[24px] min-[1279px]:pt-14 max-[426px]:pt-0 text-[33px] font-bold text-white max-md:pt-14">
          GOVERNANCE IN PREVENTING FRAUD
        </div>
        <div className="max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
          Dedicated to implementing robust fraud prevention strategies.
        </div>
        <div className="max-md:w-full max-md:px-5 flex flex-wrap items-center gap-3 max-md:mt-6">
          <button
            onClick={() => navigate("/benefits")}
            className="max-sm:text-[17px] relative flex justify-center mt-6 px-6 py-2 text-[#E0E7FF] rounded-[93.75px] border border-[#E0E7FF] max-lg:m-auto cursor-pointer max-md:w-full"
          >
            Learn More
          </button>
          <button
            onClick={() => navigate("/register")}
            className="max-sm:text-[17px] relative flex justify-center mt-6 px-6 py-2 text-blue-900 bg-[#E0E7FF] rounded-[93.75px] max-lg:m-auto cursor-pointer max-md:w-full"
          >
            Get Verified Now!
          </button>
        </div>
      </div>
    </section>
  );
}

export default TopBannerHomepage;
