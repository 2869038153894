import React, { useState, useEffect } from "react";
import StripePayment from "./StripePayment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Navigate } from "react-router-dom";
import useInspectRoute from "../../api/customHook/useInspectRoute";
import {
  getMemberSubscription,
} from "../../api/apiCall";
import StripePaymentMinor from "./minorApplicant/StripePaymentMinor";

// Define stripePromise outside the component to avoid re-initialization
// const stripePromise = loadStripe("pk_test_51KTEXhAhSIP9X4vARLNjmhPCaRg4yjAVORBMSGQI5AGiVbX1LamoPHQ4M3LZ00c3yVd7gwfTtuiqbF86gIjv2FMT00XscAra6U");

function Stripe() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');
  const [stripePromise, setStripePromise] = useState(null);

  const isMinor = localStorage.getItem('isMinor');

  // const {shouldRedirect, redirectTo} = useInspectRoute('/register/process-payment');
  // if (shouldRedirect) {
  //   return <Navigate to={redirectTo} />;
  // }

  const getStripeKey = async () => {
    try{
      let data = {
        id: user.id,
        token: token,
      }

      const response = await getMemberSubscription(data);
      if(response.status === 200){
        const stripeKey = response.data.key;
        setStripePromise(loadStripe(stripeKey));
      }
    }
    catch(error){
      console.error("Failed to get stripe key: ", error);
    }
  }

  useEffect(() => {
    getStripeKey();
  }, []);

  return (
      <Elements stripe={stripePromise}>
        {!isMinor ? (
          <div className="bg-[#F9FAFB] py-14 h-full">
            <StripePayment />
          </div>
        ):(
          <div className="bg-[#F9FAFB] py-14 h-full">
            <StripePaymentMinor />
          </div>
        )}
      </Elements>
  );
}

export default Stripe;
