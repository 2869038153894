import { basicAxios, authAxios } from "../config/common";

export const auth_api = {
	login: async (email, password) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append('email', email);
			formData.append('password', password);
			return basicAxios.post('/api/login', formData)
				.then(response => JSON.parse(response.data))
				.then(data => {
					if (data.errors) {
						reject(data);
					} else {
						resolve(data);
					}
				}).catch(err => {
					reject(err);
				});

		});
	},
	resendLink: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().post('/api/email/verification-notification').then(response => JSON.parse(response.data)).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	logout: async () => {
		return new Promise((resolve, reject) => {
			return authAxios().post('/api/logout').then(response => response.data).then(data => {
				resolve(data);
			}).catch(err => {
				reject(err);
			});
		});
	},
	register: async (pData) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(pData);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, pData[item]);
			});
			return basicAxios.post('/api/register', formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors) {
					reject(data);
				} else {
					resolve(data);
				}
			}).catch(err => {
				reject(err);
			});

		});
	},
	personalInfo: async (id, data) => {
		const toUrlEncodedString = (data) => {
			return Object.keys(data)
				.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};
		return new Promise((resolve, reject) => {
			const urlencodedData = toUrlEncodedString(data);
			return authAxios().put(`/api/user/${id}/profile-info`, urlencodedData, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
			}).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	emergencyContact: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/user/${id}/emergency-contact`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	processPayment: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/subscriptions/registration-payment`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	validateCoupon: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/validate-coupon?code=${data.code}`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	membershipValues: async (id) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/membership-values`)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors)
						reject(data);
					else
						resolve(data);
				}).catch(err => {
					reject(err);
				})
		});
	},
	uploadDocument: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/user/${id}/file-uploads`, formData)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors) {
						reject(data);
					}
					else {
						resolve(data);
					}
				}).catch(err => {
					reject(err);
				})
		});
	},
	deleteDocument: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().delete(`/api/user/${id}/file-uploads/${data.id}`).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	compileDocuments: async (id, data) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			data.ids.forEach(item => {
				formData.append('ids[]', item);
			});
			return authAxios().post(`/api/user/${id}/compile-documents`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	qrCode: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/qr-code?id=${id}&for=${data.for}`).then(response => response.data).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	scheduleInterview: async (id, data) => {
		return new Promise((resolve, reject) => {
			const keys = Object.keys(data);
			const formData = new FormData();
			keys.forEach(item => {
				formData.append(item, data[item]);
			});
			return authAxios().post(`/api/user/${id}/schedule-interview`, formData).then(response => JSON.parse(response.data)).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
	countryManagers: async () => {
		return new Promise((resolve, reject) => {
			return basicAxios.get('/api/country-managers').then(response => JSON.parse(response.data)).then(data => {
				if (data.errors) {
					reject(data);
				} else {
					resolve(data);
				}
			}).catch(err => {
				reject(err);
			});

		});
	},
	getProfileInfo: async (id) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/profile`)
				.then(response => JSON.parse(response.data)).then(data => {
					if (data.errors)
						reject(data);
					else
						resolve(data);
				}).catch(err => {
					reject(err);
				})
		});
	},
	downloadCertificate: async (id, data) => {
		return new Promise((resolve, reject) => {
			return authAxios().get(`/api/user/${id}/download-certificate`).then(response => response.data).then(data => {
				if (data.errors)
					reject(data);
				else
					resolve(data);
			}).catch(err => {
				reject(err);
			})
		});
	},
}