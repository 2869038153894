import React from 'react';
import SearchBar from '../../components/SearchBar';
import ReturnButton from '../../components/ReturnButton';
import ForumSection from './components/ForumSection';
import ForumTopic from './components/ForumTopic';

const generalDiscussionsTopics = [
  {
    title: "Career Guidance",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Job Search Type",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Skill Development",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Networking and Collaboration",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Work-Life Balance",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  }
];

const clientRecruitersTopics = [
  {
    title: "Career Guidance",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Job Search Type",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Skill Development",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Networking and Collaboration",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  },
  {
    title: "Work-Life Balance",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
    tags: ["Dev", "Client", "Professional Work"],
    posts: 14756,
    topics: 9824,
    latestPost: {
      author: "Monchii",
      topic: "Industry News",
      date: "Thu, Oct 3, 2024 9:27 AM"
    }
  }
];

function MainForum() {
  return (
    <main className="flex flex-1 shrink gap-6 px-8 py-6 basis-0 min-w-[240px] size-full max-md:px-0 max-md:max-w-full">
      <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
        <ReturnButton pathName="Dashboard" url="/dashboard/messages?tab=devreg" />
        <SearchBar />
        <ForumSection title="General Discussions" topics={generalDiscussionsTopics} />
        <ForumSection title="Client and Recruiters" topics={clientRecruitersTopics} />
      </div>
    </main>
  );
}

export default MainForum;