import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { validateCoupon, processPayment, confirmPayment } from "../../../api/apiCall";
import { setIsLoading } from "../../../actions";
import { FaLock, FaCoins, FaChevronLeft } from "react-icons/fa";
import { FormattedNumber } from "react-intl";

const useStripePayment = () => {
  const auth = useSelector((state) => state.auth);
  const userData = JSON.parse(localStorage.getItem("user"));
  const aToken = localStorage.getItem("token");


  const stripe = useStripe();
  const elements = useElements();

  const [membershipCountryValues, setMembershipCountryValues] = useState({
    currencyName: "USD",
    currencySymbol: "$",
    membershipFeeForDisplay: 135,
    membershipFeeForComputation: 0,
    membershipRenewalFee: "100",
    updated: false,
  });

  const [coupon, setCoupon] = useState('');
  const [couponUsedCode, setCouponUsedCode] = useState('');
  const [couponValue, setCouponValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState({});

  const [couponStates, setCouponStates] = useState({
    loading: false,
    success: false,
    error: false,
    message: "",
  })

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMembershipValues = async () => {
    const { id } = userData || { id: 0 };

    // await dispatch(membershipValues({ id })).then(data => {
    //   if (data?.payload) {
    //     const x = data?.payload;

    //     setMembershipCountryValues({
    //       currencyName: x.currency_name,
    //       currencySymbol: x.currency_symbol,
    //       membershipFee: x.membership_fee,
    //       membershipRenewalFee: x.membership_renewal_fee,
    //       membershipFeeForComputation: x.membership_fee,
    //       updated: true,
    //     });
    //   }
    // });
  };

  const handleSubmit = async (event) => {
    dispatch(setIsLoading(true));
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    } else if (elements.getElement(CardElement) === null) {
      setErrorMessage("Please enter correct payment method.");
      return;
    }
  
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
  
      if (error) {
        setErrorMessage(error.message);
      } else {
        setPaymentMethod(paymentMethod);
        const { id } = userData || { id: 0 };
        const data = {
          id: id,
          token: aToken,
          payloads: {
            paymentMethod: paymentMethod.id,
            coupon: couponUsedCode || '',
            membershipFee: membershipCountryValues.membershipFee,
            amount: membershipCountryValues.membershipFeeForComputation - couponValue,
          }
        };
  
        const response = await processPayment(data);
        console.log("Process Payment: ", response);
  
        if (response?.data?.clientSecret) {
          alert("SKSCJNLSK");
          let udata = JSON.parse(localStorage.getItem('user'));
  
          if (udata) {
            udata.is_paid = 1;
            localStorage.setItem('user', JSON.stringify(udata));
          } else {
            console.error("User data not found in localStorage");
          }
  
          navigate('/register/payment-success');
        } else {
          throw new Error("Payment not successful. Please check your details and try again.");
        }
      }
    } catch (error) {
      console.error("Error processing payment: ", error);
      if(error.response.status === 402){
        setClientSecret(error.response?.data?.clientSecret);
        setPaymentIntentId(error.response?.data?.payment_intent_id);
        setShowConfirmation(true);
      }
      setErrorMessage("An error occurred while processing your payment. Please try again later.");
    }
    finally {
      dispatch(setIsLoading(false));
    }
  };

  const handleValidateCoupon = async () => {
    setCouponStates(prevState => ({
      ...prevState,
      loading: true,
    }));

    try{
      if (coupon.trim() !== '') {
        let data = {
          token: aToken,
          code: coupon,
        };

        const response = await validateCoupon(data);
        console.log(response);

        if (response?.payload?.message === 'Coupon is valid') {
          setCouponValue(response?.payload?.data?.amount || 0);
          setCouponUsedCode(coupon);
        }
        else {
          setCouponStates(prevState => ({
            ...prevState,
            error: true,
            message: response?.data?.message,
          }));
        }
      }
    }
    catch(error){
      console.error("Error validating coupon: ", error);
      setCouponStates(prevState => ({
        ...prevState,
        error: true,
        message: error?.response?.data?.message,
      }));
    }
    finally{
      setCouponStates(prevState => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const handlConfirmPayment = async () => {
    dispatch(setIsLoading(true));
    try{

      const confirmResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod.id,
      })
      
      console.log(confirmResult);

      if (confirmResult.error) {
        setErrorMessage(confirmResult.error.message);
      } else if (confirmResult.paymentIntent && confirmResult.paymentIntent.status === 'succeeded') {
        let data = {
          id: userData.id,
          token: aToken,
          payloads: {
            payment_intent_id: paymentIntentId,
          }
        }

        const response = await confirmPayment(data);
        if(response.status === 200){
          userData.is_paid = 1;
          localStorage.setItem('user', JSON.stringify(userData));
          setShowConfirmation(false);
          console.log("User Info: ", localStorage.getItem('user'));
          navigate('/thankyou');
        }
      }
    }
    catch(error){
      console.error("Error confirming payment: ", error);
    }
    finally{
      dispatch(setIsLoading(false));
    }
  }

  const stringToFloat = async (strParam) => {
    if (strParam) {
      return parseFloat(strParam.replace(/,/g, ''));
    } else {
      return 0;
    }
  };

  const stringToInt = async (strParam) => {
    if (strParam) {
      return parseInt(strParam.replace(/,/g, ''));
    } else {
      return 0;
    }
  };

  return {
    membershipCountryValues,
    coupon, setCoupon,
    couponValue, setCouponValue,
    errorMessage,
    handleSubmit,
    handleValidateCoupon,
    handleMembershipValues,
    couponStates,
    showConfirmation, setShowConfirmation,
    handlConfirmPayment
  };
};

const StripePaymentMinor = () => {
  const [showCardElement, setShowCardElement] = useState(false);
  const [isFading, setIsFading] = useState(false);

  const auth = useSelector((state) => state.auth);
  const {
    handleSubmit, errorMessage, coupon, couponValue, setCoupon,
    handleValidateCoupon, handleMembershipValues, membershipCountryValues, 
    couponStates, showConfirmation, setShowConfirmation, handlConfirmPayment
  } = useStripePayment();

  const handleAddPaymentMethod = () => {
    setShowCardElement(true);
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 400,
        fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#A2A9B4',
        },
      },
      invalid: {
        iconColor: '#E25950',
        color: '#E25950',
      },
    },
  };

  useEffect(() => {
    if (!membershipCountryValues.updated) {
      handleMembershipValues();
    }
  }, [membershipCountryValues.updated, handleMembershipValues]);

  useEffect(() => {
    setIsFading(true);

    const tm = setTimeout(() => {
      setIsFading(false);
      setCoupon('');
    }, 3000);

    return () => {
      clearTimeout(tm);
    };
  }, [auth.coupon.error, setCoupon]);

  return (
    <div className="flex flex-col mx-auto mt-8 mb-12 max-w-[900px] max-md:px-5 bg-white rounded-lg border border-gray-200 max-md:mx-5">
      <div className="flex flex-col lg:flex-row bg-white rounded-2xl">
        <div className="px-4 py-6 lg:px-8 lg:py-6 lg:w-[40%]">
          <div className="flex items-center font-bold text-[#1c3775] text-lg lg:text-xl mb-6">
            <button className="mr-2 text-lg lg:text-xl"><FaChevronLeft /></button>
            Payment Method
          </div>
          {showCardElement && (
            <>
              <div className="border-devRegDarkBlue border-2 w-[90px] my-5 text-center py-1 font-bold text-xl lg:text-2xl text-[#635bff] rounded-md">
                Stripe
              </div>

              <div className="border border-gray-300 p-3 rounded-md mb-4">
                <CardElement options={CARD_ELEMENT_OPTIONS} className="StripeElement" />
              </div>

              <div id="card-errors" role="alert">
                {errorMessage && <span>{errorMessage}</span>}
              </div>
            </>
          )}
          <button
            className="font-semibold text-neutral-600"
            onClick={handleAddPaymentMethod}
          >
            + Add Payment Method
          </button>
        </div>

        <div className="py-6 lg:w-[60%] bg-white border-t lg:border-t-0 lg:border-l border-gray-200 rounded-b-lg lg:rounded-b-none lg:rounded-tr-2xl lg:rounded-br-2xl">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex lg:flex-row justify-between items-center">
              <div className="font-bold text-[#1c3775] text-lg lg:text-xl">Order Summary</div>
              <div className="flex items-center text-neutral-500 lg:mt-0">
                <FaCoins className="mr-2 text-yellow-500" />
                <div className="font-bold">
                  {membershipCountryValues.currencyName}
                </div>
              </div>
            </div>

            <div className="flex lg:flex-row justify-between mt-6">
              <div>
                <div className="font-medium text-neutral-600">Membership Fee</div>
                <div className="text-neutral-500 text-sm">{`${membershipCountryValues.currencySymbol}${membershipCountryValues.membershipRenewalFee} next year (01/15/2025)`}</div>
              </div>
              <div className="font-bold text-lg text-neutral-800 mt-4 lg:mt-0">
                {membershipCountryValues.currencySymbol}{(membershipCountryValues.membershipFeeForDisplay - couponValue).toFixed(2)}
              </div>
            </div>

            <div className="mt-6">
              <div className="font-medium text-neutral-600">Coupon Code</div>
              <div className="flex flex-col lg:flex-row items-start lg:items-center mt-2">
                <input
                  type="text"
                  className="flex-1 px-4 py-2 border rounded-lg border-neutral-300 max-md:w-full"
                  placeholder="Coupon Code"
                  value={coupon}
                  onChange={(e) => {
                    setCoupon(e.target.value);
                  }}
                />
                <button
                  onClick={handleValidateCoupon}
                  className="mt-4 lg:mt-0 lg:ml-4 bg-[#1c3775] text-white px-5 py-2 rounded-full font-bold w-full lg:w-auto">
                  Apply
                </button>
              </div>
              {couponStates.loading && <p className="mt-1 text-blue-900 text-sm">Validating coupon...</p>}
              {couponStates.error && !couponStates.loading && <p className="mt-1 text-red-900 text-sm">{couponStates.message}</p>}
            </div>

            <div className="flex justify-between mt-2 mb-4 max-md:mt-4 max-md:text-sm">
              <div className="font-medium text-neutral-600">{`Coupon Discount (FREESTAFF)`}</div>
              {/* <div className="text-neutral-600">-{membershipCountryValues.currencySymbol}{couponValue}</div> */}
            </div>

            <hr/>

            <div className="flex justify-between mt-4 text-lg font-bold">
              <div>Total Due</div>
              <div>
                <FormattedNumber
                  value={(membershipCountryValues.membershipFeeForDisplay - couponValue).toFixed(2)}
                  style="currency"
                  currency={membershipCountryValues.currencyName || "USD"}
                />
              </div>
            </div>

            <button
              onClick={handleSubmit}
              className="w-full mt-4 bg-[#1c3775] text-white px-7 py-3 rounded-full font-bold text-lg">
              Pay Now
            </button>

            {auth.loading && <p className="mt-2 text-blue-900 text-sm">Processing...</p>}
            {auth.error && <p className="mt-2 text-red-900 text-sm">{auth.error}</p>}

            <div className="flex items-center mt-6 text-neutral-500">
              <FaLock className="text-2xl" />
              <div className="ml-3 text-sm">
                <span className="font-bold">Secure checkout. </span>
                For your convenience, we will save your encrypted payment information for future payments.
              </div>
            </div>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto">
            <h2 className="text-xl font-bold mb-4">Confirm Payment</h2>
            <p>Are you sure you want to proceed with the payment?</p>
            <div className="mt-6 flex justify-end gap-4">
              <button
                onClick={() => setShowConfirmation(false)}
                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handlConfirmPayment}
                className="bg-green-500 text-white px-4 py-2 rounded-lg"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StripePaymentMinor;
