import CryptoJS from 'crypto-js';

// Use the same key for both encryption and decryption
const key = "27ae6685d618b8f4f4fa2548460fd08cdb6e3570e19079b68386acf1b7cb750f";

// Encryption function
const encryption = (tokenToEncrypt) => {
    return CryptoJS.AES.encrypt(tokenToEncrypt, CryptoJS.enc.Hex.parse(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString();
}

// Decryption function
const decryption = (tokenToDecrypt) => {
    if (tokenToDecrypt) {
        const bytes = CryptoJS.AES.decrypt(tokenToDecrypt, CryptoJS.enc.Hex.parse(key), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    return null;
}

export { encryption, decryption }
