import React, { useState, useEffect } from "react";
import { sendTicket, getTickets, sendTicketReply, getTicketReplies } from "../../../api/apiCall.js";
import SubmitTicket from "./SubmitTicket";
import TicketDetailsModal from "./TicketDetailsModal";

function Ticketing() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const [tickets, setTickets] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [replyBody, setReplyBody] = useState(""); // State for reply body
  const [response, setResponse] = useState({ success: null, message: null });
  const [errors, setErrors] = useState({ subject: null, message: null });
  const [sending, setSending] = useState(false);

  useEffect(() => {
    const fetchTickets = async () => {
      const data = { token: token };
      const response = await getTickets(data); // Fetch tickets

      // Assuming response.data contains the ticket array
      const ticketsWithCounts = response.data.map(ticket => ({
        ...ticket,
        reply_count: ticket.replies.length, // Adjust if your API response doesn't include replies
        created_at: ticket.created_at || new Date().toISOString(), // Default date
      }));

      setTickets(ticketsWithCounts);
    };

    fetchTickets();
  }, [token]);

  const validate = () => {
    setErrors({ subject: null, message: null });
    let isValid = true;

    if (!body) {
      setErrors(prev => ({ ...prev, message: "Message is required" }));
      isValid = false;
    }

    if (!subject) {
      setErrors(prev => ({ ...prev, subject: "Subject is required" }));
      isValid = false;
    }

    return isValid;
  };

  const handleSend = async () => {
    setResponse({ success: null, message: null });
    if (!validate()) return;

    setSending(true);
    try {
      const data = {
        id: user.id,
        token: token,
        payloads: { body, subject },
      };
      const response = await sendTicket(data);
      if (response.status === 201) {
        setResponse({ success: true, message: response.data.message });
        const newTicket = {
          ...response.data.data,
          reply_count: 0, // Set initial reply count
          created_at: new Date().toISOString(), // Set current date
        };
        setTickets(prev => [...prev, newTicket]);
      }
    } catch (error) {
      console.log("Error sending ticket: ", error);
      setResponse({ success: false, message: error.response?.data?.message });
    } finally {
      setSending(false);
      setModalVisible(false);
      setSubject("");
      setBody("");
    }
  };

  const handleView = async (ticket) => {
    setSelectedTicket(ticket);
    setDetailsModalVisible(true);
    // Fetch replies for the selected ticket
    const data = { token, id: ticket.id };
    const response = await getTicketReplies(data);
    setSelectedTicket(prev => ({ ...prev, replies: response.data })); // Update ticket with replies
  };

  const handleCloseDetails = () => {
    setSelectedTicket(null);
    setDetailsModalVisible(false);
    setReplyBody(""); // Clear reply body when closing modal
  };

  const handleReply = async () => {
    if (!replyBody) {
      setErrors(prev => ({ ...prev, message: "Reply is required" }));
      return;
    }
  
    try {
      const data = {
        id: selectedTicket.id,
        token: token,
        payloads: { reply: replyBody },
      };
      const response = await sendTicketReply(data);
      if (response.status === 201) {
        const newReply = response.data.data; // Directly use the response data

        // Update the ticket's replies and count in the state
        setTickets(prev => 
          prev.map(ticket => 
            ticket.id === selectedTicket.id 
              ? { 
                  ...ticket, 
                  replies: [...ticket.replies, newReply],
                  reply_count: ticket.reply_count + 1 // Increment reply count
                } 
              : ticket
          )
        );

        setReplyBody(""); // Clear reply input
        setDetailsModalVisible(false);
      }
    } catch (error) {
      console.log("Error sending reply: ", error);
    }
  };

  return (
    <div className="min-h-[87vh] pt-6 max-md:max-w-full px-5 max-md:px-3 max-md:pt-0">
      <div className="max-w-[700px] m-auto">
        <div className="flex justify-between mb-4">
          <h2 className="text-lg font-bold">Submitted Tickets</h2>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded" 
            onClick={() => setModalVisible(true)}
          >
            Submit Ticket
          </button>
        </div>
        
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">Subject</th>
              <th className="border border-gray-300 px-4 py-2">Message</th>
              <th className="border border-gray-300 px-4 py-2">Status</th>
              <th className="border border-gray-300 px-4 py-2">Replies</th>
              <th className="border border-gray-300 px-4 py-2">Date</th>
              <th className="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map(ticket => (
              <tr key={ticket.id}>
                <td className="border border-gray-300 px-4 py-2">{ticket.subject}</td>
                <td className="border border-gray-300 px-4 py-2">{ticket.body}</td>
                <td className="border border-gray-300 px-4 py-2">{ticket.status}</td>
                <td className="border border-gray-300 px-4 py-2">{ticket.reply_count}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {new Date(ticket.created_at).toLocaleString()}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  <button 
                    className="bg-blue-500 text-white px-2 py-1 rounded"
                    onClick={() => handleView(ticket)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
  
        <SubmitTicket 
          isVisible={isModalVisible} 
          onClose={() => setModalVisible(false)} 
          onSubmit={handleSend} 
          subject={subject} 
          setSubject={setSubject} 
          body={body} 
          setBody={setBody} 
          errors={errors} 
        />
  
        {selectedTicket && (
          <TicketDetailsModal 
            isVisible={isDetailsModalVisible} 
            onClose={handleCloseDetails}
            ticket={selectedTicket} 
            replyBody={replyBody} 
            setReplyBody={setReplyBody}
            onReply={handleReply}
            errors={errors}
          />
        )}
      </div>
    </div>
  );
}

export default Ticketing;
