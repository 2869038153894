import React from 'react';
import ForumTopic from './ForumTopic';

function ForumSection({ title, topics }) {
  return (
    <section className="flex overflow-hidden flex-col mt-4 w-full rounded-lg border border border-solid max-md:max-w-full">
      <h2 className="gap-2.5 self-stretch px-4 py-2 w-full text-sm font-semibold text-white bg-blue-900 max-md:max-w-full">
        {title}
      </h2>
      <div className="flex flex-col w-full max-md:max-w-full">
        {topics.map((topic, index) => (
          <ForumTopic key={index} {...topic} />
        ))}
      </div>
    </section>
  );
}

export default ForumSection;