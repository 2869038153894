import React from "react";
import TopBanner from "../../component/TopBanner";
import SidePanel from "../../component/SidePanel";

function TermsAndConditions() {
  const imgBanner =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8338b7a347f1233bd39879858483d5cef10bb8eae70aa1030e6b400988f340a6?apiKey=3ae96302e69649f5be368f89230b0596&";
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b9b6336a9d61ad74d1a23adaff3ef4107f3fbaabf840a39262881fe5ea88b812?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/terms-banner.png"} />
      <div className="self-center mt-16 w-full max-w-[1366px] max-md:mt-10 max-md:max-w-full m-auto ">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:pb-20">
          <div className="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-5 max-md:mt-10 max-md:max-w-full">
              {/* Terms and conditions of use */}
              <div className="text-3xl font-bold max-sm:text-[22px] max-sm:px-5 tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                Terms and Conditions of Use
              </div>
              <div className="mt-4 text-base  text-black70 max-md:text-[15px] max-md:px-5 max-md:max-w-full">
                PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS SITE.
              </div>{" "}
              <div className="flex flex-col self-stretch mt-6 px-5 py-12 bg-white mb-16">
                <div className="flex flex-col px-5 pb-4 max-md:max-w-full">
                  <div className="text-2xl font-semibold tracking-tight leading-7 text-devRegDarkBlue max-md:max-w-full">
                    Definitions and Site Ownership
                  </div>
                  <div className="mt-4 text-base leading-8  text-black70  max-md:max-w-full">
                    In the context of this policy, the terms "we," "us," and "our" refer specifically to GLOBAL ID VERIFICATION PTY LTD. When we mention "team members" and "associates," we encompass a broad spectrum of individuals under our direction. This includes, but is not limited to, employees, contractors, agents, and interns. The term "representatives" describes external parties engaged in conducting business related to our operations or on our behalf. This diverse group includes clients, contractors, subcontractors, suppliers, consultants, legal professionals, accountants, lobbyists, sales representatives, brokers, and franchise owners.
                    <br />
                    <br />
                    Ownership and Operation of the Site: This website, referred to as the "Site," is owned and operated by GLOBAL ID VERIFICATION PTY LTD. The domain{" "}
                    <a
                      href="https://fullidcheck.com"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://fullidcheck.com
                    </a>{" "}
                    included under this ownership (collectively referred to as{" "}"
                    <a
                      href="https://fullidcheck.com"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://fullidcheck.com
                    </a>{" "}
                    or{" "}
                    Full ID Check" in this document). As the proprietors of this domains, we manage, control, and maintain the content and services provided within these websites.
                    <br />
                    <br />
                    This Site is intended for personal and commercial purposes and is specifically designed to enable applicants to demonstrate their verified identity to potential clients.
                    Materials from{" "}
                    <a
                      href="https://fullidcheck.com"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://fullidcheck.com
                    </a>
                    , along with any related websites managed, owned, or operated by these domains, are available for use in a professional context. While users are permitted to download a single copy of these materials for private use on a personal computer, all copyrights and proprietary notices must be maintained. Any commercial use of these materials should align with the intended purpose of identity verification and professionalism. Unauthorized copying, reproduction, republishing, uploading, posting, transmitting, or distributing of these materials, or any modification for purposes beyond the stated scope, constitutes an infringement of the intellectual property rights of{" "}
                    <a
                      href="https://fullidcheck.com"
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://fullidcheck.com
                    </a>
                    .
                  </div>
                </div>
                <div className="px-5 pb-4 mt-4 text-base leading-8  text-black70  max-md:max-w-full">
                  <ol className="list-decimal ml-5">
                    <li>
                      Under this Agreement, using any material from this Site on other websites or networked computer environments is not allowed. The trademarks, service marks, and trade names (referred to collectively as the "Marks") belong exclusively to GLOBAL ID VERIFICATION PTY LTD and <a href="https://fullidcheck.com" target="_blank" rel="noopener noreferrer" className="underline">https://fullidcheck.com</a>, or their respective owners who have authorized their use by these domains. Unauthorized use of content, derivative works from this Site, and the employment of data mining, robots, or similar data gathering and extraction tools are expressly forbidden. Users are prohibited from framing any part of this Site or its content.
                    </li>
                    <br />
                    <li>
                      <a href="https://devreg.org" target="_blank" rel="noopener noreferrer" className="underline">https://devreg.org</a> or <a href="https://fullidcheck.com" target="_blank" rel="noopener noreferrer" className="underline">https://fullidcheck.com</a> endeavors to provide accurate and current information on this Site. However, no guarantees are made regarding the accuracy of this information. Neither <a href="https://devreg.org" target="_blank" rel="noopener noreferrer" className="underline">https://devreg.org</a> nor <a href="https://fullidcheck.com" target="_blank" rel="noopener noreferrer" className="underline">https://fullidcheck.com</a> accepts liability for any inaccuracies or misrepresentations in the Site's content.
                    </li>
                    <br />
                    <li>
                      Neither GLOBAL ID VERIFICATION PTY LTD nor <a href="https://fullidcheck.com" target="_blank" rel="noopener noreferrer" className="underline">https://fullidcheck.com</a> is responsible for any damage arising from the use or misuse of information made public on the Site, either voluntarily or involuntarily, by any third party. When disclosing personal information on the Site, users do so at their own risk and should be prudent in what they share.
                    </li>
                    <br />
                    <li>
                      This Site is a robust platform primarily for verifying identities and facilitating professional interactions rather than personal meetings. While it includes a forum for members to engage and share opinions, personal physical meetings are only permitted under specific conditions, such as when a client has verified a member's identity. The forum is closely moderated, particularly to protect underage users, ensuring a safe and respectful online environment. While <a href="https://fullidcheck.com" target="_blank" rel="noopener noreferrer" className="underline">https://fullidcheck.com</a> provides the framework for these professional and online interactions, they do not oversee these activities directly. Participants are strongly advised to exercise caution and sound judgment when verifying identities and engaging in forum discussions.
                    </li>
                    <br />
                    <li>
                      <b>Member Dashboard and Credit System</b>
                      <ol className="list-[upper-alpha] ml-5">
                        <li>
                          <b>Accumulating Credits:</b> Members can earn credits on their dashboard through various means: by referring new members, winning the badge draw, or participating in competitions with cash prizes. Each of these activities has specific terms that govern how credits are earned.
                        </li>
                        <li>
                          <b>Using Credits:</b> Credits accumulated in a member's dashboard can be utilized to purchase products or services offered on our website. This includes membership fees, exclusive content, or any other items we may offer for sale.
                        </li>
                        <li>
                          <b>Credit Redemption:</b> Members have the flexibility to use their credits at their discretion within the Site. The process for redemption will be straightforward and accessible directly from the dashboard.
                        </li>
                        <li>
                          <b>Limitations and Conditions:</b> While credits are versatile, they may be subject to certain limitations or conditions, such as expiry dates, specific redemption rules, or restrictions on certain products or services. These specifics will be clearly communicated on our website.
                        </li>
                        <li>
                          <b>Non-Transferability:</b> Credits earned are non-transferable and are tied to the individual member's account. They cannot be exchanged or sold to other members or external parties.
                        </li>
                        <li>
                          <b>Withdrawal of Credits:</b> In the case of choosing to cash out credits (where applicable), members must follow the specified procedure, and the payout will be processed by our administration in a timely manner.
                        </li>
                        <li>
                          <b>Adjustment and Cancellation:</b> We reserve the right to adjust or cancel credits in case of any discrepancies, fraudulent activities, or violations of our Terms and Conditions.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Terms and Conditions for Underage Members</b>
                      <ol className="list-[upper-alpha] ml-5">
                        <li>
                          <b>Eligibility for Underage Members:</b> Individuals below the age of 18 (referred to as "Underage Members") are eligible to join our website but must adhere to additional verification and consent processes as outlined below.
                        </li>
                        <li>
                          <b>Parental Consent and Registration:</b>
                          <ol className="list-decimal ml-5">
                            <li>Underage Members must have a parent or legal guardian complete the registration process on their behalf.</li>
                            <li>This process includes submitting government-issued identification documents to verify the age and identity of the Underage Member.</li>
                            <li>The parent or guardian must undergo facial recognition verification along with the Underage Member as part of the registration.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Mandatory Live Interview:</b>
                          <ol className="list-decimal ml-5">
                            <li>Upon successful initial verification, Underage Members are required to participate in a 15-minute live interview conducted via Zoom with a Senior Country Manager.</li>
                            <li>The interview is designed to assess the suitability of the Underage Member for participation on the Site.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Parental Responsibility:</b>
                          <ol className="list-decimal ml-5">
                            <li>The parent or legal guardian is responsible for all actions taken by the Underage Member on the site.</li>
                            <li>This responsibility includes oversight of the Underage Member’s interactions and contributions on the website.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Compliance with Site Rules:</b>
                          <ol className="list-decimal ml-5">
                            <li>Underage Members must comply with all website rules and guidelines, including those related to content posting, interactions with other members, and participation in any site activities or events.</li>
                            <li>Violation of these rules may result in suspension or termination of the Underage Member’s account.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Data Privacy and Safety:</b>
                          <ol className="list-decimal ml-5">
                            <li>We are committed to protecting the privacy and safety of our Underage Members. Personal information will be handled in accordance with our Privacy Policy and applicable data protection laws.</li>
                            <li>We encourage parents or guardians to monitor the Underage Member’s use of the site to ensure a safe and positive experience.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Amendments to Underage Member Terms:</b>
                          <ol className="list-decimal ml-5">
                            <li>These terms and conditions are subject to change. We will notify the parent or guardian of any significant changes that affect the participation of Underage Members.</li>
                            <li>Continued use of the site by the Underage Member after changes have been made will constitute acceptance of the revised terms.</li>
                          </ol>
                        </li>
                        <li>
                          <b>Terms and Conditions for Participation of Minors (Age 13-18)</b>
                          <ol className="list-decimal ml-5">
                            <li>
                              <b>Terms and Conditions for Participation of Minors (Age 13-18)</b>
                              <p className="ml-5 mt-2">
                                The  minimum age for participation on the site is explicitly set at 13 years old. This policy complies with international standards, including the Children's Online Privacy Protection Act (COPPA) and equivalent global regulations, ensuring the safety and privacy of underage users.
                              </p>
                            </li>
                            <li>
                              <b>Terms and Conditions for Participation of Minors (Age 13-18)</b>
                              <ul className="list-disc ml-5 mt-2">
                                <li>
                                  Users between 13 and 18 years of age are required to obtain verifiable parental  consent. This process involves parents or legal guardians providing  consent through a secure online system, which verifies their identity and relationship to the minor.
                                </li>
                                <li>
                                  Regular  audits will be conducted to ensure ongoing compliance with this verification process.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <b>Supervised Access in Main Forum Areas:</b>
                              <ul className="list-disc ml-5 mt-2">
                                <li>
                                  Minors are allowed to participate only in the main forum areas, focusing on  software development and coding discussions.
                                </li>
                                <li>
                                  These forums are under the constant supervision of Senior Moderators trained in child safety and online community management.
                                </li>
                              </ul>
                            </li>
                            <li>
                              <b>Messaging and Communication Controls:</b>
                              <ol className="list-[upper-alpha] ml-5 mt-2">
                                <li>
                                  Personal messaging features for underage users are locked to prevent unsolicited contact. The only permissible direct messages are from administrators for necessary communication or to address complaints and concerns raised by the minors.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <b>Content Moderation and Family-Friendly Environment:</b>
                              <ol className="list-[upper-alpha] ml-5 mt-2">
                                <li>
                                  Strict moderation ensures that language and behavior within the forums remain appropriate and family-friendly. Automated filters and manual oversight are in place to enforce these standards.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <b>Encouragement of Learning and Sponsorship Opportunities:</b>
                              <ol className="list-[upper-alpha] ml-5 mt-2">
                                <li>
                                  The forums are designed to foster learning, with bright and talented young members being potential candidates for sponsorship programs, supporting their educational and professional growth.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <b>Commitment to Safe Practices:</b>
                              <ol className="list-[upper-alpha] ml-5 mt-2">
                                <li>
                                  We are dedicated to safeguarding all members, regardless of age, against abuse and unsafe practices. Our safety protocols are periodically reviewed and updated to adapt to evolving online risks.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <b>Reporting and Response System:</b>
                              <ol className="list-[upper-alpha] ml-5 mt-2">
                                <li>
                                  A transparent and accessible reporting system is available for users to report any concerns. Responses to such reports will be prompt, ensuring the continued safety of our online environment
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Member Badge Draw and Dashboard Credit Terms</b>
                      <ol className="list-decimal ml-5 mt-2">
                        <li>
                          <b>Dashboard Overview:</b>
                          <ol className="list-[upper-alpha] ml-5 mt-2">
                            <li>
                              Each member's dashboard is a multifunctional area where they can track and manage their credits. This includes rewards from referrals, winnings from competitions, and prizes from the Badge Draw.
                            </li>
                          </ol>
                        </li>
                        <li>
                          <b>Earning Credits:</b>
                          <ol className="list-[upper-alpha] ml-5 mt-2">
                            <li>Referrals: Bringing a new member earns a $10.00 USD credit.</li>
                            <li>Competitions: Participating and winning in competitions results in cash prizes credited to the dashboard.</li>
                            <li>Badge Draw: Winning the weekly Badge Draw adds the prize to the member's credit.
                              <ol className="list-decimal ml-5 mt-2">
                                <li>
                                  <b>Utilizing Credits: Credits accumulated in a member's dashboard can be used for:</b>
                                  <ol className="list-[upper-alpha] ml-5 mt-2">
                                    <li>Membership Fees: Apply credits towards membership renewal or other related fees.</li>
                                    <li>Purchases: Buy products or services offered on the website.</li>
                                    <li>Cash Out: Members can request a payout of their credits, which the site administration will process.</li>
                                  </ol>
                                </li>
                                <li>
                                  <b>Badge Draw Specifics:</b>
                                  <ol className="list-[upper-alpha] ml-5 mt-2">
                                    <li>Participation: All registered members are automatically entered into the weekly Badge Draw.</li>
                                    <li>Selection and Notification: Winners are randomly selected and notified through the contact information provided in their profile.</li>
                                    <li>Prize Allocation: Badge Draw winnings are credited directly to the member's dashboard.</li>
                                  </ol>
                                </li>
                                <li>
                                  <b>Badge Terms and Conditions:</b>
                                  <ol className="list-[upper-alpha] ml-5 mt-2">
                                    <li>Modification Rights: We reserve the right to modify or discontinue the Badge Draw, competitions, and referral systems at any time.</li>
                                    <li>Compliance: Members are responsible for ensuring that their participation complies with local laws.</li>
                                    <li>Non-transferable: Credits and winnings cannot be transferred between members.</li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Detailed Process of Identity Verification and Anonymity Post-Verification:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          <b>Initial Document Upload:</b> Applicants are required to upload two forms of government-issued identification and a recent utility bill (not older than 30 days) for address verification during the sign-up process. This step is vital for authenticating the member's identity.
                        </li>
                        <li>
                          <b>Data Handling Post-Verification:</b> Once a member’s identity is verified and they are accepted into the site, the following data handling procedures are implemented:
                          <ul className="list-disc ml-5 mt-2">
                            <li>The provided identification documents are compiled into a single file, encrypted, and zipped.</li>
                            <li>This file is then labeled with the member’s unique serial number and transferred to secure offline storage.</li>
                          </ul>
                        </li>
                        <li>
                          <b>Permanent Deletion from Online Server:</b> Subsequent to the transfer to offline storage, all personal information and documents initially uploaded to the online server are permanently deleted. This ensures that sensitive personal data does not remain online beyond the necessary period.
                        </li>
                        <li>
                          <b>Retention and Use of Serial Number:</b> After the verification process, the member's unique serial number remains the only piece of identifying information on the website. Members may then add additional information to their profiles at their discretion.
                        </li>
                        <li>
                          <b>Privacy and Anonymity:</b> Our stringent privacy measures guarantee that members’ personal information, beyond their unique serial number, is not stored on the website. This policy ensures the anonymity of our members post-verification.
                        </li>
                        <li>
                          <b>Commitment to Member Privacy:</b> These procedures illustrate our unwavering commitment to privacy and security. We are dedicated to ensuring that our members' personal information is handled with the utmost care and confidentiality.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Forum Participation Guidelines:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          <b>User Identification:</b> Each user category is assigned a specific text color for easy identification. Moderators are identified in blue for efficient monitoring, especially for the supervision of underage users. Other colors are assigned to different user categories, providing an additional layer of privacy and security.
                        </li>
                        <li>
                          <b>Underage User Protection:</b> Special attention is given to protecting underage users. Any communication with or about underage users must be appropriate, respectful, and strictly non-exploitative. Moderators will closely monitor interactions involving these users.
                        </li>
                        <li>
                          <b>Appropriate Content:</b> All forum users must refrain from posting content that is inappropriate, offensive, or harmful, particularly content that could negatively impact underage users. This includes, but is not limited to, explicit language, hate speech, and adult content.
                        </li>
                        <li>
                          <b>Privacy and Safety:</b> Users are encouraged to protect their privacy and that of others. Sharing personal information, especially of underage users, is strongly discouraged unless necessary for professional verification purposes, and even then, the utmost caution must be exercised.
                        </li>
                        <li>
                          <b>Reporting Mechanism:</b> The forum includes a reporting feature for users to report any suspicious or inappropriate activity. Reports involving underage users will receive priority attention from moderators.
                        </li>
                        <li>
                          <b>Moderator Intervention:</b> Moderators have the authority to intervene in conversations, edit or remove inappropriate content, and take necessary actions to maintain a safe environment for all users, particularly for those who are underage.
                        </li>
                        <li>
                          <b>Consent and Parental Guidance:</b> Underage users must have parental consent to participate in the forum. Parents or guardians are encouraged to monitor their child's activity on the platform.
                        </li>
                        <li>
                          <b>Zero Tolerance Policy: </b> There is a zero-tolerance policy towards bullying, harassment, or any form of abuse. Violations, especially those affecting underage users, will result in immediate action, which may include suspension or banning from the forum.
                        </li>
                        <li>
                          <b>Event Participation Rules:</b> Event Participation Rules: Besides our Forum Participation Guidelines, we specify that physical and virtual events facilitated through the site are subject to these same guidelines. Should separate terms be necessary for specific events, these will be clearly outlined to ensure participants are aware of and adhere to these additional requirements.
                        </li>
                        <li>
                          <b>Uniform Conduct Policy:</b> Our conduct rules for the forum are extended to encompass all interactive features of the site, including but not limited to chat rooms, message boards, and virtual event spaces. This ensures a consistent and respectful interaction standard is maintained across all platforms.
                        </li>
                        <li>
                          <b>Supervision of Events:</b> Just as we closely monitor forum interactions, especially those involving underage users, similar supervision and protective measures are implemented for any events organized through the site. These measures are designed to ensure a safe and respectful environment for all participants.
                        </li>
                        <li>
                          <b>Consent and Guidance for Minors: </b> Parental consent and guidance are imperative for minors participating in site interactions, whether in forums or events. This consent is crucial for ensuring the safety and well-being of underage users on our platform.
                        </li>
                        <li>
                          <b>Comprehensive Zero Tolerance Policy:</b> Our zero-tolerance stance against bullying, harassment, and abuse applies universally across the site. This policy is enforced in all forums, events, and any other interactive spaces, providing a safe and welcoming environment for all users.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>By using the Site:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          You accept that any participation in events organized through it, or in any way connected to it, is entirely at your own discretion. <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> neither endorses nor sponsors these events. Participation in any activities linked to these events is done voluntarily and at your own risk.
                        </li>
                        <li>
                          You assume full responsibility for all potential risks, including personal injury or property damage to yourself or others.
                        </li>
                        <li>
                          The Site may offer a waiver ("Waiver") for participation in these events, which requires your acknowledgment. This Waiver, provided for convenience, should be thoroughly reviewed by you. We are not liable for any issues related to the Waiver's functionality, enforceability, or any claims resulting from it.
                        </li>
                        <li>
                          By using the Site, you absolve <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a>, including its affiliates and representatives, from all liabilities, claims, or legal actions in any form, directly or indirectly associated with the events or the Site
                        </li>
                        <li>
                          The Site may feature links to external websites, owned and operated by third parties ("External Sites"). We do not control and are not responsible for the content or availability of these External Sites. Concerns regarding any external link or its content should be directed to the administrators or webmasters of those External Sites.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Users of the Site may post comments</b>, reviews, and  other content and submit suggestions, ideas, or other information, provided the content does not contain any unlawful, harmful, threatening,  abusive, harassing, defamatory, vulgar, obscene, or hateful content or   content which is racially, ethnically or otherwise objectionable, or  content which infringes upon the rights of any third party.
                      <br /><br />The user  agrees not to impersonate any person and/or other entity or communicate  under a false name or a name the user is not entitled or authorized to use.
                      <br /><a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> has the right (but not the  obligation) to remove, prohibit, edit or discontinue any content on the  Site, including content that has been posted by users.
                    </li>
                    <br />
                    <li>
                      <b>By posting any content or materials on the site</b>, you are granting <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> and its affiliated entities an ongoing, irrevocable, global license that is free of royalties and non-exclusive. This license permits these entities to utilize, sell, sublicense, reproduce, distribute, display, and create derivative works from your content, effectively treating it as their own property. Additionally, you authorize <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> and its affiliates to use your name and/or username in association with this content.
                      <br />You affirm that the content and materials you contribute are entirely your own creation and do not infringe on any third-party proprietary rights. You also agree to protect and hold harmless <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> and its affiliates against any claims arising from the content you supply.
                    </li>
                    <br />
                    <li>
                      <b>Certain sections of the Site have their own specific terms and conditions.</b> In instances where these specific terms conflict with the general Terms and Conditions, the specific terms for that section will take precedence. <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> reserves the right to modify these Terms and Conditions at any time, and such changes will be effective immediately upon posting. Your continued use of the Site signifies your acceptance of these changes, so it's recommended that you regularly review the current Terms and Conditions.
                    </li>
                    <br />
                    <li>
                      <b>You  are prohibited from sending or uploading any harmful code</b> to <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> or using this Site to exploit data for commercial purposes. "Harmful  Code" includes any software or code, commonly known as viruses, worms, trojan horses, time bombs, cancel bots, or other similar harmful software, that is designed to damage, disrupt, or interfere with proper functioning of any data, storage media, program, system, or communication. This includes software intended to exceed usage limits, alter data or system configurations, or access unauthorized information. Your adherence  to this prohibition is critical for the safety and integrity of the Site.
                    </li>
                    <br />
                    <li>
                      <b>When using  the Site, adhere to the following guidelines:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          Strictly refrain from promoting, advertising, or pushing any cryptocurrency, crypto coins, or related digital assets. Our platform does not support the advertisement or facilitation of these products.
                        </li>
                        <li>
                          Avoid engaging in the sale or promotion of tobacco products.
                        </li>
                        <li>
                          Refrain from sending spam or any form of unauthorized emails.
                        </li>
                        <li>
                          Avoid harassing, threatening, or causing distress or discomfort to others.
                        </li>
                        <li>
                          Prohibit the sharing of any content that is unlawful, harmful, threatening, abusive, defamatory, vulgar, obscene, or racially, ethnically, or otherwise objectionable, including content that violates the rights of others.
                        </li>
                        <li>
                          Never impersonate others or use a false or unauthorized name.
                        </li>
                        <li>
                          Abide by all relevant local, state, national, and international laws.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Regarding Cryptocurrency and Crypto Developers:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          Individuals or entities dealing with cryptocurrencies will have a distinct profile page. However, this profile will not be visible or accessible on the main site. This measure is in place to align with our policy of not promoting or supporting cryptocurrency-related activities on our primary platform.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>When contributing funds via the Site, you agree to:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          Be at least 18 years old.
                        </li>
                        <li>
                          Comply with all applicable laws and regulations in your region.
                        </li>
                        <li>
                          Immediately notify the service staff about any unauthorized use of your user, Stripe, or PayPal accounts.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Prohibited Activities on the Site include:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          Violating any law, regulation, or ordinance.
                        </li>
                        <li>
                          Involving in transactions related to illegal substances, risky consumer products, obscene items, copyright infringement, sexually oriented materials, firearms, or regulated weapons.
                        </li>
                        <li>
                          Promoting commercial content primarily or involving spam, repetitive, or nonsensical content.
                        </li>
                        <li>
                          Engaging in transactions that expose third-party personal information illegally, support fraudulent schemes, involve unregulated financial transactions, or associate with high-risk money service activities.
                        </li>
                        <li>
                          Selling products or services deemed fraudulent by government agencies
                        </li>
                        <li>
                          Violating laws or regulations related to prescription drugs.
                        </li>
                        <li>
                          Participating in gambling, gaming, or any prize-related activities without proper authorization and in accordance with local laws.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Prohibited Activities on the Site include:</b>
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                          We encourage you to report violations of this Acceptable Use Policy to The Admin or If you have any concerns about whether a transaction adheres to the Acceptable Use Policy, feel free to reach out to the Admin's Compliance Department at <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> for clarification.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>Definitions within this document are as follows:</b>
                      <ol className="list-disc ml-5 mt-2">
                        <li>
                          Service: Refers to this website, encompassing both <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a>.
                        </li>
                        <li>
                          User: Any individual or entity that creates an account to use the services provided.
                        </li>
                        <li>
                          Contributor or Donor: Anyone who makes a payment or contribution towards any activity facilitated by the Service.
                        </li>
                        <li>
                          <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> handle payment transactions through third-party solutions like Stripe or PayPal, rather than operating as a banking entity. Thus, any funds transferred that aren't service fees are directly processed through these third-party payment solutions. <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> bear NO liability nor responsibility for funds or associated tax obligations processed by these third-party systems.
                        </li>
                        <li>
                          Contributors, Donors, and Users concur that they will not entangle <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> in any legal disputes arising from the use of the website. Furthermore, <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >https://fullidcheck.com</a> shall not be accountable for any losses or damages incurred due to non-compliance with the terms of this Agreement.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <b>International User Restrictions:</b> Our policy prohibits any government entity or agency worldwide from accessing files, passwords, or usernames from our Site without the express approval of our administrators. The only exception to this rule is in the case of fraud or scamming, which victims must officially report to our administrators. Even in such circumstances, the administrator has the right to refuse access to this information. This decision aligns with our strong commitment to user privacy and data protection, consistent with legal standards set in Victoria, Australia, where we are based.
                    </li>
                    <br />
                    <li>
                      <b>Termination Clause:</b> We reserve the right to discontinue your access to our platform at any time for any reason. If your account is suspended, creating a new account for similar activities is strictly prohibited.
                    </li>
                    <br />
                    <li>
                      MATERIALS ON THIS SITE ARE OFFERED STRICTLY ON AN "AS IS" BASIS, WITHOUT ANY FORM OF WARRANTY, EITHER EXPRESSED OR IMPLIED. <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >HTTPS://FULLIDCHECK.COM</a> ACCEPTS NO RESPONSIBILITY OR LIABILITY FOR ANY CONTENT OR MATERIALS AVAILABLE ON THE SITE. IN COMPLIANCE WITH THE LAW TO THE GREATEST EXTENT PERMISSIBLE, ALL WARRANTIES, WHETHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, SUITABILITY FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR OTHER RIGHTS VIOLATIONS, ARE DISCLAIMED.
                      <br /><br />IN NO SITUATION, INCLUDING NEGLIGENCE, WILL <a href="https://fullidcheck.com" className="underline" target="_blank" rel="noreferrer" >HTTPS://FULLIDCHECK.COM</a> OR THEIR LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, INCLUDING DATA LOSS OR PROFIT LOSS, STEMMING FROM THE USE OR INABILITY TO USE SITE MATERIALS. IF THE USE OF SITE MATERIALS NECESSITATES SERVICING, REPAIR, OR DATA CORRECTION, YOU ARE RESPONSIBLE FOR ALL ASSOCIATED COSTS. CERTAIN JURISDICTIONS MAY NOT PERMIT THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO YOU.

                      <br /><br />DISCLAIMER OF WARRANTIES FOR CONTRIBUTIONS WE DO NOT PROVIDE ANY WARRANTIES REGARDING THE MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF OUR SERVICE. BY USING OUR SERVICE, YOU RELEASE US FROM ANY POTENTIAL LIABILITIES RELATED TO THIS AGREEMENT OR OUR PRODUCTS, WHICH MAY INCLUDE SERVICE FAILURES, NEGLIGENCE, OR OTHER TORTS. THIS RELEASE IS SUBJECT TO APPLICABLE LAWS LIMITING LIABILITY, WHEREIN OUR LIABILITY TO YOU IS CONFINED TO THE MINIMUM AMOUNT OF DAMAGES, SHOULD SUCH A MINIMUM BE MANDATED BY LAW.

                      <br /><br />WE ARE NOT RESPONSIBLE FOR ANY THIRD-PARTY OFFERS MADE THROUGH OUR WEBSITE, INCLUDING THE FAILURE OF ANY GOODS OR SERVICES TO MEET EXPECTATIONS.

                      <br /><br />WE HOLD NO RESPONSIBILITY FOR THIRD-PARTY CHARITABLE OFFERS THAT MAY PROVE TO BE FRAUDULENT, IRRESPONSIBLE, OR MISREPRESENTED.

                      <br /><br />WE ARE NOT ACCOUNTABLE FOR ANY SHORTCOMINGS OF THIRD-PARTY PAYMENT PROCESSORS ASSOCIATED WITH OUR WEBSITE.

                      <br /><br />WE BEAR NO LIABILITY FOR ANY FAILURES OR DISRUPTIONS OF GOODS OR SERVICES PROVIDED BY OUR COMPANY OR A THIRD PARTY, INCLUDING UNSCHEDULED OR UNINTENTIONAL ISSUES ON OUR WEBSITE THAT TEMPORARILY OR PERMANENTLY PREVENT WEBSITE ACCESS.

                      <br /><br />YOUR USE OF OUR SERVICE IS CONTINGENT UPON YOUR ACCEPTANCE OF THIS AND ALL OTHER SECTIONS OF THIS AGREEMENT. THE "REPRESENTATIONS & WARRANTIES" SECTION HERE IS NOT INTENDED TO LIMIT THE SCOPE OF THE INITIAL PARAGRAPH OF THIS SECTION.
                    </li>
                    <br />
                    <li>
                      "For Jurisdictions Under Australian Law: Notwithstanding any provision in these Terms, our liability is adjusted by Australian law where it conflicts with our stated limitations. This means that our liability is limited to the maximum extent permissible under Australian law. Specifically, under Australian jurisdictions, this website does not exclude or limit its liability for:
                      <ol className="list-[upper-alpha] ml-5 mt-2">
                        <li>
                        Death or Personal Injury: Any liability for death or personal injury arising from our negligence, or the negligence of our employees, agents, or subcontractors, is not excluded or limited.
                        </li>
                        <li>
                        Fraudulent Misrepresentation: We do not limit liability arising from any fraudulent misrepresentation on the part of this website or its representatives.
                        </li>
                        <li>
                        Unlawful Exclusion or Limitation: We adhere to Australian laws that prohibit the exclusion or limitation of certain types of liability. Where the law dictates that a liability cannot be excluded or limited, this website will abide by such legal requirements.
                        </li>
                      </ol>
                      <br />
                      It is our intention to comply fully with Australian consumer protection laws and regulations. Users are reminded that consumer rights under the Australian Consumer Law, including statutory guarantees relating to the provision of services, are not impacted by these Terms."
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <SidePanel image={imgUrl} />
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
