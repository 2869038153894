import React from 'react';

const Loading = () => {
  return (
    <>
      <div className="inset-0 flex flex-col items-center justify-center z-50 bg-white/75">
        <div className="relative flex justify-center items-center">
          <div className="h-10 w-10 rounded-full border-t-2 border-b-2 border-gray-200"></div>
          <div className="absolute top-0 left-0 h-10 w-10 rounded-full border-t-2 border-b-2 border-devRegBlue animate-spin"></div>
        </div>
        {/* <p className="mt-4 text-lg max-sm:text-sm font-semibold text-devRegBlue animate-pulse">
          Loading...
        </p> */}
      </div>
    </>
  );
};

export default Loading;
