import React from "react";

const TicketDetailsModal = ({ ticket, isVisible, onClose, replyBody, setReplyBody, onReply, errors }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[800px]"> {/* Adjust width as necessary */}
        <h2 className="text-lg font-bold tracking-tight leading-5 text-blue-900">
          Ticket Details
        </h2>
        <hr className="border-b border-gray-200 my-4" />

        <div className="mt-4">
          <h3 className="font-semibold">Subject:</h3>
          <p>{ticket.subject}</p>
          <h3 className="font-semibold mt-2">Message:</h3>
          <p>{ticket.body}</p>
          <h3 className="font-semibold mt-2">Status:</h3>
          <p>{ticket.status}</p>
          <h3 className="font-semibold mt-2">Replies:</h3>
          <div className="replies-list mt-4">
            {ticket.replies.map(reply => (
              <div key={reply.id} className="reply-item flex items-start border-b border-gray-300 pb-2 mb-2">
                {reply.user ? (
                  <>
                    {reply.user.profile_photo_path && (
                      <img 
                        src={reply.user.profile_photo_path} 
                        alt="Profile" 
                        className="w-8 h-8 rounded-full mr-3"
                      />
                    )}
                    <div className="reply-content">
                      <p className="font-semibold">{reply.user.fullname}:</p>
                      <p className="text-gray-800">{reply.reply}</p>
                      <p className="text-sm text-gray-500">{new Date(reply.created_at).toLocaleString()}</p>
                    </div>
                  </>
                ) : (
                  <div className="reply-content">
                    <p className="font-semibold">Anonymous:</p>
                    <p className="text-gray-800">{reply.reply}</p>
                    <p className="text-sm text-gray-500">{new Date(reply.created_at).toLocaleString()}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

        </div>

        <div className="mt-4">
          <label className="block">Reply</label>
          <textarea
            className="px-4 py-2 mt-2 w-full rounded-lg border border-solid border-neutral-300 h-[80px]"
            value={replyBody}
            onChange={(e) => setReplyBody(e.target.value)}
            placeholder="Write your reply..."
          />
          {errors.message && <small className="text-red-500">{errors.message}</small>}
        </div>
        <div className="flex justify-end mt-4">
          <button className="bg-gray-300 px-4 py-2 mr-2" onClick={onClose}>Close</button>
          <button className="bg-blue-900 text-white px-4 py-2" onClick={onReply}>Send Reply</button>
        </div>
      </div>
    </div>
  );
};

export default TicketDetailsModal;
