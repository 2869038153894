import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRegisteredCoin } from "../../../api/apiCall.js";

function CoinPageMain() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [coinData, setCoinData] = useState(null);
  const [navTo, setNavTo] = useState("overview");

  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchCoinData();
  }, []);

  const fetchCoinData = async () => {
    try {
      let data = {
        id: user.id,
        token: token,
        coinId: id,
      };

      const response = await getRegisteredCoin(data);
      console.log("Coin: ", response);
      if (response.status === 200) {
        const coinData = response.data;
        setCoinData(coinData);
      }
    } catch (error) {
      console.log("Error getting coin: ", error);
    }
  };

  const handleNavTo = (nav) => {
    setNavTo(nav);
    document.getElementById(nav).scrollIntoView({ behavior: "smooth" });
  };

  if (!coinData) return <div>Loading...</div>;

  return (
    <>
      <div className="flex flex-col self-stretch px-8 pb-6 max-md:px-2 max-md:mt-6 max-md:max-w-full"></div>
      <div className="page-container pb-20">
        <div className="header flex items-center justify-center text-black80 gap-3 max-sm:mt-4">
          <img
            src={coinData.logo || '/default-logo.png'}
            alt={`${coinData.name} icon`}
            className="max-w-[55px] max-sm:max-w-[45px]"
          />
          <div>
            <div className="font-bold text-[23px] max-sm:text-[19px]">
              {coinData.ticker}
            </div>
            <div className="font-semibold items-start text-sm20 leading-4 max-sm:text-sm17">
              {coinData.name}
            </div>
          </div>
        </div>

        <div className="badge flex flex-wrap space-x-8 space-y-2 justify-center mt-4">
          {/* Example static images, replace as necessary */}
          {[...Array(parseInt(coinData.development_team_size))].map((_, index) => (
            <img
              key={index}
              src="/profileRegisteredIcon.png"
              alt={`member ${index + 1}`}
              className="w-1/6 max-w-[120px]"
            />
          ))}
        </div>

        <div className="text-center text-black70 mt-2 text-sm14">
          Each badge is a registered team Member/Developer
        </div>

        <div className="coin-details flex space-x-2.5 max-md:space-x-1 mt-5">
          <div className="bg-devRegDarkBlue w-[20%] text-white text-sm18 max-lg:text-sm16 max-sm:text-[12px] px-7 py-8 max-lg:px-3 max-lg:py-5 rounded-lg">
            <div className="font-bold uppercase max-sm:font-semibold">
              Coin Type
            </div>
            <div className="font-normal max-sm:text-[10px]">
              {coinData.type}
            </div>
          </div>

          <div className="bg-devRegDarkBlue w-[25%] text-white text-sm18 max-lg:text-sm16 max-sm:text-[12px] px-7 py-8 rounded-lg max-lg:px-3 max-lg:py-5">
            <div className="font-bold uppercase max-sm:font-semibold">
              Current Supply
            </div>
            <div className="font-normal max-sm:text-[10px]">
              {coinData.current_supply}
            </div>
          </div>

          <div className="bg-devRegDarkBlue w-[25%] text-white text-sm18 max-lg:text-sm16 max-sm:text-[12px] px-7 py-8 rounded-lg max-lg:px-3 max-lg:py-5">
            <div className="font-bold uppercase max-sm:font-semibold">
              Max Supply
            </div>
            <div className="font-normal max-sm:text-[10px]">
              {coinData.max_supply}
            </div>
          </div>

          <div className="bg-devRegDarkBlue w-[30%] text-white text-sm18 max-lg:text-sm16 max-sm:text-[12px] px-7 py-8 rounded-lg max-lg:px-3 max-lg:py-5">
            <div className="font-bold uppercase max-sm:font-semibold">
              Total Supply Exchange
            </div>
            <div className="font-normal max-sm:text-[10px]">
              {coinData.exchange_supply}
            </div>
          </div>
        </div>

        <div className="nav flex space-x-8 text-black80 pt-3.5 pb-2 mt-4 max-md:text-sm14 leading-4 border-b-2 pl-6 max-md:pl-0 max-md:justify-center">
          <button
            className={`${navTo === "overview" ? "font-bold text-devRegDarkBlue" : "font-normal text-black80"}`}
            onClick={() => handleNavTo("overview")}
          >
            Overview
          </button>
          <button
            className={`${navTo === "coin-information" ? "font-bold text-devRegDarkBlue" : "font-normal text-black80"}`}
            onClick={() => handleNavTo("coin-information")}
          >
            Coin Information
          </button>
          <button
            className={`${navTo === "links" ? "font-bold text-devRegDarkBlue" : "font-normal text-black80"}`}
            onClick={() => handleNavTo("links")}
          >
            Links
          </button>
          <button
            className={`${navTo === "socmed" ? "font-bold text-devRegDarkBlue" : "font-normal text-black80"}`}
            onClick={() => handleNavTo("socmed")}
          >
            Social Media
          </button>
        </div>

        {/* Overview */}
        <div id="overview" className="overview flex space-x-4 mt-16 max-md:flex-col">
          <div className="max-w-[70%] max-md:max-w-[100%]">
            <img
              src={coinData.logo || '/default-banner.png'} // Placeholder image for banner
              alt="bannerImg"
              className="w-full"
            />
            <div className="desc mt-3 text-sm14 leading-4 text-black70">
              {coinData.description}
            </div>
          </div>

          <div className="max-w-[30%] max-md:max-w-[90%] max-md:mt-6 w-full bg-slate-100 rounded-lg p-4 leading-5">
            <div className="">
              <div className="title font-bold text-devRegDarkBlue">
                Coin Tracker Page
              </div>
              <div className="text-black70">{coinData.tracker_page}</div>
            </div>

            <div className="mt-3.5">
              <div className="title font-bold text-devRegDarkBlue">
                Forked Technology
              </div>
              <div className="text-black70">{coinData.forked_technology}</div>
            </div>
            <div className="mt-3.5">
              <div className="title font-bold text-devRegDarkBlue">
                Base Chain
              </div>
              <div className="text-black70">{coinData.base_chain}</div>
            </div>
            <div className="mt-3.5">
              <div className="title font-bold text-devRegDarkBlue">
                Mining Algorithm / Mechanism
              </div>
              <div className="text-black70">{coinData.mining_algorithm}</div>
            </div>
          </div>
        </div>

        {/* Coin Information */}
        <div id="coin-information" className="text-black70 font-bold text-[24px] mt-14">
          Coin Information
        </div>
        <p className="text-black70">
          Please tell us about your coin/token as much as possible as this
          is what your investors will want to see.
        </p>

        <div className="faq-coin bg-devRegDarkBlue mt-5 text-[18px] p-4 text-white font-semibold rounded-lg">
          <div>About {coinData.name}</div>
          <div className="flex mt-5 justify-between border-b-2 border-[#929292] pb-3">
            <div>How long has the digital asset been in existence?</div>
            <div className="font-normal">{coinData.existence_duration} Years</div>
          </div>
          <div className="flex mt-4 justify-between border-b-2 border-[#929292] pb-3">
            <div>Has the asset ever changed hands?</div>
            <div className="font-normal">{coinData.has_changed_hands ? "Yes" : "No"}</div>
          </div>
          <div className="flex mt-4 justify-between border-b-2 border-[#929292] pb-3">
            <div>When was it last updated?</div>
            <div className="font-normal">{coinData.updated_at}</div>
          </div>
          <div className="flex mt-4 justify-between border-b-2 border-[#929292] pb-3">
            <div>Current Market Cap</div>
            <div className="font-normal">{coinData.market_cap}</div>
          </div>
        </div>

        {/* Links */}
        <div id="links" className="text-black70 font-bold text-[24px] mt-14">
          Links
        </div>
        <div className="flex flex-wrap gap-5 mt-3">
          {coinData.source_code && (
            <a
              href={coinData.source_code}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Source Code
            </a>
          )}
          {coinData.white_paper && (
            <a
              href={coinData.white_paper}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              White Paper
            </a>
          )}
          {coinData.bitcointalk_ann && (
            <a
              href={coinData.bitcointalk_ann}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Bitcointalk Ann
            </a>
          )}
          {coinData.blockchain_explorers && (
            <a
              href={coinData.blockchain_explorers}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Blockchain Explorers
            </a>
          )}
          {coinData.mobile_app && (
            <a
              href={coinData.mobile_app}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Mobile App
            </a>
          )}
          {coinData.message_board && (
            <a
              href={coinData.message_board}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Message Board
            </a>
          )}
          {coinData.tracker_page && (
            <a
              href={coinData.tracker_page}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Tracker Page
            </a>
          )}
        </div>

        {/* Social Media */}
        <div id="socmed" className="text-black70 font-bold text-[24px] mt-14">
          Social Media
        </div>
        <div className="flex flex-wrap gap-5 mt-3">
          {coinData.social_media_x && (
            <a
              href={coinData.social_media_x}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              X (formerly Twitter)
            </a>
          )}
          {coinData.social_media_facebook && (
            <a
              href={coinData.social_media_facebook}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Facebook
            </a>
          )}
          {coinData.social_media_instagram && (
            <a
              href={coinData.social_media_instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Instagram
            </a>
          )}
          {coinData.social_media_linkedin && (
            <a
              href={coinData.social_media_linkedin}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              LinkedIn
            </a>
          )}
          {coinData.social_media_telegram && (
            <a
              href={coinData.social_media_telegram}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Telegram
            </a>
          )}
          {coinData.social_media_discord && (
            <a
              href={coinData.social_media_discord}
              target="_blank"
              rel="noopener noreferrer"
              className="text-devRegDarkBlue"
            >
              Discord
            </a>
          )}
        </div>

        {/* nav link buttons */}
        <div className="text-devRegBlue text-sm18 font-normal mt-14 space-x-7 space-y-4">
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.id}/edit-coin`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                Edit Coin Profile
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.ticker}/assign-new-head-developer`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                Assign New Head Developer
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.ticker}/remove-coin-developer`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                Remove Coin Developer
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.ticker}/resign-as-head-developer`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                Resign as Head Developer
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.ticker}/file-a-complaint`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                File a Complaint
              </button>
              <button
                onClick={() => {
                  navigate(
                    `/dashboard/coin-profile/coin/${coinData.ticker}/resign-as-coin-developer`,
                    {
                      state: { coinData },
                    }
                  );
                  window.scrollTo(0, 0);
                }}
              >
                Resign as Coin Developer
              </button>
            </div>

      </div>
    </>
  );
}

export default CoinPageMain;
