import { configureStore } from '@reduxjs/toolkit';
import valueReducer from '../reducers/valueReducer';
import authReducer from './authSlice';

const store = configureStore({
  reducer: {
    value: valueReducer,
    auth: authReducer,
  },
});

export default store;