import React from "react";
import { useNavigate } from "react-router-dom";

function TopBanner({ bannerImg, center }) {
  const navigate = useNavigate();
  return (
    <section className="w-full h-auto" style={{
      width: "100%",
      backgroundImage: `url(${bannerImg})`,
      height: "230px",
      margin: "auto",
      zIndex: "-1",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      // backgroundPosition: "center bottom",
    }}>
      <div
        className={`${
          center ? "min-[768px]:ml-[40%]" : "min-[768px]:ml-auto"
        } max-w-[1320px] items-center justify-center m-auto h-full max-xl:flex max-xl:flex-col max-xl:justify-center max-xl:items-center`}
      >
        <div className="max-sm:text-sm20 max-lg:text-[24px] min-[1279px]:pt-14 text-[33px] font-bold text-white max-md:pt-14">
          GOVERNANCE IN PREVENTING FRAUD​
        </div>
        <div className="max-sm:text-center text-white font-light text-sm15 max-sm:mt-2">
          Dedicated to implementing robust fraud prevention strategies.
        </div>
        <button
          onClick={() => navigate("/register")}
          className="w-60 max-sm:text-[17px] relative flex justify-center mt-6 px-9 py-2 font-semibold text-blue-900 bg-white rounded-[93.75px]  max-lg:m-auto cursor-pointer"
        >
          Get Verified Now!
        </button>
      </div>
    </section>
  );
}

export default TopBanner;
