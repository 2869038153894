import React from 'react';
import { GoChevronLeft } from "react-icons/go";
import { useNavigate } from "react-router-dom";

function ReturnButton({ pathName, url }) {
    const navigate = useNavigate();
    const redirectTo = () => {
        navigate(url);
    };

    return (
        <button onClick={redirectTo} className="flex flex-1 shrink cursor-pointer gap-6 basis-0 min-w-[240px] size-full max-md:max-w-full">
            <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
                <nav className="flex gap-0.5 items-center self-start text-sm leading-none text-sky-600">
                    <GoChevronLeft size={20} className='object-contain shrink-0 self-stretch my-auto aspect-square w-[18px]' />
                    Return to {pathName}
                </nav>
            </div>
        </button>
    );
}

export default ReturnButton;