import React, { useEffect, useState } from "react";
import TopBanner from "../../component/TopBanner";
import { useNavigate } from "react-router-dom";
import { Country, State, City } from "country-state-city";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Loading from "../../component/Loading";

function AboutUs({ teamMembers, loading }) {
  const navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [filterCountry, setFilterCountry] = useState("");
  const currentData = teamMembers;
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData =
  currentData.length > 0
    ? currentData.filter((item) => {
        const matchesSearchQuery =
          (item.fullname
            ? item.fullname.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.country !== undefined
            ? item.country.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.country_code
            ? item.country_code.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase()) ||
          (item.position !== undefined
            ? item.position.toString().toLowerCase()
            : ""
          ).includes(searchQuery.toLowerCase());

        const matchesFilterCountry =
          filterCountry === "" ||
          (item.country !== undefined &&
            item.country.toString().toLowerCase() ===
              filterCountry.toLowerCase());

        return matchesSearchQuery && matchesFilterCountry;
      })
    : [];
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevNext = (direction) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    if (startPage > 1) {
      paginationItems.push(
        <button
          key={1}
          className={`px-4 py-2 mx-1 border rounded ${1 === currentPage ? "bg-black text-white" : "bg-white"}`}
          onClick={() => handleClick(1)}
        >
          1
        </button>
      );

      if (startPage > 2) {
        paginationItems.push(
          <span key="start-ellipsis" className="px-4 py-2 mx-1">
            ...
          </span>
        );
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      paginationItems.push(
        <button
          key={number}
          className={`px-4 py-2 mx-1 border rounded ${number === currentPage ? "bg-black text-white" : "bg-white"}`}
          onClick={() => handleClick(number)}
        >
          {number}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        paginationItems.push(
          <span key="end-ellipsis" className="px-4 py-2 mx-1">
            ...
          </span>
        );
      }

      paginationItems.push(
        <button
          key={totalPages}
          className={`px-4 py-2 mx-1 border rounded ${totalPages === currentPage ? "bg-blue-500 text-white" : "bg-white"}`}
          onClick={() => handleClick(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return paginationItems;
  };

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"./about-us-banner.png"} />
      <div className="max-w-[1360px] m-auto p-[20px] flex flex-col items-center">
        {/* Section 1 */}
        <div className="mt-16 w-full max-md:mt-0 max-md:max-w-full m-auto">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[27%] max-md:ml-0 max-md:w-full">
              <img
                alt="img"
                loading="lazy"
                src="/avatar-ceo.png"
                className="w-full aspect-[0.85] max-sm:w-[280px] max-sm:aspect-[0.90] m-auto max-sm:mt-12 max-md:w-[300px] max-md:aspect-[0.87] "
              />
            </div>
            <div className="flex flex-col ml-5 w-[73%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col items-start self-stretch justify-center px-5 py-8 max-md:px-2 my-auto text-2xl tracking-tight max-sm:py-4 max-md:mt-4 max-md:max-w-full">
                <div className="self-stretch text-base leading-6 tracking-tighter text-neutral-600 max-md:max-w-full ">
                  <span className="text-3xl font-bold leading-10 tracking-tighter text-blue-900 max-md:text-center">
                    Our Vision for Clients and Professionals
                  </span>
                  <br />
                  <br />
                  <span className="leading-7 text-neutral-600">
                    It is our aim to unite users—whether they are seeking or providing services—on a foundation of integrity, honesty, and authenticity. We aspire to create an environment where every person, regardless of their background, is treated with respect, care, and professionalism. Our mission is to foster genuine, prosperous relationships between all individuals through mutual trust and shared success. We invite you to join us in realising this vision.
                  </span>
                </div>
                <div className="mt-9 font-bold text-blue-900 leading-[120%]">
                  Jeffrey Lawson
                </div>
                <div className="mt-2 font-semibold text-sky-600 leading-[100%]">
                  CEO
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#E3E3E3] w-full mt-16 rounded-md m-auto h-[1px] max-md:mt-9"></div>

        {/* Section 2 */}
        <div className="mt-14 max-md:mt-2 w-full w-full max-md:mt-10 max-md:max-w-full m-auto max-md:px-2">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[46%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col font-bold grow max-md:mt-10 max-md:max-w-full">
                <img
                  alt="img"
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8bd403e55f3a7ef70025029308f823a53804116bd284ebdf31788bf97eef19a?apiKey=3ae96302e69649f5be368f89230b0596&"
                  className="max-w-full aspect-[4] w-[314px]"
                />
                <div className="mt-4 text-3xl leading-9 tracking-tight text-black max-md:max-w-full">
                  Your Global Trust Platform for Everyone
                </div>
                <div className="mt-6 text-2xl text-sky-600 max-md:max-w-full">
                  Why Choose Us?
                </div>
                <div className="mt-2 text-base leading-7 font-normal text-neutral-600 max-md:max-w-full">
                  Full ID Check is a global identity verification platform serving individuals across the world. Using cutting-edge data science and machine learning, we’ve built a network of verified profiles by curating authoritative data sources. Our goal is to give everyone the confidence that only authentic, verified individuals can access services or platforms requiring identity authentication
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[54%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col w-full p-12 rounded-3xl bg-neutral-100 max-md:px-5 max-md:mt-10 max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/15e4a8d42785287fcd915cd180cbc568f988fc1abc28e5f195cbc74d3bd5ed59?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="self-start w-12 shrink-0 aspect-square"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
                      The Mission
                    </div>
                    <div className="mt-2 text-base leading-7 text-neutral-600 max-md:max-w-full">
                      Committed to empowering all individuals with certified identity verification, we place honesty and integrity at the forefront of our values. Our mission is to ensure that everyone, not just professionals, can demonstrate their authenticity in an increasingly digital world.
                    </div>
                  </div>
                </div>
                <div className="flex gap-5 mt-4 max-md:flex-wrap">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/f40f4164dd56632e2cdaa1112bbb30c45e5cea2a2f6e250f0b3c4a1adfdedbdf?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="self-start w-12 shrink-0 aspect-square"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
                      The Vision
                    </div>
                    <div className="mt-2 text-base leading-7 text-neutral-600 max-md:max-w-full">
                      <span className="text-neutral-600">
                        Our vision is to offer everyone, from professionals to the general public worldwide, the opportunity to prove that ‘They Are Who They Say They Are.’ We strive to eliminate the anonymity of digital interactions, ensuring that all users, irrespective of their professional background, are recognised as competent and trustworthy individuals with a strong sense of integrity and accountability.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section-3 */}
        <div className="flex flex-col p-12 mt-10 w-full rounded-3xl border border-sky-500 border-solid max-md:px-5 max-md:max-w-[97%] m-auto">
          <div className="text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
            Why is FullIDCheck Essential?
          </div>
          <div className="mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col self-stretch p-4 grow text-neutral-600 max-md:pl-0 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc6f91873035db27b838f443bab86cd963219c2e99fe74f6e284f76fa2c9c910?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="aspect-[0.8] w-[85px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-sm:mx-auto max-md:text-center">
                    Digital Trust
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    In today’s digital age, verifying identity authenticity is crucial. Full ID Check helps all users—clients, individuals, and professionals—build confidence in their engagements, fostering secure and trustworthy interactions.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col p-4 text-neutral-600 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fb55ba0cf96e0e2916e25fd9ae80793a27cee655f9240bf8fa03de6cbc83837?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[1.03] w-[109px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-md:text-center max-sm:mx-auto">
                    Combatting Fraud
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    Fraudulent activities damage trust across the globe. Our platform addresses these issues by protecting both clients and all verified users from fraudulent behaviours, ensuring safer interactions.
                  </div>
                </div>
              </div>
              <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col p-4 text-neutral-600 max-md:mt-4">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/2cfea7ae03c79722e8875308d8eac44666ab8051dcc82645382edb019342499a?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[1.03] w-[109px] max-md:mx-auto max-md:w-[70px] max-sm:mx-auto"
                  />
                  <div className="mt-4 text-2xl font-bold leading-9 tracking-tight max-sm:text-center max-md:text-center">
                    Boosting Credibility
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:text-sm max-sm:text-base max-sm:text-center">
                    With Full ID Check’s worldwide registration, anyone can build trust and credibility. Verified credentials allow users to connect confidently with others, whether for personal or professional reasons, fostering secure relationships and enhancing opportunities.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-5 */}
        <div className="flex flex-col py-12 pr-12 pl-6 mt-10 w-full rounded-3xl bg-neutral-100 w-full max-md:px-5  max-md:mx-1 max-md:max-w-[97%] m-auto">
          <div className="text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-full">
            Key Features
          </div>
          <div className="p-4 mt-4 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
              <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow text-neutral-600 max-md:mt-10 max-md:max-w-full">
                  <img
                    alt="img"
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/222e996e184a9e2abcdf2db33f55c87e845f6417ef091b70573f7e0777ac1adb?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="max-w-full aspect-[3.33] w-[247px]"
                  />
                  <div className="mt-2 text-2xl font-bold leading-9 tracking-tight max-md:max-w-full">
                    AML & eIDAS Compliance
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:max-w-full">
                    We house a database of certified professionals compliant
                    with Anti-Money Laundering (AML) and Electronic
                    Identification, Authentication, and Trust Services (eIDAS)
                    protocols.
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-6/12 ml-5 max-md:ml-0 max-md:w-full">
                <div className="flex flex-col text-neutral-600 max-md:mt-10 max-md:max-w-full">
                  <div className="flex items-start gap-2 text-sm font-medium tracking-tight pt-7 text-sky-600 max-md:flex-wrap">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4a730471a69379d6f705892538662343793aa7ecfdf5adc2aec12d8b30bbb06?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="aspect-[4.55] w-[238px]"
                    />
                    <div className="mt-2.5">
                      The key to finding
                      <br />
                      professionals
                    </div>
                  </div>
                  <div className="mt-2 text-2xl font-bold leading-8 tracking-tight max-md:max-w-full">
                    Connecting People and Professionals
                  </div>
                  <div className="mt-2 text-base leading-7 tracking-tight max-md:max-w-full">
                    Clients and individuals can quickly find and connect with verified users, creating seamless, secure, and trustworthy interactions for any type of service or collaboration.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Section-6 */}
        {/* <div className="flex gap-5 px-5 mt-16 w-full leading-[121%] max-w-[1200px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
          <div className="flex-1 my-auto text-2xl font-semibold text-right text-neutral-600 max-md:max-w-full max-sm:text-2xl max-sm:leading-6">
            Affirm Your Identity with Confidence
          </div>
          <div className="flex-1 text-4xl font-bold text-sky-600 max-md:max-w-full max-md:leading-10 max-sm:text-2xl max-sm:leading-8 max-sm:text-center">
            'I AM WHO I SAY I AM.'{" "}
          </div>
        </div> */}
        <div className="w-full max-lg:w-[100%] m-auto justify-between px-12 py-8 mt-16 rounded-2xl max-w-[1360px] max-md:px-5 max-md:mt-10 max-md:max-w-[97%] bg-gradient-to-br from-[#28b9bb] to-[#1c3775]  max-md:ml-1  max-md:mr-1">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col w-[79%] max-md:ml-0 max-md:w-full">
              <div className="text-3xl font-semibold leading-9 tracking-tight text-white max-md:mt-10 max-md:max-w-full">
                Are you interested in a management position?
              </div>
              <span className="text-sm leading-6 text-white">
                Situations vacant for country managers, check and see if your
                country that you live in is available
              </span>
            </div>
            <div className="flex flex-col ml-5 w-[21%] max-md:ml-0 max-md:w-full max-lg:w-[30%]">
              <button
                onClick={() => navigate("/apply-country-manager")}
                className="max-lg:text-[18px] max-lg:px-0 justify-center self-stretch px-9 py-3 my-auto w-full text-xl font-semibold text-blue-900 bg-zinc-100 rounded-[93.75px] max-md:px-5 max-md:mt-10 text-center "
              >
                Apply
              </button>
            </div>
          </div>
        </div>
        <div className="w-full mt-10 text-3xl font-bold leading-9 tracking-tight text-blue-900 max-md:max-w-[98%]">
          Meet The Senior Country Managers and Country Managers
        </div>
        <div className="w-full max-md:max-w-full">
          <div className="flex items-center justify-start mt-5 search max-md:flex-col">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input my-auto border border-neutral-400 w-[30%] rounded-lg px-2 py-2 max-md:w-full max-md:mb-2"
              placeholder="Search name, position ,etc..."
            />
            <div className="relative w-[20%] max-md:w-full ml-4 max-md:ml-0">
              <select
                className={`w-full py-2 border border-neutral-400 rounded-lg px-3 pr-10 ${filterCountry === "" ? "text-neutral-400" : "text-black"
                  }`}
                id="country"
                value={filterCountry}
                onChange={(e) => setFilterCountry(e.target.value)}
              >
                <option disabled value="">
                  Country
                </option>
                {countries.map((country) => (
                  <option key={country.isoCode} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </select>

              {filterCountry && (
                <button
                  onClick={() => setFilterCountry("")}
                  className="absolute top-1/2 right-7 transform -translate-y-1/2 text-gray-400 hover:text-black"
                  aria-label="Clear selection"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="#6c6c6c"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 9.293l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414L10 8.586z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 max-[376px]:grid-cols-1 justify-between max-w-full m-auto mt-10">
            {!loading ? (
              currentItems.length > 0 ? (
                currentItems.map((item, index) => (
                  <div key={index} className="flex flex-col items-center justify-center p-4 rounded-lg max-md:p-0 max-md:mb-6">
                    <img
                      src={item.user?.profile_photo_path ?? '/fullidcheck-default-avatar-member.png'}
                      alt={item.first_name}
                      className="w-[150px] aspect-square border border-gray-300 rounded-full h-auto mb-2 max-md:w-[80px]"
                    />
                    <div className="text-center mt-4 font-semibold text-[24px] text-[#1C3775] max-md:text-[18px]">
                      {item.fullname}
                    </div>
                    <div className="flex items-center mt-2 gap-1">
                      {Array.isArray(item.country_code) ? (
                        item.country_code.map((code, index) => (
                          <React.Fragment key={code}>
                            <img
                              src={`https://flagsapi.com/${code}/flat/64.png`}
                              alt={`${item.country[index]} flag`}
                              className="w-[24px]"
                            />
                            <span className="text-[18px] max-md:text-[14px]">
                              {item.country[index] === 'United States' ? 'USA' : item.country[index]}
                              {index < item.country_code.length - 1 && " | "}
                            </span>
                          </React.Fragment>
                        ))
                      ) : (
                        <>
                          {item.country_code && (
                            <img
                              src={`https://flagsapi.com/${item.country_code}/flat/64.png`}
                              alt={`${item.country} flag`}
                              className="w-[24px]"
                            />
                          )}
                          <span className="text-[18px] max-md:text-[14px]">
                            {item.country === 'United States' ? 'USA' : item.country}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500 col-span-full mt-4">
                  No results found
                </div>
              )
            ):(
              <div className="text-center text-gray-500 col-span-full mt-4">
                <Loading />
              </div>
            )}
            
          </div>

          <div className="flex justify-end px-5 mt-4">
            <button
              className={`px-3 py-1 mx-1 border rounded ${currentPage === 1 ? "bg-gray-300 cursor-not-allowed" : "bg-white"}`}
              onClick={() => handlePrevNext("prev")}
              disabled={currentPage === 1}
            >
              <MdKeyboardArrowLeft size={22} />
            </button>
            {renderPaginationItems()}
            <button
              className={`px-3 py-1 mx-1 border rounded ${currentPage === totalPages ? "bg-gray-300 cursor-not-allowed" : "bg-white"}`}
              onClick={() => handlePrevNext("next")}
              disabled={currentPage === totalPages}
            >
              <MdKeyboardArrowRight size={22} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
