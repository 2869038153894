import React from "react";
import SidePanel from "../../component/SidePanel";
import TopBanner from "../../component/TopBanner";

function WhyRegister() {
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9bcaef71c39d737e555e92b212ab701a98ef2fb4d87684c7020d0512325a8368?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/Why-Register-Banner.png"} />
      <div className="flex-column flex mt-16 w-[93%] m-auto max-md:flex-col max-w-[1366px] pb-16 max-md:mt-5">
        <div className="section-1 w-[75%]  max-md:w-full">
          <div className="flex flex-col grow self-stretch px-5 max-md:mt-10 max-md:max-w-full max-md:px-3">
            <div className="text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
              At Full ID Check, we want to ensure that everyone—whether you're a professional, freelancer, or individual
            </div>
            <div className="mt-2 text-base tracking-normal leading-7 text-neutral-600 max-md:max-w-full">
              can prove their identity, traceability, and authenticity. By registering, you'll submit the necessary legal documents, participate in a live interview, and verify your identity using our secure facial recognition system.
              Everyone registered on FullIDCheck can confidently say, <b>“I AM WHO I SAY I AM”.</b>
            </div>
            <div className="mt-12 text-3xl font-bold tracking-tight leading-9 text-neutral-600 max-md:mt-10 max-md:max-w-full">
              Why Register at FullIDCheck?
            </div>
            <div className="justify-center mt-9 max-md:max-w-full">
              {/* FIX----------------- */}
              <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-lg:gap-2  max-xl:gap-0">
                <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/78262274a871264389ae7395165562c0ffd60f9d437df0b5489a33449a9bf822?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="self-center aspect-[1.72] w-[97px]"
                    />
                    <div className="mt-2">Reputation Boost</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/f1b4c43aa838ec8776da8e18e3dc64cd9730ac52ebae041a9b17139e6b3a2c07?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="self-center aspect-[0.91] w-[51px]"
                    />
                    <div className="mt-2">Increased Trust</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold  font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/21ff9619563b0f64f19c637a44ecbf831dcf91fa778d5800e6929575c59013ee?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="self-center aspect-[1.61] w-[91px]"
                    />
                    <div className="mt-2">Unlimited Opportunities</div>
                  </div>
                </div>
                <div className="flex flex-col ml-5 max-lg:ml-0 w-3/12 max-md:ml-0 max-md:w-full">
                  <div className="flex flex-col grow self-stretch px-4 py-6 w-full text-2xl max-lg:text-lg max-lg:font-bold  font-semibold tracking-tight leading-6 text-center bg-white rounded-2xl border border-solid border-zinc-300 text-neutral-800 max-md:mt-4">
                    <img
                      alt="img"
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3f320f650f97b30a8c77fa869b6ce891a1147d56ed2590b6996b58ff94732f3?apiKey=3ae96302e69649f5be368f89230b0596&"
                      className="self-center aspect-[1.49] w-[95px]"
                    />
                    <div className="mt-4">Community Building</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:mt-10 max-md:max-w-full">
              What We Offer
            </div>
            <div className="flex flex-col gap-3 mt-6 text-neutral-600 max-md:max-w-full">
              <div className="flex flex-col justify-center px-5 py-6 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB] max-md:p-5">
                <div className="flex gap-5 pl-5 max-md:pl-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col justify-start">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/b5ef8a64d428fd1fe9ed6bd7961d05771ff4d199582d268484a6e8db30ad43df?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start max-w-full aspect-[1.56] w-[100px]"
                  />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Registered Badge
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-6 max-md:max-w-full">
                      Upon registration, your badge will link directly to your profile, allowing anyone—clients, colleagues, or peers—to verify you as a legitimate, trusted individual. This badge can be added to emails, social media, and personal websites to build credibility. With just one click, people can view your profile, making it easier to connect and build trusted relationships, whether for professional or personal reasons.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center px-5 py-6 mt-4 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB] max-md:p-5">
                <div className="flex gap-5 pl-5 max-md:pl-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/458a14c45d77093fe6e068218507d7456cd218bb17d379e58834e8d1b3e46e7c?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start aspect-[0.91] w-[58px]"
                  />
                  <div className="flex flex-col flex-1 min-[768px]:ml-8 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Personalized Profile
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-6 max-md:max-w-full">
                      Whether you’re a professional or an individual, your profile on Full ID Check is a powerful tool. For professionals, it serves as a detailed portfolio showcasing your skills and experience. For individuals, it confirms your identity, providing peace of mind to anyone you interact with. By completing your profile with accurate information, you make it easier for others to trust you and engage with you.
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify- px-5 py-6 mt-4 bg-white rounded-2xl max-md:max-w-full border border-[#E5E7EB]  max-md:p-5">
                <div className="flex gap-5 pl-5 max-md:pl-0 max-md:pr-0 max-md:flex-wrap max-md:flex-col">
                  <img
                    alt="img"
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/d03aceb818fd99094dfe7e81182be62be36d36c24f462881ff57a8aecdb058f6?apiKey=3ae96302e69649f5be368f89230b0596&"
                    className="shrink-0 self-start w-16 aspect-square"
                  />
                  <div className="flex flex-col  min-[768px]:ml-6 flex-1 max-md:max-w-full">
                    <div className="text-2xl font-bold tracking-tight leading-6 max-md:max-w-full">
                      Our Forums
                    </div>
                    <div className="mt-2 text-base tracking-tight leading-7 max-md:max-w-full">
                      Our forums are a trusted space for all registered users—professionals, freelancers, or individuals. Here, you can confidently connect with others knowing everyone has been thoroughly verified. The forums are a place to find local experts, share knowledge, and collaborate on projects. Whether you're looking to hire, offer your skills, or simply engage with a community, our forums provide a secure, scam-free environment for networking and growth.
                      <br />
                      <br />
                      What you can do in the forums: <br />
                      <ul className="list-disc ml-5">
                        <li>Network with Verified Users: Connect with other registered individuals, professionals, or freelancers for potential opportunities, knowing each person's identity is verified.</li>
                        <br /><li>Seek Local Experts: Find people in your area or field to expand your network or offer services.</li>
                        <br /><li>Exchange Knowledge: Share expertise or ask for assistance in a supportive community committed to professional growth and collaboration.</li>
                      </ul>
                      <br />
                      We are committed to maintaining a respectful, constructive space for all members, where people can elevate each other personally and professionally.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SidePanel image={imgUrl} />
      </div>
    </div>
  );
}

export default WhyRegister;
