import React from "react";

const SubmitTicket = ({ isVisible, onClose, onSubmit, subject, setSubject, body, setBody, errors }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[800px]"> {/* Increased width */}
        <h2 className="text-lg font-bold tracking-tight leading-5 text-blue-900">
          Submit a Ticket
        </h2>
        <hr className="border-b border-gray-200 my-4" />

        <div className="mt-4">
          <label className="block">Subject</label>
          <input
            type="text"
            className="px-4 py-2 mt-2 w-full rounded-lg border border-solid border-neutral-300"
            placeholder="Ticket Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          {errors.subject && <small className="text-red-500">{errors.subject}</small>}
        </div>
        <div className="mt-4">
          <label className="block">Message</label>
          <textarea
            className="px-4 py-2 mt-2 w-full rounded-lg border border-solid border-neutral-300 h-[180px]"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
          {errors.message && <small className="text-red-500">{errors.message}</small>}
        </div>
        <div className="flex justify-end mt-4">
          <button className="bg-gray-300 px-4 py-2 mr-2" onClick={onClose}>Cancel</button>
          <button className="bg-blue-900 text-white px-4 py-2" onClick={onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );

};

export default SubmitTicket;
