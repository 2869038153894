import React from 'react';
import ForumHeader from './components/CategoryHeader';
import BoardSection from './components/BoardSection';
import TopicSection from './components/CategoryTopic';
import SearchBar from '../../components/SearchBar';

const CategoryViewed = () => {
  const boards = [
    {
      title: "Dev",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
      posts: 14756,
      topics: 9824,
      latestPost: {
        author: "Monchii",
        topic: "Industry News",
        date: "Thu, Oct 3, 2024 9:27 AM"
      }
    },
    {
      title: "Client",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
      posts: 14756,
      topics: 9824,
      latestPost: {
        author: "Monchii",
        topic: "Industry News",
        date: "Thu, Oct 3, 2024 9:27 AM"
      }
    },
    {
      title: "Professional Work",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec non sapien id purus accumsan interdum vitae at felis. In interdum ex augue, sit amet vulputate quam...",
      posts: 14756,
      topics: 9824,
      latestPost: {
        author: "Monchii",
        topic: "Industry News",
        date: "Thu, Oct 3, 2024 9:27 AM"
      }
    }
  ];

  const topics = Array(10).fill({
    subject: "Career Guidance",
    startedBy: "achow_101",
    replies: 89,
    views: 5047,
    lastPost: {
      date: "Fri, Sep 27, 2024, 4:24 AM",
      author: "Elon Mo To"
    }
  });

  return (
    <main className="flex flex-col flex-1 shrink justify-center px-8 py-6 w-full basis-0 min-w-[240px] max-md:px-0 max-md:max-w-full">
      <div className="flex flex-col flex-1 w-full max-md:max-w-full">
        <ForumHeader />
        <SearchBar />
        <BoardSection title="Chill Boards" boards={boards} />
        <TopicSection topics={topics} />
      </div>
    </main>
  );
};

export default CategoryViewed;