import React, { useEffect } from "react";
import ReadyRecognized from "../../component/ReadyRecognized";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  updateReferralClicks,
  updateReferralShares,
} from "../../feature/home/homeSlice";
import queryString from "query-string";
import TopBannerHomepage from "../../component/TopBannerHomepage";
import { BsArrowRightShort } from "react-icons/bs";

function Home() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const { s, ref, t } = queryParams;

    if (s && ref && t) {
      if (t === "c") {
        dispatch(updateReferralClicks({ ref_code: ref }));
      } else if (t === "s") {
        dispatch(updateReferralShares({ ref_code: ref }));
      }

      // Remove the query parameters from the URL
      const updatedQueryParams = { ...queryParams };
      delete updatedQueryParams.s;
      delete updatedQueryParams.ref;
      delete updatedQueryParams.t;
      const newQueryString = queryString.stringify(updatedQueryParams);
      const newUrl = `${window.location.pathname}${newQueryString ? `?${newQueryString}` : ""
        }`;
      navigate(newUrl, { replace: true });
    }
  }, [dispatch, navigate]);

  return (
    <div className="bg-[#F9FAFB] pt-4">
      <div className="max-[426px]:hidden" >
        <TopBannerHomepage bannerImg={"/HomepageBanner.png"} />
      </div>
      <div className="hidden max-[426px]:block" >
        <TopBannerHomepage bannerImg={"/HomepageMobileBG.png"} />
      </div>
      <div className="max-w-[1366px] m-auto px-[20px] pb-16 max-md:px-0">
        <div
          className="flex items-center justify-center min-h-screen"
          style={{
            position: "relative",
            overflow: "hidden",
            flexDirection: "column",
          }}
        >
          <div className="flex flex-col mx-auto mt-14 max-w-full max-sm:mt-[50px]">
            <div className="w-full text-5xl font-bold tracking-tighter text-center text-blue-900 leading-[67.2px] max-md:max-w-full max-md:text-4xl max-sm:px-3 max-sm:mt-6 max-sm:text-4xl">
              You Are Who You Say You Are
            </div>
            <div className="w-full mt-4 text-2xl leading-9 text-center text-neutral-600 max-md:px-5 max-md:max-w-full max-md:text-2xl max-sm:px-4 max-sm:text-lg">
              Bringing Credibility, Integrity, and Protection to Industry Professionals, Individuals and Clients Worldwide
            </div>
            <div className="flex self-center justify-between gap-5 px-5 mt-8 text-xl max-md:flex-col max-md:w-full">
              <div
                className="my-auto px-6 py-2.5 text-blue-900 bg-[#E0E7FF] text-center rounded-full cursor-pointer max-md:text-base max-sm:text-sm max-md:w-full"
                onClick={() => {
                  navigate("/benefits");
                }}
              >
                Learn More
              </div>
              <div
                className="flex items-center gap-1 justify-center px-6 py-2.5 text-white bg-blue-900 rounded-full max-md:px-5 max-md:text-base max-sm:text-sm cursor-pointer max-md:w-full"
                onClick={() => {
                  navigate("/register");
                }}
              >
                Get Verified
                <BsArrowRightShort size={25} />
              </div>
            </div>
          </div>
          <div
          >
            <img
              loading="lazy"
              alt="banner"
              src="/Hero Images.png"
              className="object-contain w-full mt-12 aspect-[2.86] max-md:max-w-full max-md:mt-14 z-[1]"
            />
          </div>

          <div className="w-full flex flex-col items-center">
            <section className="flex flex-col justify-center items-center py-20 w-full max-md:px-5 max-md:py-16">
              <div className="flex flex-col justify-center items-center max-md:max-w-full">
                <div className="text-sm font-semibold text-sky-600">BUILD CREDIBILITY</div>
                <h2 className="text-3xl max-md:text-[24px] font-bold text-gray-800 max-md:max-w-full text-center">Build Confidence and Trust Through Verification</h2>
              </div>
              <p className="self-stretch mt-10 text-base leading-6 text-center text-gray-500 max-md:max-w-full">
                In today's constantly changing digital landscape, differentiating yourself from others, building confidence in your abilities, and demonstrating your talents in compelling ways has become more important than ever.
                <br /><br />
                We are excited to introduce you to FullIDCheck, a secure and verified platform that empowers anyone to establish trust within their community and beyond. Whether you're a professional seeking to demonstrate your expertise or an individual aiming to verify your identity, FullIDCheck gives you the platform you deserve to shine and build lasting, trustworthy relationships.
              </p>
            </section>
          </div>

          <div className="flex flex-col justify-center mt-4 w-full max-md:px-5 max-md:mt-4 max-md:max-w-full">
            <div className="flex flex-wrap gap-6 w-full max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink p-[2px] border-2 border-cyan-600 rounded-lg basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col flex-1 justify-center p-8 my-auto bg-white rounded-lg">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/reputation.png"
                      className="object-contain rounded-sm aspect-[1.71] w-[82px]"
                    />
                    <div className="mt-4 text-xl font-bold text-gray-700 max-md:max-w-full">
                      Reputation Boost
                    </div>
                    <div className="mt-4 text-sm leading-5 text-gray-500 max-md:max-w-full">
                      &quot;Being verified on FullIDCheck boosts your reputation. Our
                      thorough process ensures people can trust your qualifications
                      and experience, giving you credibility that stands out.&quot;
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink p-[2px] border-2 border-cyan-600 rounded-lg basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col flex-1 justify-center p-8 my-auto bg-white rounded-lg">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/increased.png"
                      className="object-contain w-12 aspect-square"
                    />
                    <div className="mt-4 text-xl font-bold text-gray-700 max-md:max-w-full">
                      Increased Trust
                    </div>
                    <div className="mt-4 text-sm leading-5 text-gray-500 max-md:max-w-full">
                      &quot;Trust matters. By joining our verified database, you show
                      clients you're reliable and credible, giving you a major edge in
                      securing more opportunities.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-6 mt-6 w-full max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink p-[2px] border-2 border-cyan-600 rounded-lg basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col flex-1 justify-center p-8 my-auto bg-white rounded-lg">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/unlimited.png"
                      className="object-contain w-12 aspect-square"
                    />
                    <div className="mt-4 text-xl font-bold text-gray-700 max-md:max-w-full">
                      Unlimited Opportunities
                    </div>
                    <div className="mt-4 text-sm leading-5 text-gray-500 max-md:max-w-full">
                      &quot;Get verified and open the door to new projects and
                      collaborations. FullIDCheck connects you with clients who value
                      trust, helping you advance your career.&quot;
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1 shrink p-[2px] border-2 border-cyan-600 rounded-lg basis-0 min-w-[240px] max-md:max-w-full">
                <div className="flex flex-col flex-1 justify-center p-8 my-auto bg-white rounded-lg">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <img
                      loading="lazy"
                      src="/group.png"
                      className="object-contain aspect-[1.48] w-[71px]"
                    />
                    <div className="mt-4 text-xl font-bold text-gray-700 max-md:max-w-full">
                      Community Building
                    </div>
                    <div className="mt-4 text-sm leading-5 text-gray-500 max-md:max-w-full">
                      Join a community of professionals where you can share ideas,
                      collaborate, and grow your career through trusted relationships
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="w-full text-center mt-10 text-gray-500">"Register to expand your client base globally and build trust with potential clients through verified credentials."</p>
          </div>

          <div className="flex flex-col items-start mt-20 w-full max-md:max-w-full">
            <ReadyRecognized />
          </div>

          <div className="flex overflow-hidden flex-col py-20 max-md:px-5">
            <div className="flex flex-col justify-center items-center self-center">
              <div className="text-sm font-semibold text-sky-600">
                UNDERSTANDING THE PROCESS
              </div>
              <div className="text-3xl font-bold text-gray-800">
                Register in 3 Easy Steps
              </div>
            </div>
            <div className="flex flex-wrap gap-10 items-start mt-12 w-full max-md:mt-10 max-md:max-w-full">
              <div className="flex overflow-hidden flex-col flex-1 min-h-[159px] h-full shrink justify-center pt-6 bg-blue-900 rounded-lg basis-0 min-w-[240px]">
                <div className="flex gap-3 items-center px-6 pb-3 w-full text-base font-bold text-white whitespace-nowrap max-md:px-5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/0578d204e552bfe35332dd31103b6f7cd5f05c0a859194bc7d3b15b3c89ccd34?placeholderIfAbsent=true&apiKey=5b0bec533dbb4cdda7a4d72a707595ca"
                    className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                  />
                  <div className="self-stretch my-auto">Register</div>
                </div>
                <div className="flex-1 shrink gap-2.5 self-stretch px-6 pt-3 pb-6 w-full text-sm font-medium leading-5 text-indigo-200 bg-blue-950 max-md:px-5">
                  Create a new account by filling out the registration form and
                  submitting your personal details to get started with our services.
                </div>
              </div>
              <div className="flex overflow-hidden flex-col flex-1 min-h-[159px] h-full shrink justify-center pt-6 bg-blue-900 rounded-lg basis-0 min-w-[240px]">
                <div className="flex gap-3 items-center px-6 pb-3 w-full text-base font-bold text-white whitespace-nowrap max-md:px-5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e48c77fd2a738760171aa3cc99c54d7723380035d9f7a32a0796e200e434f71f?placeholderIfAbsent=true&apiKey=5b0bec533dbb4cdda7a4d72a707595ca"
                    className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                  />
                  <div className="self-stretch my-auto">Authenticate</div>
                </div>
                <div className="flex-1 shrink gap-2.5 self-stretch px-6 pt-3 pb-6 w-full text-sm font-medium leading-5 text-indigo-200 bg-blue-950 max-md:px-5">
                  Use facial recognition and upload your passport, driver's license,
                  or country ID for secure identity confirmation.
                </div>
              </div>
              <div className="flex overflow-hidden flex-col flex-1 min-h-[159px] shrink justify-center pt-6 bg-blue-900 rounded-lg basis-0 min-w-[240px]">
                <div className="flex gap-3 items-center px-6 pb-3 w-full text-base font-bold text-white max-md:px-5">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/0fcda5861712f8078158de4900c79d3b25c3f446815c025754a00dbe479130a5?placeholderIfAbsent=true&apiKey=5b0bec533dbb4cdda7a4d72a707595ca"
                    className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                  />
                  <div className="self-stretch my-auto">Get Verified</div>
                </div>
                <div className="flex-1 shrink gap-2.5 self-stretch px-6 pt-3 pb-6 w-full text-sm font-medium leading-5 text-indigo-200 bg-blue-950 max-md:px-5">
                  Receive your FullIDCheck badge number to display in emails,
                  websites, start building your profile.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start mt-16 w-full max-md:mt-4 max-md:max-w-full">
            <div className="max-md:max-w-full max-md:px-5">
              <div className="flex justify-between max-md:flex-col max-md:gap-0">
                <div className="flex flex-col w-[42%] max-md:w-full">
                  <img
                    alt="img"
                    loading="lazy"
                    src="./professions.png"
                    className="grow w-full aspect-[1.75] max-md:mt-0 max-md:max-w-full"
                  />
                </div>
                <div className="flex flex-col self-stretch my-auto w-[53%] max-md:w-full">
                  <div className="flex flex-col justify-center max-md:max-w-full max-md:mt-6">
                    <div className="text-sm font-semibold text-sky-600">STEP INTO POSSIBILITY</div>
                    <h2 className="text-3xl font-bold text-gray-800 max-md:max-w-full">Unlocking Opportunities and Trust</h2>
                  </div>
                  <p className="mt-8 text-sm leading-5 text-gray-600 max-md:max-w-full">
                    <span className="font-bold text-gray-700">FullIDCheck</span> is open to everyone, offering a secure and verified platform that promotes trust across all types of professional and personal networks. Whether you're a professional looking to showcase your expertise or simply want to verify your identity for peace of mind, <span className="font-bold text-gray-700">FullIDCheck</span> is here for you.
                    <br /><br />
                    By registering, you can gain the advantage of expanding your reach to a global audience. Our verification process substantiates your identity and, for professionals, your skills and experience, helping to build trust with potential clients, employers, or anyone you interact with. <span className="font-bold text-gray-700">FullIDCheck</span> sets you apart as a credible, verified individual, opening doors to stronger relationships and greater opportunities in your personal and professional life.
                  </p>
                  <button onClick={() => { navigate("/register"); window.scrollTo(0, 0); }} className="flex gap-1 justify-center items-center self-start py-2.5 pr-4 pl-5 mt-8 text-base font-medium text-gray-50 bg-blue-900 rounded-[99px] max-md:w-full">
                    <span className="self-stretch my-auto">Sign Up Now</span>
                    <BsArrowRightShort size={20} className="object-contain shrink-0 self-stretch my-auto aspect-square" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
