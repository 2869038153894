import React from "react";
import SidePanel from "../../component/SidePanel";
import TopBanner from "../../component/TopBanner";

function BenefitPage() {
  const imgUrl =
    "https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f50bc7574a4f81d8afcd8c3255dc5cd925cb0c7e5a617ad185108353d9971d17?apiKey=3ae96302e69649f5be368f89230b0596&";

  return (
    <div className="bg-[#F9FAFB] mt-4 max-md:mt-0">
      <TopBanner bannerImg={"/benefit-banner.png"} />
      <div className="justify-end self-center mt-16 pb-[200px] w-full m-auto max-w-[1366px] max-md:mt-5 max-md:max-w-full">
        <div className="flex gap-0 max-md:flex-col max-md:gap-0 max-md:px-4">
          <div className="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow self-stretch px-5 max-md:px-2.5 max-md:mt-10 max-md:max-w-full">
              <div className="text-3xl font-bold tracking-tight leading-9 text-blue-900 max-md:max-w-full">
                We aim to create a supportive environment where individuals, freelancers, and professionals alike can confidently connect, share knowledge, and seek guidance
              </div>
              <div className="mt-2 text-lg tracking-tight leading-7 text-zinc-600 max-md:max-w-full">
                Our forums are designed to give everyone a sense of community and belonging, providing a space to ask questions and grow together.
              </div>
              <div className="mt-10 max-md:max-w-full">
                <div className="flex gap-0 max-md:flex-col max-md:gap-0">
                  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col grow max-md:mt-6">
                      <div className="flex flex-col justify-center w-full">
                        <div className="flex gap-2 max">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/337ca416567271e6fd6a012a7085610c8fc427ee8b84fee94282da4ad071b35f?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-8 text-neutral-600">
                              A One-Time Investment for Lifetime Opportunities
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                              For just $195.00 USD, you can secure a lifetime membership at Full ID Check. This one-time fee guarantees that your profile is verified and accessible to potential clients, employers, or anyone looking to confirm your identity. No recurring annual charges—unless you update your profile details, which only requires a small annual fee thereafter.<br />Get Verified Today and Connect with Confidence!
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-8 w-full">
                        <div className="flex gap-2">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9910bffc96cdab3198bec70010fe4124ed84d6da134623593ccc5ee05ed05762?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-8 text-neutral-600">
                              Competitions and Challenges
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                              Engage in our regular competitions and challenges to showcase your talents and skills. With exciting prizes up for grabs, these events give you the opportunity not only to prove your abilities but also to be recognized by your peers and community.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center items-center mt-8 w-full min-md:aspect-square">
                        <div className="flex gap-2">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e99b6e8fc72c63c574b23b008ea6d706170dadc8e182ff386c5d1d1bb4a5e798?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-8 text-neutral-600">
                              Monthly Bradge Draws
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                              Each month, our members automatically enter into a thrilling badge draw, with a jackpot that continues to grow until a lucky winner claims it. As a verified member, you’re eligible for this exciting opportunity, offering more than just verification—it offers rewards too!
                              <br />
                              <br />
                              Our referral program adds even more excitement. For every new applicant you refer who joins and gets verified, you’ll receive a $10.00 bonus added to your account. This program helps us build a strong, supportive community and rewards you for helping us grow
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
                    <div className="flex flex-col max-md:mt-6">
                      <div className="flex flex-col justify-center w-full">
                        <div className="flex gap-2">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/737fbd63f487b64345c97a27d63ae2c66f5072f439b96d7de6785f14d34c082d?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-8 text-neutral-600">
                              Easily Connect with Local Clients
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                            By joining FullIDCheck, you’ll gain access to local clients through The Register at register.dev. Once registered, your profile becomes part of a trusted directory where potential clients, employers, or partners can find you based on your skills and location. This connection increases your visibility and helps you grow your business or network by linking you directly with those who need your services.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-8 w-full">
                        <div className="flex gap-2">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/18232f98c3a98f9847538f9e0af94e8a19be164c3240532cc3a4f9ef31885880?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-7 text-neutral-600">
                              Maximize Your Visibility
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                            Complete your profile to ensure clients and partners can easily find and trust you. By displaying your verified credentials on Register.dev, you establish your authenticity and make it easier for others to confidently choose to work with you.<br/>Join now to start building trust within your local community and beyond!
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-8 w-full">
                        <div className="flex gap-2">
                          <img
                            alt="img"
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/c805b6bb9548328d4db01a342ab68e3e700297d44c9bb7d109c387e48e6af098?apiKey=3ae96302e69649f5be368f89230b0596&"
                            className="shrink-0 self-start w-6 aspect-square mt-1"
                          />
                          <div className="flex flex-col flex-1">
                            <div className="text-2xl font-bold tracking-tight leading-7 text-neutral-600">
                              Recognition and Badges
                            </div>
                            <div className="mt-2 text-base tracking-tight leading-7 text-zinc-600">
                            We value every contribution our members make. That’s why we’ve developed a badges and levels system to recognise your activity, experience, and engagement within the community. The more you participate—whether it’s in forums, discussions, or product development polls—the more you’ll be acknowledged with badges that show your standing within the community. Your input helps guide the future of our website and services, ensuring that every member benefits from collaborative progress.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SidePanel image={imgUrl} />
        </div>
      </div>
    </div>
  );
}

export default BenefitPage;
