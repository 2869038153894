import React from "react";
import useRedirectToCoinProfilePage from "../hooks/useRedirectToCoinProfilePage";
import { useNavigate } from "react-router-dom";

function FileComplaintMain() {
  const navigate = useNavigate();
  const { coin, redirect } = useRedirectToCoinProfilePage();
  if (redirect) {
    return redirect;
  }

  return (
    <div className="w-full mt-10 ">
      {coin && (
        <>
          <div className="self-stretch tracking-tight leading-[110%] max-md:max-w-full">
            <span className="font-bold">{coin.ticker} Coin</span> &gt; File a
            complaint
          </div>

          <div className="flex flex-col justify-center max-w-[50%] mx-auto max-md:max-w-[90%]">
            <div className=" text-black90 text-sm14 mt-4 font-semibold">
              Reason for resignation
            </div>
            <textarea
              name="text-area-resign"
              id="resign-msg"
              className="border-2 mt-1 rounded-xl min-h-[150px] p-3"
            ></textarea>

            <div className="flex space-x-3 justify-center text-white mt-4">
              <button
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
                className="text-sm14 bg-devRegBlue px-5 py-1.5 rounded-2xl"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  navigate("/dashboard/coin-profile");
                  window.scrollTo(0, 0);
                }}
                className="text-sm14 bg-devRegDarkBlue px-5 py-1.5 rounded-2xl"
              >
                Sent To Admin
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FileComplaintMain;
