import { useState } from "react";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../contexts/authContext";

const useUploadDocument = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openFacialRecognition, setOpenFacialRecognition] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxChecked2, setIsCheckboxChecked2] = useState(false);
  //   const TOKEN = localStorage.getItem("token");
  //   const userData = JSON.parse(localStorage.getItem("user"));
  //   const id = userData.id;
  //   const { login } = useAuth();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [responseMessage, setResponseMessage] = useState({
    id: "",
    utils: "",
    selfie: "",
  });
  const [fileListID, setFileListID] = useState([]);

  const [fileListUtilityBill, setFileListUtilityBill] = useState([]);
  const [fileListSelfie, setFileListSelfie] = useState([]);
  const [qrCodeDataUser, setQrCodeDataUser] = useState("");
  const extractIdsIfNotEmpty = (fileList) => {
    if (fileList.length > 0) {
      return fileList.map((item) => item.id);
    }
    return [];
  };

  const idIds = extractIdsIfNotEmpty(fileListID);

  const utilityBillIds = extractIdsIfNotEmpty(fileListUtilityBill);

  const selfieIds = extractIdsIfNotEmpty(fileListSelfie);

  const allIds = [...idIds, ...utilityBillIds, ...selfieIds];

  const uniqueIds = new Set(allIds);

  console.log(uniqueIds);

  const onFileDrop = async (
    e,
    type,
    fileListID,
    setFileListID,
    fileListUtilityBill,
    setFileListUtilityBill,
    fileListSelfie,
    setFileListSelfie
  ) => {
    const droppedFile = e.target.files[0];

    if (!droppedFile) return;

    // const { name } = droppedFile;
    // const fileNameWithoutExtension = name.split(".").slice(0, -1).join(".");

    // const formData = new FormData();
    // formData.append("name", fileNameWithoutExtension);
    // formData.append("file", droppedFile);
    // formData.append("type", type);

    const setMessage = (message) => {
      switch (type) {
        case "VALID_ID":
          setResponseMessage({ id: message });
          break;
        case "DOCUMENT":
          setResponseMessage({ utils: message });
          break;
        case "SELFIE_PHOTO":
          setResponseMessage({ selfie: message });
          break;
        default:
          break;
      }
    };

    setMessage("Uploading File...");

    // try {
    //   const response = await axios.post(
    //     `https://devreg.co.nz/api/api/user/${id}/file-uploads`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         "X-Requested-With": "XMLHttpRequest",
    //         Authorization: `Bearer ${TOKEN}`,
    //       },
    //     }
    //   );

    //   if (response.status === 200) {
    //     console.log("File uploaded successfully");
    //     const getResponseId = response.data.data.id;
    //     const combineParameter = { file: droppedFile, id: getResponseId };
    //     switch (type) {
    //       case "VALID_ID":
    //         setFileListID([...fileListID, combineParameter]);

    //         break;
    //       case "DOCUMENT":
    //         setFileListUtilityBill([...fileListUtilityBill, combineParameter]);

    //         break;
    //       case "SELFIE_PHOTO":
    //         setFileListSelfie([...fileListSelfie, combineParameter]);

    //         break;
    //       default:
    //         break;
    //     }

    //     setMessage(response.data.message);
    //   }
    // } catch (error) {
    //   console.error("File upload failed:", error);
    //   setMessage(error.response?.data?.message || "An error occurred");
    // }
  };

  const removeSelectedFile = async (
    index,
    type,
    fileListID,
    setFileListID,
    fileListUtilityBill,
    setFileListUtilityBill,
    fileListSelfie,
    setFileListSelfie,
    uploadId
  ) => {
    const setMessage = (message) => {
      switch (type) {
        case "VALID_ID":
          setResponseMessage({ id: message });
          break;
        case "DOCUMENT":
          setResponseMessage({ utils: message });
          break;
        case "SELFIE_PHOTO":
          setResponseMessage({ selfie: message });
          break;
        default:
          break;
      }
    };

    setMessage("Removing File...");
    // try {
    //   const response = await axios.delete(
    //     `https://devreg.co.nz/api/api/user/${id}/file-uploads/${uploadId}`,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //         "X-Requested-With": "XMLHttpRequest",
    //         Authorization: `Bearer ${TOKEN}`,
    //       },
    //     }
    //   );

    //   if (response.status === 200) {
    //     console.log("File Deleted successfully");

    //     const setMessage = (message) => {
    //       switch (type) {
    //         case "VALID_ID":
    //           setResponseMessage({ id: message });
    //           setFileListID(fileListID.filter((_, i) => i !== index));
    //           break;
    //         case "DOCUMENT":
    //           setResponseMessage({ utils: message });
    //           setFileListUtilityBill(
    //             fileListUtilityBill.filter((_, i) => i !== index)
    //           );
    //           break;
    //         case "SELFIE_PHOTO":
    //           setResponseMessage({ selfie: message });
    //           setFileListSelfie(fileListSelfie.filter((_, i) => i !== index));
    //           break;
    //         default:
    //           break;
    //       }
    //     };

    //     setMessage(response.data.message);
    //   }
    // } catch (error) {
    //   console.error("File Delete failed:", error);
    // }
  };

  const onFileIdDrop = async (e) => {
    onFileDrop(
      e,
      "VALID_ID",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const onFileUtilityBillDrop = async (e) => {
    onFileDrop(
      e,
      "DOCUMENT",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const onFileSelfieDrop = async (e) => {
    onFileDrop(
      e,
      "SELFIE_PHOTO",
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie
    );
  };

  const removeSelectedFileID = async (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };

  const removeUtilityBillFile = (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };
  const removeSelfieFile = (index, type, uploadId) => {
    removeSelectedFile(
      index,
      type,
      fileListID,
      setFileListID,
      fileListUtilityBill,
      setFileListUtilityBill,
      fileListSelfie,
      setFileListSelfie,
      uploadId
    );
  };

  const navigate = useNavigate();
  const handleNextBtn = async () => {
    navigate("/face-recognition");
    // if (
    //   fileListID.length === 0 ||
    //   fileListUtilityBill.length === 0 ||
    //   fileListSelfie.length === 0
    // ) {
    //   setResponseMessage({
    //     id: fileListID.length === 0 ? "Please upload your valid ID" : "",
    //     utils:
    //       fileListUtilityBill.length === 0
    //         ? "Please upload your utility bill"
    //         : "",
    //     selfie: fileListSelfie.length === 0 ? "Please upload your selfie" : "",
    //   });
    //   return;
    // }
    // compileDocuments();
    // submitIsDocumentDone({
    //   is_document_uploaded: 1,
    // });
    // qrCoderequest();
  };
  //   const compileDocuments = async () => {
  //     try {
  //       const allIds = Array.from(uniqueIds);
  //       console.log("All IDs:", allIds);
  //       const userFileID = new FormData();
  //       allIds.forEach((id) => userFileID.append("ids[]", id));
  //       console.log("FormData:", userFileID);

  //       const response = await axios.post(
  //         `https://devreg.co.nz/api/api/user/${id}/compile-documents`,
  //         userFileID,
  //         {
  //           headers: {
  //             "X-Requested-With": "XMLHttpRequest",
  //             Authorization: `Bearer ${TOKEN}`,
  //             "Content-Type": "multipart/form-data", // Ensure Content-Type header is set
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         console.log("compile id successful:", response.data);
  //         setOpenFacialRecognition(true);
  //       } else {
  //         console.log("compile id failed with status:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("compile id failed:", error);
  //     }
  //   };

  const handleBackBtn = () => {
    setOpenFacialRecognition(false);
  };

  //   const qrCoderequest = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://devreg.co.nz/api/api/qr-code?id=${id}&for=user`,
  //         {
  //           headers: {
  //             "X-Requested-With": "XMLHttpRequest",
  //             Authorization: `Bearer ${TOKEN}`,
  //           },
  //         }
  //       );

  //       if (response.status === 200) {
  //         const qrCodeData = response.data;
  //         console.log(qrCodeData);
  //         setQrCodeDataUser(qrCodeData);
  //       } else {
  //         console.log("get qr code failed with status:", response.status);
  //       }
  //     } catch (error) {
  //       console.error("get qr code failed:", error);
  //     }
  //   };

  //   const submitIsDocumentDone = async (data) => {
  //     try {
  //       const response = await axios.put(
  //         `https://devreg.co.nz/api/api/user/${id}/profile-info`,
  //         data,
  //         {
  //           headers: {
  //             "X-Requested-With": "XMLHttpRequest",
  //             Authorization: `Bearer ${TOKEN}`,
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log(response.data, "checkData");
  //         const { user } = response.data;
  //         login(TOKEN, user);
  //       }

  //       console.log("Update update-document successful:", response.data);
  //     } catch (error) {
  //       console.error("Update update-document failed:", error);
  //     }
  //   };
  return {
    isModalOpen,
    setIsModalOpen,
    isCheckboxChecked,
    setIsCheckboxChecked,
    isCheckboxChecked2,
    setIsCheckboxChecked2,
    openModal,
    closeModal,
    responseMessage,
    setResponseMessage,
    setFileListID,
    fileListID,
    setFileListUtilityBill,
    fileListUtilityBill,
    setFileListSelfie,
    fileListSelfie,
    onFileDrop,
    removeSelectedFile,
    onFileIdDrop,
    onFileUtilityBillDrop,
    onFileSelfieDrop,
    removeSelectedFileID,
    removeUtilityBillFile,
    removeSelfieFile,
    handleNextBtn,
    openFacialRecognition,
    qrCodeDataUser,
    handleBackBtn,
    setOpenFacialRecognition,
  };
};

export default useUploadDocument;
