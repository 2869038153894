import React, { useState, useEffect, useRef } from "react";
import UpdatePassword from "./UpdatePassword";
import { FaPaste } from "react-icons/fa";
import BacktoLogin from "../components/BackToLogin";
import {
  forgetPasswordSendOtpEmail,
  forgetPasswordVerifyOtpEmail,
} from "../../../api/apiCall.js";

function TwoStepVerification(props) {
  const { email } = props;
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [otpOne, setOtpOne] = useState("");
  const [otpTwo, setOtpTwo] = useState("");
  const [otpThree, setOtpThree] = useState("");
  const [otpFour, setOtpFour] = useState("");
  const [otpFive, setOtpFive] = useState("");
  const [otpSix, setOtpSix] = useState("");
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const allFieldsFilled = [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].every(
    (value) => value.length === 1
  );

  useEffect(() => {
    if (allFieldsFilled) {
      handleVerify();
    }
  }, [otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix]);

  const handleVerify = async () => {
    if (loading) return;
    setLoading(true);
    try {
      setLoading(true);
      let data = {
        payloads: {
          email: email,
          otp: otpOne + otpTwo + otpThree + otpFour + otpFive + otpSix,
          is_reset_password: 1,
        },
      };
      const response = await forgetPasswordVerifyOtpEmail(data);
      if (response.status === 200) {
        setToken(response.data.token);
        setShowUpdatePassword(true);
      }
      else {
        console.error("Failed to verify OTP: ", response.message);
        setError('Invalid OTP!');
      }
    } catch (error) {
      console.log("Error verifying OTP: ", error);
      setError('Invalid OTP!');
    } finally {
      setLoading(false);
    }
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (text.length === 6 && /^[0-9]+$/.test(text)) {
        const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
        text.split("").forEach((char, index) => {
          otpSetters[index](char);
          inputRefs[index].current.value = char;
        });
        inputRefs[5].current.focus();
      } else {
        console.error("Invalid OTP format. Make sure it's 6 digits.");
      }
    } catch (error) {
      console.error("Failed to read clipboard contents: ", error);
    }
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (value.length > 1) {
      e.target.value = value.slice(0, 1);
    }
    const otpSetters = [setOtpOne, setOtpTwo, setOtpThree, setOtpFour, setOtpFive, setOtpSix];
    otpSetters[index](value);

    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleResendOtpEmail = async () => {
    try {
      let data = {
        payloads: {
          email: email,
        },
      };
      const response = await forgetPasswordSendOtpEmail(data);
      if (response.status === 200) {
        startCountdown();
      }
    } catch (error) {
      console.log("Error resending OTP: ", error);
    }
  };

  const startCountdown = () => {
    setCountdown(30);
    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  return (
    <div className="flex flex-col min-h-screen bg-devRegDarkBlue">
      {!showUpdatePassword && (
        <div className="flex flex-col items-center py-20 px-20 text-base bg-[#F9FAFB] flex-grow max-md:px-5">
          <div className="flex flex-col p-6 mt-6 max-w-full bg-white rounded-2xl border border-gray-200 shadow-sm w-[400px] max-md:w-full max-md:px-5">
            <div className="flex items-center justify-between w-full mb-4">
              <div className="flex justify-start items-center gap-1 cursor-pointer text-teal-400 text-base font-medium">
                <BacktoLogin />
              </div>
              <img src="/logoFooter.png" alt="Devreg Logo" className="h-8" />
            </div>
            <hr className="w-full border-t border-gray-200" />
            <div className="mt-8 text-xl font-semibold text-neutral-800 max-sm:mr-auto text-start">
              Two Step Verification
            </div>
            <div className="mt-2 mb-4 text-base text-zinc-700 text-opacity-80 max-sm:text-sm max-md:text-md">
              We sent a verification code to your email. Enter the code from
              the mobile in the field below.
            </div>
            {error && (
              <>
                <small className="text-red-600 text-center">{error}</small>
              </>
            )}
            <div className="flex gap-4 mt-2 items-center justify-center">
              {[otpOne, otpTwo, otpThree, otpFour, otpFive, otpSix].map((otp, index) => (
                <input
                  key={index}
                  type="text"
                  className={`flex-1 shrink-0 px-3 rounded-md border border-solid h-[46px] w-[30px] max-[320px]:px-0 text-center ${error ? 'border-red-600' : 'border-zinc-700 border-opacity-20'}`}
                  maxLength={1}
                  value={otp}
                  onChange={(e) => handleInputChange(e, index)}
                  ref={inputRefs[index]}
                />
              ))}
            </div>
            <div
              onClick={handlePaste}
              className="flex flex-row gap-1 cursor-pointer justify-center items-center px-9 py-3 mt-8 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center bg-teal-300"
            >
              <FaPaste size={20} />
              Paste from Clipboard
            </div>
            <button
              onClick={handleVerify}
              disabled={!allFieldsFilled || loading}
              className={`cursor-pointer justify-center items-center px-9 py-3 mt-4 text-base font-semibold text-white whitespace-nowrap rounded-3xl max-md:px-5 text-center ${loading
                  ? "bg-gray-400"
                  : "bg-blue-900"
                }`}
            >
              {loading ? (
                <svg
                  className="animate-spin h-6 w-6 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Verify"
              )}
            </button>
            <div className="cursor-pointer self-center mt-4 text-base font-bold text-center">
              <span className="text-neutral-400 font-normal">
                Didn’t get the email?
              </span>
              <button
                className={`ml-1 text-devRegBlue ${countdown > 0 ? "opacity-50" : ""}`}
                disabled={countdown > 0}
                onClick={() => handleResendOtpEmail()}
              >
                Resend
                {countdown > 0 && <span> ({countdown}s)</span>}
              </button>
            </div>
          </div>
        </div>
      )}
      {showUpdatePassword && <UpdatePassword email={email} token={token} />}
    </div>
  );
}

export default TwoStepVerification;
