import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  getTheRegisterConversation,
  getDevregConversation,
  getAdminConversation,
  replyTheRegisterMessage,
  replyDevregMessage,
  replyAdminMessage,
} from "../../../api/apiCall";
import { LiaReplySolid } from "react-icons/lia";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoSend, IoChevronBack } from "react-icons/io5";
import { FaRegTimesCircle } from "react-icons/fa";
import Loading from '../../../component/Loading';

const OpenMessageMobile = ({ data, emit }) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem('token');

  const [isFething, setIsFetching] = useState(false);
  const [showReplyPanel, setShowReplyPanel] = useState(false);
  const [showMessage, setShowMessage] = useState(null)
  const [replyInput, setReplyInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    const initialFetch = async () => {
      setIsFetching(true);
      await handleGetMessageConversation();
      setIsFetching(false);
    }

    initialFetch();

  }, []);

  const handleGetMessageConversation = async () => {
    try {
      let formData = {
        token: token,
        conversationId: data.conversation_id,
        params: `platform=${data.platform}`
      }

      let response;
      switch (data.platform) {
        case 'devreg':
          response = await getDevregConversation(formData);
          break;
        case 'register':
          response = await getTheRegisterConversation(formData);
          break;
        case 'admin':
          response = await getAdminConversation(formData);
          break;

        default:
          break;
      }

      if (response.status === 200) {
        setMessages(response.data);
      }
    }
    catch (error) {
      console.error("Error getting conversation: ", error);
    }
    finally {

    }
  }

  const handleDelete = () => {
    setReplyInput("");
  }

  const handleReply = async () => {
    setSending(true);
    try {
      let formData = {
        token: token,
        payloads: {
          receiver_id: data.sender.id,
          subject: 'Reply',
          message_content: replyInput,
          receiver_platform: data.platform,
        }
      }

      let response;
      switch (data.platform) {
        case 'devreg':
          response = await replyDevregMessage(formData);
          break;
        case 'register':
          response = await replyTheRegisterMessage(formData);
          break;
        case 'admin':
          response = await replyAdminMessage(formData);
          break;

        default:
          break;
      }

      if (response.status === 201) {
        await handleGetMessageConversation();
        setReplyInput("");
      }
    }
    catch (error) {
      console.error("Error sending reply:", error);
    }
    finally {
      setSending(false);
    }
  }

  const sendDataToParent = () => {
    emit({
      type: "setShowMessage",
      data: showMessage,
    });
  }

  const formatDateTime = (timestamp) => {
    const response = `${moment(timestamp).format('MMM D, YYYY | hh:mm A')} (${moment(timestamp).fromNow()})`;
    return response;
  }

  const handleCloseReplyPanel = () => {
    setShowReplyPanel(false);
  }

  return (
    <div className="flex flex-col self-stretch px-8 max-md:px-0 max-md:mt-0 max-md:max-w-full">
      <div className="flex flex-wrap gap-2.5 content-center mt-6 text-2xl font-semibold tracking-tight leading-7 text-blue-900">
        <div className="flex cursor-pointer gap-1 items-end justify-between w-full"
          onClick={() => {
            setShowMessage(false);
            sendDataToParent();
          }}
        >
          <div className='flex items-center gap-1'>
            <IoChevronBack size={20} className="self-left" />
            <div className="flex max-md:max-w-full">
              {data.subject}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2.5 justify-between mt-2 text-xs font-light tracking-tight leading-4 text-neutral-600 max-md:flex-wrap">
        <div className="flex gap-1 my-auto max-md:max-w-full">
          from: <span className='text-[#0082ba] font-semibold'>
            {data.sender.fullname}
          </span>
        </div>
        <div className="flex gap-2 justify-between w-full">
          <div className="flex my-auto text-xs text-[#727272] font-light">
            {formatDateTime(data.created_at)}
          </div>
          <div className="flex justify-between gap-2 px-px whitespace-nowrap">
            <div
              className="flex gap-2 cursor-pointer text-[#0082ba]"
              onClick={() => setShowReplyPanel(true)}
            >
              <LiaReplySolid size={15} className='cursor-pointer' />
              <div className="my-auto">Reply</div>
            </div>
            <FaRegTrashCan onClick={() => handleDelete()} size={15} className="cursor-pointer text-gray-600 hover:text-red-500" />
          </div>
        </div>
      </div>

      {/* <div className="justify-center p-8 mt-2 text-sm font-light tracking-tight leading-4 rounded-2xl border border-solid border-zinc-200 text-neutral-600 max-md:px-5 max-md:max-w-full">
        {data.message_content}
      </div> */}

      {/* Message Section */}
      {!isFething ? (
        <div className='flex flex-col items-center h-auto overflow-auto mt-2'>
          {messages.map((msg) => (
            <div
              key={msg.id}
              className={`justify-center p-6 mt-4 rounded-lg text-sm font-light max-w-full tracking-tight leading-4 border border-solid border-gray-300 text-neutral-600 max-md:px-5 max-md:max-w-full ${msg.type === 'received' ? 'self-start rounded-bl-none' : 'self-end bg-[#dcf9ff] rounded-br-none'}`}
            >
              <div>{msg.message_content}</div>
              <div className={`italic text-xs text-gray-500 mt-2 ${msg.sender.id !== user.id ? 'text-start' : 'text-end'}`}>{formatDateTime(msg.created_at)}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className='min-h-[500px] flex justify-center items-center'>
          <Loading />
        </div>
      )}

      {showReplyPanel && (
        <div className="flex gap-4 mt-8 max-md:flex-wrap">
          <img
            alt="profile"
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1f6218a449597f3a8bc713b85905e3bdb9e14b0e6e958134a97eb98c7ddb99b"
            className="shrink-0 self-start w-10 rounded-full aspect-square border border-devRegGreen"
          />
          <div className="flex flex-col flex-1 max-md:max-w-full border border-gray-300 rounded-lg">
            <div className="flex justify-between items-center px-4 py-4 rounded-t-lg">
              <div className="flex items-center gap-2.5 text-sm">
                <LiaReplySolid size={15} />
                <span>{data.sender.email}</span>
              </div>
              <FaRegTimesCircle
                onClick={handleCloseReplyPanel}
                size={20}
                className="cursor-pointer text-gray-600 hover:text-red-500"
              />
            </div>

            <textarea
              onChange={(replyInput) => setReplyInput(replyInput.target.value)}
              value={replyInput}
              className="py-2 px-4 text-sm font-light w-full min-h-[100px] border border-transparent focus:outline-none outline-none"
              placeholder="Type your reply here..."
            />

            <div className="flex justify-between items-center px-4 py-2 rounded-b-lg border-t border-gray-300">
              <FaRegTrashCan
                size={20}
                className="cursor-pointer text-gray-600 hover:text-red-500"
              />
              <button
                onClick={() => handleReply()}
                className={`flex items-center gap-2 px-6 py-2 text-white bg-blue-900 rounded-full hover:bg-blue-700 focus:outline-none ${sending || !replyInput ? 'cursor-not-allowed opacity-[50%]' : 'cursor-pointer'}`}
              >
                {sending ? "Sending..." : (
                  <>
                    Send <IoSend size={20} />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default OpenMessageMobile
