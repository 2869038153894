import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa6";
const BackButtonImage = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(-1);
    };

    return (
        <>
            <FaChevronLeft onClick={handleClick} className="object-contain shrink-0 self-stretch my-auto w-5 aspect-square cursor-pointer" />
        </>
    );
};

export default BackButtonImage;
