// src/actions/index.js

export const SET_VALUE = "SET_VALUE";
export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOADING = "IS_LOADING";
export const SET_GOTO_LOGIN_PAGE = "SET_GOTO_LOGIN_PAGE";
export const MARK_NAV_DASH = "MARK_NAV_DASH";

export const setValue = (value) => ({
  type: SET_VALUE,
  payload: value,
});
export const setMarkNav = (value) => ({
  type: MARK_NAV_DASH,
  payload: value,
});
export const setIsLoggedIn = (value) => ({
  type: IS_LOGGED_IN,
  payload: value,
});
export const setIsLoading = (value) => ({
  type: IS_LOADING,
  payload: value,
});

export const setGotoLoginPage = (value) => ({
  type: SET_GOTO_LOGIN_PAGE,
  payload: value,
});
