import React from 'react'

const PostListLoading = () => {
  return (
    <div className="relative animate-pulse gap-2 p-4 w-full rounded-lg border">
        <div className="flex justify-between">
            <div className='flex gap-2 items-center mb-3'>
                <div className='w-[35px] h-[35px] rounded-full bg-slate-300 text-lg"'></div>
                <div className=" h-5 w-[130px] bg-slate-300 text-lg"></div>
            </div>
            <div className="flex gap-2">
                <div className="mb-1 h-4 w-[70px] bg-slate-300 text-lg"></div>
                <div className="mb-1 h-4 w-[15px] bg-slate-300 text-lg"></div>
            </div>
        </div>

        <div className="mb-1 h-4 w-[150px] bg-slate-300 text-lg"></div>
        
        <div className="flex flex-col gap-1 mt-3 mb-1">
            <div className="h-4 w-[99%] bg-slate-300 text-sm"></div>
            <div className="h-4 w-[77%] bg-slate-300 text-sm"></div>
            <div className="h-4 w-[86%] bg-slate-300 text-sm"></div>
        </div>

        <div className="mt-3 flex justify-between">
            <div className="flex gap-1">
                <div className="mb-1 h-5 w-[50px] bg-slate-300 text-lg rounded-sm"></div>
                <div className="mb-1 h-5 w-[40px] bg-slate-300 text-lg rounded-sm"></div>
                <div className="mb-1 h-5 w-[40px] bg-slate-300 text-lg rounded-sm"></div>
                <div className="mb-1 h-5 w-[37px] bg-slate-300 text-lg rounded-sm"></div>
            </div>
            <div className="flex gap-2">
                <div className="mb-1 h-4 w-[25px] bg-slate-300 text-lg"></div>
                <div className="mb-1 h-4 w-[25px] bg-slate-300 text-lg"></div>
            </div>
            
        </div>
    </div>
  )
}

export default PostListLoading
