import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
function SidePanel({ image }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col text-2xl max-md:mt-10">
          <div className="max-md:w-[94%] max-md:m-auto flex flex-col items-center px-5 py-[100px] max-md:py-[60px] font-semibold rounded-2xl bg-gradient-to-br from-[#28b9bb] to-[#1c3775]">
            <img
              alt="img"
              loading="lazy"
              srcSet={image}
              className="self-stretch w-full"
            />
            <div className="tracking-tight leading-7 text-white m-auto text-center max-xl:text-lg">
              Ready to become a recognized, trusted, and sought-after
              professional around the world?
            </div>
            <div
              className="justify-center px-9 py-2 mt-10 max-w-full text-xl max-lg:text-[14px] max-md:text-[20px] max-sm:text-[18px] text-blue-900 rounded-3xl bg-zinc-100 w-[236px] max-md:px-5 text-center cursor-pointer"
              onClick={() => {
                navigate("/register");
                window.scrollTo(0, 0);
              }}
            >
              Get Verified
            </div>
          </div>
          <div className="max-md:w-[94%] max-md:m-auto max-md:mt-17 flex flex-col justify-end p-8 mt-10 rounded-2xl border border-sky-600 border-solid max-md:px-5 max-md:mt-16">
            <div className="flex font-bold items-center text-blue-900  max-sm:text-[22px]  ">
              <div>HAVE QUESTIONS?</div>
              <FaQuestionCircle
                size={40}
                color="orange"
                className="max-:md:pt-2 pl-2 min-[1400px]:mt-[-2px]"
              />
            </div>
            <div className="mt-1 text-xl tracking-tight leading-6 text-neutral-600  max-sm:text-[18px]  max-lg:text-[19px]">
              Reach out to us and ask your questions away
            </div>
            <div
              className=" justify-center px-8 py-3 mt-6 text-xl font-semibold text-white bg-sky-600 rounded-3xl max-md:pr-7 max-md:pl-7 text-center cursor-pointer  max-sm:text-[18px]"
              onClick={() => {
                navigate("/contact");
                window.scrollTo(0, 0);
              }}
            >
              Get Help
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SidePanel;
