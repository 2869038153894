
import { Axios } from "axios";

const basicAxios = new Axios({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'X-Requested-With':'XMLHttpRequest',
    }
});

const authAxios = () => {
    const token = localStorage.getItem('token');
    return new Axios({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Authorization':`Bearer ${token}`,
            'X-Requested-With':'XMLHttpRequest',
        }
    });
};


export {
    basicAxios,
    authAxios,
};