import React from 'react';
import { PiMagnifyingGlass } from "react-icons/pi";

function SearchBar() {
  return (
    <form className="relative flex flex-wrap mt-6 w-full min-h-[45px] max-md:max-w-full">
      <label htmlFor="search" className="sr-only">Search</label>
      <div className="absolute top-3.5 left-2 flex gap-2 justify-center items-center w-[26px]">
        <PiMagnifyingGlass size={17} className="object-contain self-stretch my-auto text-gray-600" />
    </div>
      <input
        type="search"
        id="search"
        className="flex-1 shrink gap-2 self-stretch pl-9 px-3 my-auto text-sm text-gray-400 rounded-lg border border-gray-400 border-solid min-h-[45px] max-md:max-w-full"
        placeholder="Search anything..."
      />
    </form>
  );
}

export default SearchBar;