import React, { useState } from "react";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { items } from "../data/accordionForRequirements";

function AccordionRequirements() {
  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <div key={index} className="accordion-item mt-4 cursor-pointer border border-[#E5E7EB] p-[16px] rounded-lg bg-white">
          <div
            className={`accordion-title ${
              index === activeIndex ? "active" : ""
            } flex justify-between items-center`}
            onClick={() => onTitleClick(index)}
          >
            <div className="text-[#4A4A4A] font-semibold text-2xl max-sm:text-lg">
              {item.title}
            </div>
            <div className="">
              {index === activeIndex ? (
                <MdKeyboardArrowDown size={30} color="#2AD2C9" />
              ) : (
                <MdKeyboardArrowRight size={30} color="#2AD2C9" />
              )}
            </div>
          </div>
          {index === activeIndex && (
            <div className="accordion-content text-neutral-500 max-sm:pr-8 max-w-[90%]">
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AccordionRequirements;
