import React, { useState } from 'react';

const CategoryTopic = ({ topics }) => {

  const [visibleCount, setVisibleCount] = useState(4);

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  return (
    <div className='pb-6'>
      <section className="max-md:hidden flex overflow-hidden flex-col mt-6 w-full text-sm rounded-lg border border border-solid max-md:max-w-full overflow-x-auto">
        {/* Header Section */}
        <div className="flex flex-nowrap items-center px-4 py-2 w-full font-semibold text-white bg-blue-900 min-w-[588px]">
          <div className="self-stretch my-auto whitespace-nowrap min-w-[180px] w-[400px] max-md:w-0">Subject</div>
          <div className="gap-2.5 self-stretch pl-2 my-auto w-60 min-w-[115px] max-md:w-0">Started by</div>
          <div className="flex-1 shrink gap-2.5 self-stretch pl-2 my-auto whitespace-nowrap min-w-[70px] max-md:pl-0">Replies</div>
          <div className="flex-1 shrink gap-2.5 self-stretch pl-2 my-auto whitespace-nowrap min-w-[70px] max-md:pl-0">Views</div>
          <div className="gap-2.5 self-stretch pl-2 my-auto w-60 max-md:pl-0">Last Post</div>
        </div>

        {/* Topics Section */}
        <div className="flex flex-col w-full max-md:max-w-full">
          {topics.map((topic, index) => (
            <div key={index} className="flex items-center px-4 w-full bg-white border-b border-solid max-md:max-w-full min-w-[588px]">
              <div className="flex flex-nowrap flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[570px] max-md:max-w-full">
                <div className="flex items-center pr-2 text-blue-900 min-w-[180px] w-[400px]">{topic.subject}</div>
                <div className="flex items-center px-2 w-60 text-blue-900 whitespace-nowrap bg-gray-100 min-w-[110px]">{topic.startedBy}</div>
                <div className="flex items-center flex-1 shrink px-2 text-blue-900 whitespace-nowrap min-w-[70px]">{topic.replies}</div>
                <div className="flex items-center flex-1 shrink px-2 text-blue-900 whitespace-nowrap bg-gray-100 min-w-[70px]">{topic.views}</div>
                <div className="flex flex-col justify-center p-2 w-60 text-xs min-w-[140px]">
                  <div className="text-gray-600">{topic.lastPost.date}</div>
                  <div className="text-sky-600">
                    by <span className="font-medium text-sky-600">{topic.lastPost.author}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <div className='hidden max-md:block'>
        <div className="flex overflow-hidden flex-col mt-6 w-full text-xs rounded-lg border border-solid">
          {topics.slice(0, visibleCount).map((topic, index) => (
            <div key={index} className="flex flex-col w-full bg-gray-50">
              <div className="flex w-full items-center text-white bg-blue-900 border-b border-b-gray-300">
                <div className="flex-1 shrink gap-2.5 px-4 py-3 h-full font-medium whitespace-nowrap">
                  Subject
                </div>
                <div className="flex-1 shrink gap-2.5 self-start px-4 py-3 border-l border-l-gray-300">
                  {topic.subject}
                </div>
              </div>
              <div className="flex items-center w-full text-blue-900 border-b border-b-gray-300">
                <div className="flex-1 shrink gap-2.5 px-4 py-3 h-full font-medium">
                  Started by
                </div>
                <div className="flex-1 shrink gap-1 self-start px-4 py-3 whitespace-nowrap border-l border-l-gray-300">
                  {topic.startedBy}
                </div>
              </div>
              <div className="flex items-center w-full text-blue-900 whitespace-nowrap border-b border-b-gray-300">
                <div className="flex-1 shrink gap-2.5 px-4 py-3 h-full font-medium">
                  Replies
                </div>
                <div className="flex-1 shrink gap-2.5 self-start px-4 py-3 border-l border-l-gray-300">
                  {topic.replies}
                </div>
              </div>
              <div className="flex items-center w-full text-blue-900 whitespace-nowrap border-b border-b-gray-300">
                <div className="flex-1 shrink gap-2.5 px-4 py-3 h-full font-medium">
                  Views
                </div>
                <div className="flex-1 shrink gap-2.5 self-start px-4 py-3 border-l border-l-gray-300">
                  {topic.views}
                </div>
              </div>
              <div className="flex items-center w-full border-b border-b-gray-300">
                <div className="flex-1 shrink gap-2.5 px-4 py-3 h-full font-medium text-blue-900">
                  Last Post
                </div>
                <div className="flex flex-1 shrink gap-2.5 justify-center self-start px-4 py-3 basis-0 border-l border-l-gray-300">
                  <div className="flex flex-col flex-1 shrink justify-center py-2 pr-px pl-2 w-full basis-0">
                    <div className="text-gray-600">
                      {topic.lastPost.date}
                    </div>
                    <div className="text-sky-600">
                      by <span className="font-medium text-sky-600">{topic.lastPost.author}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {visibleCount < topics.length && (
          <div className="mt-6 w-full text-sm font-semibold text-sky-600 text-center" onClick={handleSeeMore}>
            See more
          </div>
        )}
      </div>
    </div >
  );
};

export default CategoryTopic;
